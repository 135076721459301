import React from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { AuthenticatedTemplate, useIsAuthenticated } from '@azure/msal-react';

interface ProtectedRouteProps {
  children: React.ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const isAuthenticated = useIsAuthenticated();
  const location = useLocation();

  if (!isAuthenticated && location.pathname.startsWith(`/`)) {
    return <Redirect to={`/`} />;
  }

  return <AuthenticatedTemplate>{children}</AuthenticatedTemplate>;
};

export default ProtectedRoute;
