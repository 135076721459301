/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Steps } from '../../../../model/Step';
import { Project, ProjectPropositions } from '../../../../model/Project';
import ProjectStepProposition from '../../ProjectStepProposition';
import { getProjectPropositions } from '../../../../const/Projects';
import { getDesignTemp } from '../../../../api/projects';

interface Props {
  project: Project;
}

const HeatPumpSelectionIntro: React.FC<Props> = ({ project }) => {
  const { t } = useTranslation();
  const stepId = Steps.STEP_12;
  const i18nIntro = `step-${stepId}:intro`;
  const propositions: ProjectPropositions = useMemo(() => {
    return getProjectPropositions(project);
  }, [project]);

  const { data: designTemp } = useQuery({
    queryKey: ['designTemp'],
    queryFn: async () => {
      return (
        await getDesignTemp(
          project.address.country,
          project.address.province,
          project.address.city
        )
      ).data;
    },
  });

  const airflowCFM =
    (project.steps['existingConstraints'][
      'airflowCapacityMeasurementMethod-value'
    ] === 'A'
      ? project.steps['airflowCapacityMeasurement'].airflowTesting
          ?.predictedMaximumAirflow
      : project.steps['airflowCapacityMeasurement']
          .crossSectionalAreaMeasurement?.estimatedMaximumFlowCapacity) ?? 0;

  return (
    <>
      <p className="intro-title">{t(`${i18nIntro}.title`)}</p>
      <ProjectStepProposition
        id={stepId}
        propositions={[
          ...propositions.always.options[stepId],
          ...propositions.options[stepId],
        ]}
        title={t(`${i18nIntro}.baseOnPreviousSelection`)}
        translationVariables={{
          airflow: airflowCFM,
          targetHeatingCapacity: project.steps.sizingCapacityRequirements[
            'sizingCapacityRequirements-value'
          ]
            ? t(
                `${i18nIntro}.targetHeatingCapacity.${project.steps.sizingCapacityRequirements['sizingCapacityRequirements-value']}`
              ).toString()
            : '',
          sizingApproach: project.steps.sizingCapacityRequirements[
            'sizingCapacityRequirements-value'
          ]
            ? t(
                `step-${
                  Steps.STEP_3
                }:form.${project.steps.sizingCapacityRequirements[
                  'sizingCapacityRequirements-value'
                ]?.toLowerCase()}.option.text`
              ).toString()
            : '',
          temp: project.steps.sizingCapacityRequirements[
            'sizingCapacityRequirements-value'
          ]
            ? t(
                `${i18nIntro}.temp.${project.steps.sizingCapacityRequirements['sizingCapacityRequirements-value']}`,
                { designTemp }
              ).toString()
            : '',
          availableCapacity:
            project.steps.existingConstraints.availableCapacity,
          dhl: project.steps.heatingCoolingLoadEstimates.designHeatLoss,
          designTemp,
        }}
        withListStyle
      />
    </>
  );
};

export default HeatPumpSelectionIntro;
