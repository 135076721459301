/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from 'react-i18next';
import { Redirect, Route, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { useAccount } from '@azure/msal-react';
import { AppRoute, QUERY_REFETCH_INTERVAL } from '../../const';
import { LocalizedSwitch } from '../i18n';
import { AppHeader } from '../common';
import { getProject } from '../../api/projects';
import { useUserProjects } from '../../hooks/useUserProjects';
import { ProjectStepsPage } from './StepPage';
import { ProjectChecklistPage } from './ChecklistPage';
import { ProjectOverview, ProjectSettings } from '.';

const ProjectPage: React.FC = () => {
  const { t } = useTranslation();

  const { projectId } = useParams<{ projectId: string }>();
  const account = useAccount();

  const userProjectsQuery = useUserProjects();

  const projectQuery = useQuery({
    enabled: !!projectId,
    queryKey: ['project', { id: projectId }],
    queryFn: async () => {
      return projectId ? (await getProject(projectId)).data : null;
    },
    refetchInterval: QUERY_REFETCH_INTERVAL,
  });
  const project = projectQuery.data;

  return (
    <div className="project-page">
      <AppHeader />

      {!(!project || projectQuery.error) && (
        <LocalizedSwitch nested={true}>
          <Route exact path={AppRoute.ProjectOverview}>
            <ProjectOverview project={project} />
          </Route>

          <Route path={AppRoute.StepPath}>
            <ProjectStepsPage project={project} />
          </Route>

          <Route path={AppRoute.ProjectChecklist}>
            <ProjectChecklistPage project={project} />
          </Route>

          {account?.localAccountId === project.owner && (
            <Route path={AppRoute.ProjectSettings}>
              <ProjectSettings
                project={project}
                userProjects={userProjectsQuery.data ?? []}
              />
            </Route>
          )}

          <Redirect
            to={`/${t(AppRoute.Project)}/${projectId}/${t(
              AppRoute.ProjectOverview
            )}`}
          />
        </LocalizedSwitch>
      )}
      {((projectQuery.isFetched && !project) || projectQuery.error) && (
        <LocalizedSwitch nested={true}>
          <Redirect to={`/${t(AppRoute.NotFound)}`} />
        </LocalizedSwitch>
      )}
    </div>
  );
};

export default ProjectPage;
