import { useEffect, useState } from 'react';
import { useScript } from '../../../hooks/useScript';
import { Video } from '../../../model/Videos';
import VideoInitialize from './VideoInitialize';

interface Props {
  video: Video;
}

const VideoContent: React.FC<Props> = ({ video }) => {
  const [ready, setReady] = useState(false);

  const wetBoewScript = useScript(
    '/etc/designs/canada/cdts/gcweb/wet-boew/js/wet-boew.min.js'
  );

  // Waiting for resources to load...
  useEffect(() => {
    if (!ready && wetBoewScript === 'ready' && (window as any).wb) {
      setReady(true);
    }
  }, [wetBoewScript, ready]);

  return (
    <>
      {ready && (
        <VideoInitialize>
          <video title={video.title}>
            <source type="video/youtube" src={video.href} />
          </video>
        </VideoInitialize>
      )}
    </>
  );
};

export default VideoContent;
