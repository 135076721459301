/* eslint-disable react-hooks/exhaustive-deps */
import { JSX, useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { getProjectPropositions } from '../../../const/Projects';
import { Project, ProjectPropositions } from '../../../model/Project';
import { Step2Data, Step2_1Data, StepOption, Steps } from '../../../model/Step';
import { updateFieldArrayDependingOnNumber } from '../../../utils/fieldArrayUtils';
import { Input, RadioGroup, Select } from '../../common/Form';
import ProjectStepProposition from '../ProjectStepProposition';
import { StepOptionRadioItem } from '../StepPage';

interface Props {
  step2_1Data?: Step2_1Data;
  stepData?: Step2Data;
  project: Project;
  updateProject?: (value) => void;
  displayCompact?: boolean;
  userIsProjectOwner?: boolean;
  hideOptionsSelection?: boolean;
  additionalInfo?: JSX.Element;
  children?: JSX.Element;
}

const AshpUnitConfigurationPage: React.FC<Props> = ({
  step2_1Data,
  stepData,
  project,
  updateProject,
  displayCompact,
  userIsProjectOwner,
  hideOptionsSelection,
  additionalInfo,
  children,
}) => {
  const { t } = useTranslation();

  const stepId = Steps.STEP_2;
  const fieldValue = `${stepId}-value`;

  const propositions: ProjectPropositions = useMemo(() => {
    return getProjectPropositions(project);
  }, [project]);

  const {
    control,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useForm<Step2Data>({
    defaultValues: {
      [fieldValue]: stepData?.[fieldValue] || 'A',
      numberOfIndoorUnits: stepData?.numberOfIndoorUnits || 1,
      numberOfOutdoorUnits: stepData?.numberOfOutdoorUnits || 1,
    },
    mode: 'onChange',
  });

  const ashpUnitConfigurationValueWatch = watch('ashpUnitConfiguration-value');
  const numberOfIndoorUnitsWatch = watch('numberOfIndoorUnits');
  const numberOfOutdoorUnitsWatch = watch('numberOfOutdoorUnits');

  const submitField = (value: { [key: string]: string | number }) => {
    updateProject &&
      updateProject({
        [`steps.${[stepId]}`]: {
          ...formValues,
          ...value,
        },
      });
  };

  const updateIndoorOutdoorUnits = (value: {
    [key: string]: Step2_1Data['indoorUnits'] | Step2_1Data['outdoorUnits'];
  }) => {
    updateProject &&
      updateProject({
        [`steps.${Steps.STEP_2_1}/${Object.keys(value)[0]}`]:
          value[Object.keys(value)[0]],
      });
  };

  useEffect(() => {
    const updatedIndoorUnits = updateFieldArrayDependingOnNumber(
      numberOfIndoorUnitsWatch ?? 0,
      step2_1Data?.indoorUnits ?? [],
      {
        'ductlessIndoorOutdoorUnitsConfiguration-value':
          ashpUnitConfigurationValueWatch === 'D' ? 'D' : 'A',
      }
    );
    updateIndoorOutdoorUnits({ indoorUnits: updatedIndoorUnits });
  }, [numberOfIndoorUnitsWatch]);

  useEffect(() => {
    const updatedOutdoorUnits = updateFieldArrayDependingOnNumber(
      numberOfOutdoorUnitsWatch ?? 0,
      step2_1Data?.outdoorUnits ?? [],
      {
        'ductlessIndoorOutdoorUnitsConfiguration-value': 'A',
      }
    );
    updateIndoorOutdoorUnits({ outdoorUnits: updatedOutdoorUnits });
  }, [numberOfOutdoorUnitsWatch]);

  useEffect(() => {
    if (ashpUnitConfigurationValueWatch === 'A') {
      submitField({ numberOfIndoorUnits: 0, numberOfOutdoorUnits: 0 });
      updateIndoorOutdoorUnits({ indoorUnits: [] });
      updateIndoorOutdoorUnits({ outdoorUnits: [] });
    }
  }, [ashpUnitConfigurationValueWatch]);

  const i18nForm = `step-${stepId}:form`;

  const options: {
    label: string;
    value: string | number;
    disabled?: boolean;
    labelData: StepOption;
  }[] = [
    {
      label: `${t(`${i18nForm}.a.option.title`)} - ${t(
        `${i18nForm}.a.option.text`
      )}`,
      value: 'A',
      disabled: !userIsProjectOwner,
      labelData: {
        img: {
          alt: t('figures:2A.alt'),
          fileName: t('figures:2A.fileName'),
          longDescription: t('figures:2A.longDesc'),
        },
        modal: {
          content: (
            <>
              <p>{t(`${i18nForm}.a.modal.list1.title`)}</p>
              <ul>
                <li>
                  {t(`${i18nForm}.a.modal.list1.item1`)}
                  <ul>
                    <li>{t(`${i18nForm}.a.modal.list1.item1SubItem1`)}</li>
                    <li>{t(`${i18nForm}.a.modal.list1.item1SubItem2`)}</li>
                  </ul>
                </li>
                <li>{t(`${i18nForm}.a.modal.list1.item2`)}</li>
              </ul>
              <p>{t(`${i18nForm}.a.modal.list2.title`)}</p>
            </>
          ),
          title: t(`${i18nForm}.a.modal.title`),
        },
        option: {
          text: t(`${i18nForm}.a.option.text`),
          title: t(`${i18nForm}.a.option.title`),
        },
      },
    },
    {
      label: `${t(`${i18nForm}.b.option.title`)} - ${t(
        `${i18nForm}.b.option.text`
      )}`,
      value: 'B',
      disabled: !userIsProjectOwner,
      labelData: {
        img: {
          alt: t('figures:2B.alt'),
          fileName: t('figures:2B.fileName'),
          longDescription: t('figures:2B.longDesc'),
        },
        modal: {
          content: (
            <>
              <p>{t(`${i18nForm}.b.modal.list1.title`)}</p>
              <ul>
                <li>{t(`${i18nForm}.b.modal.list1.item1`)}</li>
              </ul>
              <p>{t(`${i18nForm}.b.modal.list2.title`)}</p>
            </>
          ),
          title: t(`${i18nForm}.b.modal.title`),
        },
        option: {
          text: t(`${i18nForm}.b.option.text`),
          title: t(`${i18nForm}.b.option.title`),
        },
      },
    },
    {
      label: `${t(`${i18nForm}.c.option.title`)} - ${t(
        `${i18nForm}.c.option.text`
      )}`,
      value: 'C',
      disabled: !userIsProjectOwner,
      labelData: {
        img: {
          alt: t('figures:2C.alt'),
          fileName: t('figures:2C.fileName'),
          longDescription: t('figures:2C.longDesc'),
        },
        modal: {
          content: (
            <>
              <p>{t(`${i18nForm}.c.modal.list1.title`)}</p>
              <ul>
                <li>{t(`${i18nForm}.c.modal.list1.item1`)}</li>
                <li>{t(`${i18nForm}.c.modal.list1.item2`)}</li>
                <li>{t(`${i18nForm}.c.modal.list1.item3`)}</li>
              </ul>
            </>
          ),
          title: t(`${i18nForm}.c.modal.title`),
        },
        option: {
          text: t(`${i18nForm}.c.option.text`),
          title: t(`${i18nForm}.c.option.title`),
        },
      },
    },
    {
      label: `${t(`${i18nForm}.d.option.title`)} - ${t(
        `${i18nForm}.d.option.text`
      )}`,
      value: 'D',
      disabled: !userIsProjectOwner,
      labelData: {
        img: {
          alt: t('figures:2D.alt'),
          fileName: t('figures:2D.fileName'),
          longDescription: t('figures:2D.longDesc'),
        },
        modal: {
          content: (
            <>
              <p>{t(`${i18nForm}.d.modal.list1.title`)}</p>
              <ul>
                <li>{t(`${i18nForm}.d.modal.list1.item1`)}</li>
                <li>{t(`${i18nForm}.d.modal.list1.item2`)}</li>
              </ul>
              <p>{t(`${i18nForm}.d.modal.list2.title`)}</p>
              <p>{t(`${i18nForm}.d.modal.list3.title`)}</p>
            </>
          ),
          title: t(`${i18nForm}.d.modal.title`),
        },
        option: {
          text: t(`${i18nForm}.d.option.text`),
          title: t(`${i18nForm}.d.option.title`),
        },
      },
    },
  ];

  const formValues = getValues();
  const [selectedOption, setSelectedOption] = useState(
    options.find((o) => o.value === formValues[fieldValue])
  );

  useEffect(() => {
    if (!(stepData && stepData[fieldValue])) {
      submitField(formValues as any);
    }
  }, [formValues[fieldValue]]);

  useEffect(() => {
    setSelectedOption(options.find((o) => o.value === stepData?.[fieldValue]));
    if (stepData && !formValues) {
      setValue('ashpUnitConfiguration-value', stepData?.[fieldValue] || 'A');
      setValue('numberOfIndoorUnits', stepData?.numberOfIndoorUnits || 0);
      setValue('numberOfOutdoorUnits', stepData?.numberOfOutdoorUnits || 0);
    }
  }, [stepData]);

  const validationRules = {
    min: {
      value: 1,
      message: t('errors.project.minNumber', {
        min: 1,
      }).toString(),
    },
    max: {
      value: 99,
      message: t('errors.project.maxNumber', {
        max: 99,
      }).toString(),
    },
    validate: (value) => {
      const num = Number(value);
      if (!Number.isInteger(num)) {
        return t('errors.common.notInteger').toString();
      }
      return true;
    },
  };

  return (
    <>
      <form noValidate>
        {displayCompact ? (
          <Row>
            <Col xs={8} md={8}>
              {userIsProjectOwner ? (
                <>
                  <Select
                    label={t('checklist.selectedOption')}
                    id={fieldValue}
                    control={control}
                    options={options}
                    formGroupClasses={'step-option-radio'}
                    onChange={submitField}
                    inputProps={{ disabled: !userIsProjectOwner }}
                  />
                  {ashpUnitConfigurationValueWatch !== 'A' && (
                    <>
                      <Input
                        id="numberOfIndoorUnits"
                        control={control}
                        label={t(`${i18nForm}.numberOfIndoorUnits`)}
                        inputProps={{ disabled: !userIsProjectOwner }}
                        validationRules={validationRules}
                        onBlur={submitField}
                        type="number"
                        error={errors?.numberOfIndoorUnits}
                      />
                      <Input
                        id="numberOfOutdoorUnits"
                        control={control}
                        label={t(`${i18nForm}.numberOfOutdoorUnits`)}
                        inputProps={{ disabled: !userIsProjectOwner }}
                        validationRules={validationRules}
                        onBlur={submitField}
                        type="number"
                        error={errors?.numberOfOutdoorUnits}
                      />
                    </>
                  )}
                </>
              ) : (
                <div className="selected-option-text">
                  {!hideOptionsSelection && (
                    <>
                      <label>{selectedOption?.labelData.option?.title}</label>
                      <p>{selectedOption?.labelData.option?.text}</p>
                    </>
                  )}
                  {selectedOption?.value !== 'A' && (
                    <>
                      <label>{t(`${i18nForm}.numberOfIndoorUnits`)}</label>
                      <p>{stepData?.numberOfIndoorUnits}</p>
                      <label>{t(`${i18nForm}.numberOfOutdoorUnits`)}</label>
                      <p>{stepData?.numberOfOutdoorUnits}</p>
                    </>
                  )}
                </div>
              )}
              {children}
              {additionalInfo}
            </Col>
            <Col xs={4} md={4}>
              {selectedOption && (
                <img
                  src={`/assets/images/${selectedOption.labelData.img?.fileName}`}
                  alt={selectedOption.labelData.img?.alt}
                  className={'compact-image'}
                />
              )}
            </Col>
          </Row>
        ) : (
          <>
            <RadioGroup
              id={fieldValue}
              control={control}
              items={options}
              radioClassName="step-option-radio"
              onChange={submitField}
              renderLabel={(item) => <StepOptionRadioItem item={item} />}
            />
            {ashpUnitConfigurationValueWatch !== 'A' && (
              <>
                <p>{t(`${i18nForm}.unitDescription`)}</p>
                <Row>
                  <Col xs={6} sm={4}>
                    <Input
                      id="numberOfIndoorUnits"
                      control={control}
                      label={t(`${i18nForm}.numberOfIndoorUnits`)}
                      inputProps={{ disabled: !userIsProjectOwner }}
                      validationRules={validationRules}
                      onBlur={submitField}
                      type="number"
                      error={errors?.numberOfIndoorUnits}
                    />
                  </Col>
                  <Col xs={6} sm={4}>
                    <Input
                      id="numberOfOutdoorUnits"
                      control={control}
                      label={t(`${i18nForm}.numberOfOutdoorUnits`)}
                      inputProps={{ disabled: !userIsProjectOwner }}
                      validationRules={validationRules}
                      onBlur={submitField}
                      type="number"
                      error={errors?.numberOfOutdoorUnits}
                    />
                  </Col>
                </Row>
              </>
            )}
            <ProjectStepProposition
              id={stepId}
              propositions={propositions.options[stepId]}
            />
          </>
        )}
      </form>
    </>
  );
};

export default AshpUnitConfigurationPage;
