import { useQuery } from '@tanstack/react-query';
import { useIsAuthenticated } from '@azure/msal-react';
import { getProjectsOfUser } from '../api';
import { QUERY_REFETCH_INTERVAL } from '../const';

export const useUserProjects = () => {
  const isAuthenticated = useIsAuthenticated();
  return useQuery({
    queryKey: ['projects'],
    queryFn: async () => {
      if (isAuthenticated) {
        return (await getProjectsOfUser()).data;
      } else {
        return [];
      }
    },
    refetchInterval: QUERY_REFETCH_INTERVAL,
  });
};
