import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { AppLanguage } from '../../const';
import { setLang } from '../../utils/splashUtils';

const GCTop = () => {
  const {
    i18n: { language, getResourceBundle },
    t,
  } = useTranslation();

  const { pathname, search } = useLocation();

  const matchingRoute = useMemo(() => {
    const paths = pathname.split('/');
    const currentLangRoutes = getResourceBundle(language, 'routes');
    const translatedPath = paths.map((path) => {
      const routeKey: string | undefined = Object.keys(currentLangRoutes).find(
        (key) => {
          return currentLangRoutes[key] === path;
        }
      );

      if (routeKey) {
        return t(`routes:${routeKey}`, { lng: t('lngLinks.lang') });
      }

      return path;
    });

    return translatedPath.join('/') + search;
  }, [getResourceBundle, language, pathname, search, t]);

  if (language === 'fr') {
    return (
      <div id="def-top">
        <ul id="wb-tphp">
          <li className="wb-slc">
            <a className="wb-sl" href="#wb-cont">
              Passer au contenu principal
            </a>
          </li>
          <li className="wb-slc visible-sm visible-md visible-lg">
            <a className="wb-sl" href="#wb-info">
              Passer à «&#160;À propos de ce site&#160;»
            </a>
          </li>
        </ul>
        <header>
          <div id="wb-bnr" className="container">
            <section id="wb-lng" className="text-right">
              <h2 className="wb-inv">Sélection de la langue</h2>
              <ul className="list-inline margin-bottom-none">
                <li>
                  <a
                    lang="en"
                    href={matchingRoute}
                    onClick={() => setLang(AppLanguage.English)}
                  >
                    English
                  </a>
                </li>
              </ul>
            </section>
            <div className="row">
              <div
                className="brand col-xs-5 col-md-4"
                property="publisher"
                typeof="GovernmentOrganization"
              >
                <a href="https://www.canada.ca/fr.html" property="url">
                  <img
                    src="https://www.canada.ca/etc/designs/canada/cdts/gcweb/v4_0_32/assets/sig-blk-fr.svg"
                    alt=""
                    property="logo"
                  />
                  <span className="wb-inv" property="name">
                    {' '}
                    Gouvernement du Canada /{' '}
                    <span lang="en">Government of Canada</span>
                  </span>
                </a>
                <meta property="areaServed" typeof="Country" content="Canada" />
                <link
                  property="logo"
                  href="https://www.canada.ca/etc/designs/canada/cdts/gcweb/v4_0_32/assets/wmms-blk.svg"
                />
              </div>
              <section id="wb-srch" className="col-lg-8 text-right">
                <h2>Recherche</h2>
                <form
                  action="https://www.canada.ca/fr/sr.html"
                  method="get"
                  name="cse-search-box"
                  role="search"
                  className="form-inline"
                >
                  <div className="form-group">
                    <label htmlFor="wb-srch-q" className="wb-inv">
                      Rechercher dans Canada.ca
                    </label>
                    <input name="cdn" defaultValue="canada" type="hidden" />
                    <input name="st" defaultValue="s" type="hidden" />
                    <input name="num" defaultValue="10" type="hidden" />
                    <input name="langs" defaultValue="fr" type="hidden" />
                    <input name="st1rt" defaultValue="1" type="hidden" />
                    <input name="s5bm3ts21rch" defaultValue="x" type="hidden" />
                    <input
                      id="wb-srch-q"
                      list="wb-srch-q-ac"
                      className="wb-srch-q form-control"
                      name="q"
                      type="search"
                      defaultValue=""
                      size={34}
                      maxLength={170}
                      placeholder="Rechercher dans Canada.ca"
                    />
                    <input
                      type="hidden"
                      name="_charset_"
                      defaultValue="UTF-8"
                    />
                    <datalist id="wb-srch-q-ac"></datalist>
                  </div>
                  <div className="form-group submit">
                    <button
                      type="submit"
                      id="wb-srch-sub"
                      className="btn btn-primary btn-small"
                      name="wb-srch-sub"
                    >
                      <span className="glyphicon-search glyphicon"></span>
                      <span className="wb-inv">Recherche</span>
                    </button>
                  </div>
                </form>
              </section>
            </div>
          </div>
          <nav className="gcweb-menu" typeof="SiteNavigationElement">
            <div className="container">
              <h2 className="wb-inv">Menu</h2>
              <button type="button" aria-haspopup="true" aria-expanded="false">
                Menu<span className="wb-inv"> principal</span>{' '}
                <span className="expicon glyphicon glyphicon-chevron-down"></span>
              </button>
              <ul
                role="menu"
                aria-orientation="vertical"
                data-ajax-replace="//www.canada.ca/content/dam/canada/sitemenu/sitemenu-v2-fr.html"
              >
                <li role="presentation">
                  <a
                    role="menuitem"
                    href="https://www.canada.ca/fr/services/emplois.html"
                  >
                    Emplois et milieu de travail
                  </a>
                </li>
                <li role="presentation">
                  <a
                    role="menuitem"
                    href="https://www.canada.ca/fr/services/immigration-citoyennete.html"
                  >
                    Immigration et citoyenneté
                  </a>
                </li>
                <li role="presentation">
                  <a role="menuitem" href="https://voyage.gc.ca/">
                    Voyage et tourisme
                  </a>
                </li>
                <li role="presentation">
                  <a
                    role="menuitem"
                    href="https://www.canada.ca/fr/services/entreprises.html"
                  >
                    Entreprises et industrie
                  </a>
                </li>
                <li role="presentation">
                  <a
                    role="menuitem"
                    href="https://www.canada.ca/fr/services/prestations.html"
                  >
                    Prestations
                  </a>
                </li>
                <li role="presentation">
                  <a
                    role="menuitem"
                    href="https://www.canada.ca/fr/services/sante.html"
                  >
                    Santé
                  </a>
                </li>
                <li role="presentation">
                  <a
                    role="menuitem"
                    href="https://www.canada.ca/fr/services/impots.html"
                  >
                    Impôts
                  </a>
                </li>
                <li role="presentation">
                  <a
                    role="menuitem"
                    href="https://www.canada.ca/fr/services/environnement.html"
                  >
                    Environnement et ressources naturelles
                  </a>
                </li>
                <li role="presentation">
                  <a
                    role="menuitem"
                    href="https://www.canada.ca/fr/services/defense.html"
                  >
                    Sécurité nationale et défense
                  </a>
                </li>
                <li role="presentation">
                  <a
                    role="menuitem"
                    href="https://www.canada.ca/fr/services/culture.html"
                  >
                    Culture, histoire et sport
                  </a>
                </li>
                <li role="presentation">
                  <a
                    role="menuitem"
                    href="https://www.canada.ca/fr/services/police.html"
                  >
                    Services de police, justice et urgences
                  </a>
                </li>
                <li role="presentation">
                  <a
                    role="menuitem"
                    href="https://www.canada.ca/fr/services/transport.html"
                  >
                    Transport et infrastructure
                  </a>
                </li>
                <li role="presentation">
                  <a
                    role="menuitem"
                    href="http://international.gc.ca/world-monde/index.aspx?lang=fra"
                  >
                    Canada et le monde
                  </a>
                </li>
                <li role="presentation">
                  <a
                    role="menuitem"
                    href="https://www.canada.ca/fr/services/finance.html"
                  >
                    Argent et finances
                  </a>
                </li>
                <li role="presentation">
                  <a
                    role="menuitem"
                    href="https://www.canada.ca/fr/services/science.html"
                  >
                    Science et innovation
                  </a>
                </li>
              </ul>
            </div>
          </nav>
          <nav id="wb-bc" property="breadcrumb">
            <h2>Vous êtes ici :</h2>
            <div className="container">
              <ol className="breadcrumb">
                <li>
                  <a href="https://www.canada.ca/fr.html">Canada.ca</a>
                </li>
              </ol>
            </div>
          </nav>
        </header>
      </div>
    );
  }

  if (language === 'en') {
    return (
      <div id="def-top">
        <ul id="wb-tphp">
          <li className="wb-slc">
            <a className="wb-sl" href="#wb-cont">
              Skip to main content
            </a>
          </li>
          <li className="wb-slc visible-sm visible-md visible-lg">
            <a className="wb-sl" href="#wb-info">
              Skip to "About this site"
            </a>
          </li>
        </ul>
        <header>
          <div id="wb-bnr" className="container">
            <section id="wb-lng" className="text-right">
              <h2 className="wb-inv">Language selection</h2>
              <ul className="list-inline margin-bottom-none">
                <li>
                  <a
                    lang="fr"
                    href={matchingRoute}
                    onClick={() => setLang(AppLanguage.French)}
                  >
                    Français
                  </a>
                </li>
              </ul>
            </section>
            <div className="row">
              <div
                className="brand col-xs-5 col-md-4"
                property="publisher"
                typeof="GovernmentOrganization"
              >
                <a href="https://www.canada.ca/en.html" property="url">
                  <img
                    src="https://www.canada.ca/etc/designs/canada/cdts/gcweb/v4_0_32/assets/sig-blk-en.svg"
                    alt=""
                    property="logo"
                  />
                  <span className="wb-inv" property="name">
                    {' '}
                    Government of Canada /{' '}
                    <span lang="fr">Gouvernement du Canada</span>
                  </span>
                </a>
                <meta property="areaServed" typeof="Country" content="Canada" />
                <link
                  property="logo"
                  href="https://www.canada.ca/etc/designs/canada/cdts/gcweb/v4_0_32/assets/wmms-blk.svg"
                />
              </div>
              <section id="wb-srch" className="col-lg-8 text-right">
                <h2>Search</h2>
                <form
                  action="https://www.canada.ca/en/sr.html"
                  method="get"
                  name="cse-search-box"
                  role="search"
                  className="form-inline"
                >
                  <div className="form-group">
                    <label htmlFor="wb-srch-q" className="wb-inv">
                      Search Canada.ca
                    </label>
                    <input name="cdn" defaultValue="canada" type="hidden" />
                    <input name="st" defaultValue="s" type="hidden" />
                    <input name="num" defaultValue="10" type="hidden" />
                    <input name="langs" defaultValue="en" type="hidden" />
                    <input name="st1rt" defaultValue="1" type="hidden" />
                    <input name="s5bm3ts21rch" defaultValue="x" type="hidden" />
                    <input
                      id="wb-srch-q"
                      list="wb-srch-q-ac"
                      className="wb-srch-q form-control"
                      name="q"
                      type="search"
                      defaultValue=""
                      size={34}
                      maxLength={170}
                      placeholder="Search Canada.ca"
                    />
                    <input
                      type="hidden"
                      name="_charset_"
                      defaultValue="UTF-8"
                    />
                    <datalist id="wb-srch-q-ac">
                      <select></select>
                    </datalist>
                  </div>
                  <div className="form-group submit">
                    <button
                      type="submit"
                      id="wb-srch-sub"
                      className="btn btn-primary btn-small"
                      name="wb-srch-sub"
                    >
                      <span className="glyphicon-search glyphicon"></span>
                      <span className="wb-inv">Search</span>
                    </button>
                  </div>
                </form>
              </section>
            </div>
          </div>

          <nav className="gcweb-menu" typeof="SiteNavigationElement">
            <div className="container">
              <h2 className="wb-inv">Menu</h2>
              <button type="button" aria-haspopup="true" aria-expanded="false">
                <span className="wb-inv">Main </span>Menu{' '}
                <span className="expicon glyphicon glyphicon-chevron-down"></span>
              </button>
              <ul
                role="menu"
                aria-orientation="vertical"
                data-ajax-replace="//www.canada.ca/content/dam/canada/sitemenu/sitemenu-v2-en.html"
              >
                <li role="presentation">
                  <a
                    role="menuitem"
                    href="https://www.canada.ca/en/services/jobs.html"
                  >
                    Jobs and the workplace
                  </a>
                </li>
                <li role="presentation">
                  <a
                    role="menuitem"
                    href="https://www.canada.ca/en/services/immigration-citizenship.html"
                  >
                    Immigration and citizenship
                  </a>
                </li>
                <li role="presentation">
                  <a role="menuitem" href="https://travel.gc.ca/">
                    Travel and tourism
                  </a>
                </li>
                <li role="presentation">
                  <a
                    role="menuitem"
                    href="https://www.canada.ca/en/services/business.html"
                  >
                    Business and industry
                  </a>
                </li>
                <li role="presentation">
                  <a
                    role="menuitem"
                    href="https://www.canada.ca/en/services/benefits.html"
                  >
                    Benefits
                  </a>
                </li>
                <li role="presentation">
                  <a
                    role="menuitem"
                    href="https://www.canada.ca/en/services/health.html"
                  >
                    Health
                  </a>
                </li>
                <li role="presentation">
                  <a
                    role="menuitem"
                    href="https://www.canada.ca/en/services/taxes.html"
                  >
                    Taxes
                  </a>
                </li>
                <li role="presentation">
                  <a
                    role="menuitem"
                    href="https://www.canada.ca/en/services/environment.html"
                  >
                    Environment and natural resources
                  </a>
                </li>
                <li role="presentation">
                  <a
                    role="menuitem"
                    href="https://www.canada.ca/en/services/defence.html"
                  >
                    National security and defence
                  </a>
                </li>
                <li role="presentation">
                  <a
                    role="menuitem"
                    href="https://www.canada.ca/en/services/culture.html"
                  >
                    Culture, history and sport
                  </a>
                </li>
                <li role="presentation">
                  <a
                    role="menuitem"
                    href="https://www.canada.ca/en/services/policing.html"
                  >
                    Policing, justice and emergencies
                  </a>
                </li>
                <li role="presentation">
                  <a
                    role="menuitem"
                    href="https://www.canada.ca/en/services/transport.html"
                  >
                    Transport and infrastructure
                  </a>
                </li>
                <li role="presentation">
                  <a
                    role="menuitem"
                    href="http://international.gc.ca/world-monde/index.aspx?lang=eng"
                  >
                    Canada and the world
                  </a>
                </li>
                <li role="presentation">
                  <a
                    role="menuitem"
                    href="https://www.canada.ca/en/services/finance.html"
                  >
                    Money and finances
                  </a>
                </li>
                <li role="presentation">
                  <a
                    role="menuitem"
                    href="https://www.canada.ca/en/services/science.html"
                  >
                    Science and innovation
                  </a>
                </li>
              </ul>
            </div>
          </nav>
          <nav id="wb-bc" property="breadcrumb">
            <h2>You are here:</h2>
            <div className="container">
              <ol className="breadcrumb">
                <li>
                  <a href="https://www.canada.ca/en.html">Canada.ca</a>
                </li>
              </ol>
            </div>
          </nav>
        </header>
      </div>
    );
  }
  return null;
};

export default GCTop;
