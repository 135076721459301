import { t } from 'i18next';
import { Button, ButtonToolbar, Col, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { Trans } from 'react-i18next';
import { Input } from '../../../common/Form';
import { HeatPump } from '../../../../model/HeatPump';
import { useNumberFormatter } from '../../../../hooks/useNumberFormatter';
import {
  BTUH_MAX_DECIMALS,
  COP_MAX_DECIMALS,
} from '../../../../const/Formatter';
import { TooltipIcon, TooltipWrapper } from '../../../common';
import {
  getMaxLengthRule,
  getMinRule,
  getRequiredRule,
  isNotEmptyString,
  isNumber,
} from '../../../../utils/validationUtils';
import { BTU_MIN, COP_MIN, NAME_LENGTH_MAX } from '../../../../const';

interface Props {
  addDisabled?: boolean;
  data?: HeatPump;
  i18nForm: string;
  onCancel?: () => void;
  onSubmit?: (heatPump: HeatPump) => Promise<void>;
  userIsProjectOwner?: boolean;
}

const CustomHeatPumpForm: React.FC<Props> = ({
  addDisabled,
  data,
  i18nForm,
  onCancel,
  onSubmit,
  userIsProjectOwner,
}) => {
  const {
    control,
    handleSubmit: submitWrapper,
    reset,
    formState: { errors },
  } = useForm<HeatPump>({
    defaultValues: { ...new HeatPump(data), isCustom: true },
    mode: 'onChange',
  });

  const handleSubmit = async (values: HeatPump) => {
    await onSubmit?.(values);
    if (!data) {
      reset({ ...new HeatPump(), isCustom: true });
    }
  };

  const btuFormatter = useNumberFormatter(BTUH_MAX_DECIMALS);
  const copFormatter = useNumberFormatter(COP_MAX_DECIMALS);

  const textRules = {
    ...getMaxLengthRule(NAME_LENGTH_MAX),
    ...getRequiredRule(),
    validate: isNotEmptyString,
  };

  const btuRules = {
    ...getMinRule(BTU_MIN),
    ...getRequiredRule(),
    validate: isNumber,
  };

  const copRules = {
    ...getMinRule(COP_MIN),
    ...getRequiredRule(),
    validate: isNumber,
  };

  return (
    <form className="filter" noValidate onSubmit={submitWrapper(handleSubmit)}>
      <Row>
        <Col xs={12}>
          <Trans i18nKey={`${i18nForm}.custom.allRequired`}>
            All the fields with an asterisk (
            <span className="text-danger">*</span>) are required.
          </Trans>
        </Col>
      </Row>

      <Row className="margin-top-medium">
        <Col xs={12} lg={6}>
          <Input
            label={t(`${i18nForm}.custom.name`)}
            id="name"
            control={control}
            error={errors.name}
            placeholder={t(`${i18nForm}.custom.name`)}
            required
            validationRules={textRules}
          />
        </Col>
        <Col xs={12} lg={6}>
          <Input
            label={t(`${i18nForm}.compareList.ahri`)}
            id="ahri_Number"
            control={control}
            error={errors.ahri_Number}
            placeholder={t(`${i18nForm}.compareList.ahri`)}
            required
            validationRules={textRules}
          />
        </Col>
      </Row>

      <Row>
        <Col
          xs={12}
          lg={12}
          className="header-tooltip-wrapper"
          componentClass="span"
        >
          <h3>{t(`${i18nForm}.filter.capacity`)}</h3>
          <TooltipIcon
            alt={t(`${i18nForm}.filter.capacityTooltipAlt`)}
            glyph="info-sign"
            id={
              !!data
                ? `edit-capacity-tooltip-${data.ahri_Number}`
                : 'custom-capacity-info-tooltip'
            }
            placement="bottom"
            tooltip={t(`${i18nForm}.filter.capacityTooltipText`)}
          />
        </Col>
      </Row>

      <Row>
        <Col xs={12} lg={6}>
          <Input
            id="maxHeatingCapacityAt5F"
            addonRight={t('units.btuPerHour')}
            control={control}
            label={t(`${i18nForm}.compareList.heatingRatedBtuh`)}
            inputProps={{ disabled: !userIsProjectOwner }}
            validationRules={btuRules}
            error={errors.maxHeatingCapacityAt5F}
            formatter={btuFormatter}
            required
          />
        </Col>
        <Col xs={12} lg={3}>
          <Input
            id="temp5"
            addonRight={t('units.degreesFahrenheit')}
            control={control}
            label={t(`${i18nForm}.custom.atTemp`)}
            inputProps={{ disabled: true }}
            formatter={copFormatter}
          />
        </Col>
        <Col xs={12} lg={3}>
          <Input
            id="copAt5F"
            control={control}
            label={t(`${i18nForm}.custom.cop`)}
            inputProps={{ disabled: !userIsProjectOwner }}
            validationRules={copRules}
            error={errors.copAt5F}
            formatter={copFormatter}
            required
          />
        </Col>
      </Row>

      <Row className="margin-top-medium">
        <Col xs={12} lg={6}>
          <Input
            id="maxHeatingCapacityAt17F"
            addonRight={t('units.btuPerHour')}
            control={control}
            label={t(`${i18nForm}.compareList.heatingRatedBtuh`)}
            inputProps={{ disabled: !userIsProjectOwner }}
            validationRules={btuRules}
            error={errors.maxHeatingCapacityAt17F}
            formatter={btuFormatter}
            required
          />
        </Col>
        <Col xs={12} lg={3}>
          <Input
            id="temp17"
            addonRight={t('units.degreesFahrenheit')}
            control={control}
            label={t(`${i18nForm}.custom.atTemp`)}
            inputProps={{ disabled: true }}
            formatter={copFormatter}
          />
        </Col>
        <Col xs={12} lg={3}>
          <Input
            id="copAt17F"
            control={control}
            label={t(`${i18nForm}.custom.cop`)}
            inputProps={{ disabled: !userIsProjectOwner }}
            validationRules={copRules}
            error={errors.copAt17F}
            formatter={copFormatter}
            required
          />
        </Col>
      </Row>

      <Row className="margin-top-medium">
        <Col xs={12} lg={6}>
          <Input
            id="maxHeatingCapacityAt47F"
            addonRight={t('units.btuPerHour')}
            control={control}
            label={t(`${i18nForm}.compareList.heatingRatedBtuh`)}
            inputProps={{ disabled: !userIsProjectOwner }}
            validationRules={btuRules}
            error={errors.maxHeatingCapacityAt47F}
            formatter={btuFormatter}
            required
          />
        </Col>
        <Col xs={12} lg={3}>
          <Input
            id="temp47"
            addonRight={t('units.degreesFahrenheit')}
            control={control}
            label={t(`${i18nForm}.custom.atTemp`)}
            inputProps={{ disabled: true }}
            formatter={copFormatter}
          />
        </Col>
        <Col xs={12} lg={3}>
          <Input
            id="copAt47F"
            control={control}
            label={t(`${i18nForm}.custom.cop`)}
            inputProps={{ disabled: !userIsProjectOwner }}
            validationRules={copRules}
            error={errors.copAt47F}
            formatter={copFormatter}
            required
          />
        </Col>
      </Row>

      <Row className="margin-top-large">
        <Col xs={12}>
          <ButtonToolbar className="filter-btn-group">
            <Button
              onClick={onCancel ?? (() => reset(new HeatPump()))}
              className="btn btn-primary btn-contrast btn-sm"
            >
              {t(onCancel ? 'common.cancel' : 'common.clear')}
            </Button>
            <TooltipWrapper
              disabled={!addDisabled}
              id="add-max-tooltip"
              tooltip={t(`${i18nForm}.heatPump.maxSelected`)}
            >
              <Button
                className="btn btn-contrast btn-sm"
                disabled={addDisabled}
                type="submit"
              >
                {t(data ? 'common.save' : `${i18nForm}.custom.addButton`)}
              </Button>
            </TooltipWrapper>
          </ButtonToolbar>
        </Col>
      </Row>
    </form>
  );
};

export default CustomHeatPumpForm;
