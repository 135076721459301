/* eslint-disable react-hooks/exhaustive-deps */
import { PageHeader } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { Project } from '../../model/Project';
import { ProjectMenu, ProjectSettingsForm } from '.';

interface Props {
  project?: Project;
  userProjects: Project[];
}

const ProjectSettings: React.FC<Props> = ({ project, userProjects }) => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>
          {t('pageTitle.projectSettings', { projectName: project?.name })} -{' '}
          {t('pageTitle.appName')}
        </title>
      </Helmet>
      <PageHeader>
        {t('pageTitle.projectSettings', { projectName: project?.name })}
      </PageHeader>
      <ProjectMenu project={project} />

      <ProjectSettingsForm project={project} userProjects={userProjects} />
    </>
  );
};

export default ProjectSettings;
