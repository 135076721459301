import clsx from 'clsx';
import React, { ReactNode } from 'react';
import { Col, ColProps, Radio as BSRadio, Row } from 'react-bootstrap';
import {
  Control,
  Controller,
  FieldError,
  FieldErrorsImpl,
  Merge,
} from 'react-hook-form';
import { FieldGroup } from '.';

interface RadioItem {
  value: string | number;
  label: string;
  disabled?: boolean;
  labelData?: any;
  colProps?: ColProps;
  image?: ReactNode;
}

interface Props {
  control: Control<any>;
  defaultValue?: string | number | boolean;
  error?: FieldError | Merge<FieldError, FieldErrorsImpl<any>>;
  help?: string;
  id: string;
  items: RadioItem[];
  label?: string;
  labelHidden?: boolean;
  inline?: boolean;
  onChange?: (value: any) => void;
  radioClassName?: string;
  renderLabel?: (item: RadioItem) => ReactNode;
  noLegend?: boolean;
  containerClassName?: string;
  validationRules?: {
    [key: string]:
      | string
      | boolean
      | number
      | ((value: number) => boolean | string);
  };
}

const RadioGroup: React.FC<Props> = ({
  control,
  defaultValue,
  error,
  help,
  items,
  id,
  label,
  labelHidden = false,
  inline = false,
  onChange,
  radioClassName,
  renderLabel,
  noLegend,
  containerClassName,
  validationRules,
}) => {
  return (
    <FieldGroup
      error={error}
      help={help}
      id={id}
      className={containerClassName}
    >
      <fieldset>
        {!noLegend && (
          <legend className={clsx({ 'sr-only': labelHidden })}>{label}</legend>
        )}
        <Controller
          name={id}
          control={control}
          defaultValue={defaultValue}
          rules={validationRules}
          render={({ field, fieldState }) => (
            <Row>
              {items &&
                items.map((item) => {
                  return (
                    <Col xs={inline ? 6 : 12} key={`${id}-${item.value}`}>
                      <Row>
                        <Col xs={12} {...item.colProps}>
                          {item.image}
                          <BSRadio
                            aria-disabled={item.disabled}
                            disabled={item.disabled}
                            name={id}
                            aria-label={item.label}
                            checked={field.value === item.value}
                            value={item.value}
                            className={radioClassName}
                            onChange={(event: any) => {
                              field.onChange(event);

                              if (onChange && !fieldState.invalid) {
                                onChange({
                                  [field.name]: event.target.value,
                                });
                              }
                            }}
                          >
                            {renderLabel ? renderLabel(item) : item.label}
                          </BSRadio>
                        </Col>
                      </Row>
                      {item.labelData &&
                        item.labelData.appendElements &&
                        item.labelData.appendElements.map(
                          (appendElement, i) => {
                            return (
                              <Row key={i}>
                                <Col xs={12} {...item.colProps}>
                                  {appendElement}
                                </Col>
                              </Row>
                            );
                          }
                        )}
                    </Col>
                  );
                })}
            </Row>
          )}
        ></Controller>
      </fieldset>
    </FieldGroup>
  );
};

export default RadioGroup;
