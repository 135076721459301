import React from 'react';
import { Glyphicon } from 'react-bootstrap';
import TooltipWrapper from './TooltipWrapper';

interface Props {
  alt?: string;
  glyph: string;
  id: string;
  placement?: 'top' | 'right' | 'bottom' | 'left';
  tooltip: string | React.ReactNode;
}

const TooltipIcon: React.FC<Props> = ({
  alt,
  glyph,
  id,
  placement = 'bottom',
  tooltip,
}: Props) => {
  return (
    <TooltipWrapper id={id} placement={placement} tooltip={tooltip}>
      <Glyphicon alt={alt} glyph={glyph} tabIndex={0} />
    </TooltipWrapper>
  );
};

export default TooltipIcon;
