/* eslint-disable react-hooks/exhaustive-deps */
import { JSX, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DataTable, {
  TableColumn,
  TableStyles,
} from 'react-data-table-component';
import { useMediaQuery } from 'react-responsive';
import { useQuery } from '@tanstack/react-query';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faPlayCircle } from '@fortawesome/free-solid-svg-icons';
import { Step15Data, Steps } from '../../../model/Step';
import { DataTableCell } from '../../Dashboard';
import { SM_MAX_SIZE } from '../../../const/Responsive';
import { getCalculations } from '../../../api/projects';
import { Project, ProjectPropositions } from '../../../model/Project';
import { GHGCostEnergyUse } from '../../../model/GHGCostEnergyUse';
import ProjectStepProposition from '../ProjectStepProposition';
import { getProjectPropositions } from '../../../const/Projects';
import { env } from '../../../env';
import { Spinner } from '../../common';
import { VideoModal } from '../Videos';

const customStyles: TableStyles = {
  table: {
    style: {
      marginTop: 20,
      marginBottom: 20,
    },
  },
  headRow: {
    style: {
      borderBottomStyle: 'solid',
      borderBottomWidth: '1px',
      borderBottomColor: '#000',
      background: '#26374A',
    },
  },
  headCells: {
    style: {
      display: 'flex',
      color: 'white',
      fontFamily: 'Noto Sans, sans-serif',
      fontSize: 14,
      fontWeight: 600,
      justifyContent: 'space-between',
      span: {
        fontSize: 12,
      },

      '& div': {
        whiteSpace: 'pre-wrap',
        fontWeight: 600,
        minHeight: 15,
      },
    },
  },
  rows: {
    style: {
      backgroundColor: '#FBFBFB',
      borderBottom: 'none',
    },
    stripedStyle: {
      backgroundColor: 'transparent',
    },
  },
  cells: {
    style: {
      borderBottom: '#26374A solid 1px',
      fontSize: 20,

      '& a': {
        color: '#000',
      },
    },
  },
};

const mobileStyle: TableStyles = {
  table: {
    style: {
      marginTop: 20,
      marginBottom: 20,
    },
  },
  headRow: {
    style: {
      display: 'none',
    },
  },
  rows: {
    style: {
      display: 'flex',
      flexWrap: 'wrap',
      backgroundColor: '#FBFBFB',
      '&:first-of-type': {
        borderTop: '#26374A solid 1px',
      },
      '&:not(:last-of-type)': {
        borderBottom: '#26374A solid 1px',
      },

      borderBottom: '#26374A solid 1px',
    },
    stripedStyle: {
      backgroundColor: 'transparent',
    },
  },
  cells: {
    style: {
      border: 'none',
      display: 'inline-block',
      fontSize: 18,
      lineHeight: '26px',
      marginTop: 15,

      a: {
        color: '#333333',
      },

      '.table-cell-column-label': {
        fontSize: 16,
        fontWeight: 600,
      },
    },
  },
};

interface Props {
  project: Project;
  stepData?: Step15Data;
  displayCompact?: boolean;
  userIsProjectOwner?: boolean;
  additionalInfo?: JSX.Element;
}

const GHGCostEnergyUseComparisonPage: React.FC<Props> = ({
  project,
  stepData,
  displayCompact,
  userIsProjectOwner,
  additionalInfo,
}) => {
  const { t } = useTranslation();
  const isSM = useMediaQuery({ query: `(max-width: ${SM_MAX_SIZE}px)` });
  const [showVideo, setShowVideo] = useState(false);

  const propositions: ProjectPropositions = useMemo(() => {
    return getProjectPropositions(project);
  }, [project]);

  const data = useQuery({
    queryKey: ['ghgCostEnergyUseComparisons'],
    queryFn: async () => {
      return userIsProjectOwner
        ? (await getCalculations(project.id)).data
        : stepData?.ghgCostEnergyUseComparisons;
    },
    refetchOnMount: false,
    refetchInterval: false,
    refetchOnWindowFocus: false,
  });

  const stepId = Steps.STEP_15;

  const i18nTable = `step-${stepId}:table`;

  const columns: TableColumn<GHGCostEnergyUse>[] = [
    {
      id: 'index',
      name: '#',
      selector: (row, index = 0) => index + 1,
      width: '7%',
      cell: (row, index) => {
        return <DataTableCell columnLabel="#">{index + 1}</DataTableCell>;
      },
    },
    {
      id: 'heatPumpModel',
      name: t(`${i18nTable}.heatPumpModel`),
      selector: (row) => row.name ?? '',
      width: isSM ? '60%' : '23%',
      cell: (row) => {
        return (
          <DataTableCell columnLabel={t(`${i18nTable}.heatPumpModel`)}>
            {row.name}
            {row.outdoorUnitNumber ? ` (${row.outdoorUnitNumber})` : ''}
          </DataTableCell>
        );
      },
    },
    {
      id: 'ghgEmissions',
      name: t(`${i18nTable}.ghgEmissions`),
      selector: (row) => row.ghg ?? 0,
      width: isSM ? '40%' : undefined,
      cell: (row) => (
        <DataTableCell columnLabel={t(`${i18nTable}.ghgEmissions`)}>
          {row.ghg}
        </DataTableCell>
      ),
    },
    {
      id: 'heatingCost',
      name: t(`${i18nTable}.heatingCost`),
      selector: (row) => row.cost ?? 0,
      width: isSM ? '100%' : undefined,
      cell: (row) => (
        <DataTableCell columnLabel={t(`${i18nTable}.heatingCost`)}>
          {row.cost}
        </DataTableCell>
      ),
    },
    {
      id: 'heatingEnergyConsumption',
      name: t(`${i18nTable}.heatingEnergyConsumption`),
      selector: (row) => row.energyUse ?? 0,
      width: isSM ? '100%' : undefined,
      cell: (row) => (
        <DataTableCell columnLabel={t(`${i18nTable}.heatingEnergyConsumption`)}>
          {row.energyUse}
        </DataTableCell>
      ),
    },
  ];
  const table = (
    <DataTable
      keyField="ahri_Number"
      customStyles={isSM ? { ...customStyles, ...mobileStyle } : customStyles}
      columns={columns}
      data={data?.data ?? []}
      persistTableHead
      striped
      noDataComponent={t(`${i18nTable}.noResult`)}
    />
  );

  const { mostEfficentHp, hpLowestOperatingCost, hpLowestGreenhouseGaz } =
    useMemo((): {
      mostEfficentHp: GHGCostEnergyUse | undefined;
      hpLowestOperatingCost: GHGCostEnergyUse | undefined;
      hpLowestGreenhouseGaz: GHGCostEnergyUse | undefined;
    } => {
      let mostEfficentHp: GHGCostEnergyUse | undefined = undefined;
      let hpLowestOperatingCost: GHGCostEnergyUse | undefined = undefined;
      let hpLowestGreenhouseGaz: GHGCostEnergyUse | undefined = undefined;
      data.data?.forEach((i) => {
        if (
          mostEfficentHp?.energyUse == null ||
          (mostEfficentHp?.energyUse ?? 0) > (i.energyUse ?? 0)
        ) {
          mostEfficentHp = i;
        }
        if (
          hpLowestOperatingCost?.cost == null ||
          (hpLowestOperatingCost?.cost ?? 0) > (i.cost ?? 0)
        ) {
          hpLowestOperatingCost = i;
        }
        if (
          hpLowestGreenhouseGaz?.ghg == null ||
          (hpLowestGreenhouseGaz?.ghg ?? 0) > (i.ghg ?? 0)
        ) {
          hpLowestGreenhouseGaz = i;
        }
      });

      return { mostEfficentHp, hpLowestOperatingCost, hpLowestGreenhouseGaz };
    }, [data?.data]);

  const videoButton = (
    <Button
      aria-label={`${t('common.watchVideo')}: ${t(
        'project.details.situations.video.title'
      )}`}
      className="btn btn-primary video-button"
      style={{ marginTop: 16 }}
      onClick={(e) => setShowVideo(true)}
    >
      <FontAwesomeIcon icon={faPlayCircle} />
      <span className="video-button-text">{t('common.watchVideo')}</span>
    </Button>
  );

  if (data.isLoading) {
    return <Spinner />;
  }

  return (
    <>
      <VideoModal
        video={{
          href: t(`step-${stepId}:video.href`),
          title: t(`step-${stepId}:video.title`),
        }}
        show={showVideo}
        setShow={setShowVideo}
      />
      {displayCompact ? (
        <>
          {table}
          {videoButton}
          {additionalInfo}
        </>
      ) : (
        <>
          <p>{t(`step-${stepId}:description`)}</p>
          {table}
          {videoButton}
          {data.data?.map(
            (item, index) =>
              item.intermediateResults &&
              Boolean(env.REACT_APP_API_DISPLAY_INTERMEDIATE_RESULTS) && (
                <div
                  className="intermediate-results"
                  key={item.outdoorUnitNumber}
                >
                  <p>
                    {t(`step-${stepId}:intermediateResults`, {
                      name:
                        `#${index + 1} ${item.name ?? ''} ` +
                        (item.outdoorUnitNumber
                          ? `(${item.outdoorUnitNumber ?? ''})`
                          : ''),
                    })}
                  </p>
                  <Button
                    onClick={() => {
                      navigator.clipboard.writeText(
                        item.intermediateResults ?? ''
                      );
                    }}
                  >
                    <FontAwesomeIcon icon={faCopy} />
                  </Button>
                </div>
              )
          )}
          <ProjectStepProposition
            id={stepId}
            propositions={propositions.options[stepId]}
            translationVariables={{
              mostEfficentHP: mostEfficentHp?.name,
              hpLowestOperatingCost: hpLowestOperatingCost?.name,
              hpLowestGreenhouseGaz: hpLowestGreenhouseGaz?.name,
            }}
          />
        </>
      )}
    </>
  );
};

export default GHGCostEnergyUseComparisonPage;
