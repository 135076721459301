export class HeatPumpsFilter {
  brand?: string;
  ahriModelUnit?: string;
  capacity47?: number;
  capacity47Max?: number;
  capacity47Min?: number;
  capacity5?: number;
  capacity5Max?: number;
  capacity5Min?: number;

  constructor(entity?: HeatPumpsFilter) {
    if (entity) {
      Object.assign(this, { ...entity });
    } else {
      this.brand = '';
      this.ahriModelUnit = '';
      this.capacity47 = 47;
      this.capacity47Max = 200000;
      this.capacity47Min = 0;
      this.capacity5 = 5;
      this.capacity5Max = 200000;
      this.capacity5Min = 0;
    }
  }
}
