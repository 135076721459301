import clsx from 'clsx';
import { Button } from 'react-bootstrap';
import DataTable, { TableStyles } from 'react-data-table-component';
import {
  Control,
  FieldError,
  FieldErrorsImpl,
  Merge,
  useFieldArray,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { SM_MAX_SIZE } from '../../const/Responsive';
import { Collaborator } from '../../model/Project';
import { UserRoles } from '../../model/User';
import { isEmail } from '../../utils/validationUtils';
import { Input, Select } from '../common/Form';
import { DataTableCell } from '../Dashboard';

interface Props {
  control: Control<any>;
  error?: Merge<
    FieldError,
    (Merge<FieldError, FieldErrorsImpl<Collaborator>> | undefined)[]
  >;
}

const customStyles: TableStyles = {
  tableWrapper: {
    style: {
      marginBottom: 20,
    },
  },
  noData: {
    style: {
      marginTop: 20,
    },
  },
  headRow: {
    style: {
      borderBottomStyle: 'solid',
      borderBottomWidth: '1px',
      borderBottomColor: '#000',
    },
  },
  headCells: {
    style: {
      color: '#000',
      fontSize: 20,
    },
  },
  rows: {
    style: {
      backgroundColor: '#FBFBFB',
    },
    stripedStyle: {
      backgroundColor: 'transparent',
    },
  },
  cells: {
    style: {
      borderBottomStyle: 'solid',
      borderBottomWidth: '1px',
      borderBottomColor: '#000',

      '& .form-group': {
        marginBottom: 30,
        marginTop: 30,
        width: '100%',

        '& input, & select': {
          width: '100%',
        },

        '& .help-block': {
          position: 'absolute',
        },
      },
    },
  },
};

const mobileStyle: TableStyles = {
  table: {
    style: {
      marginTop: 20,
    },
  },
  headRow: {
    style: {
      display: 'none',
    },
  },
  rows: {
    style: {
      backgroundColor: '#FBFBFB',
      display: 'block',
    },
    stripedStyle: {
      backgroundColor: 'transparent',
    },
  },
  cells: {
    style: {
      display: 'inline-block',
      fontSize: 18,
      lineHeight: '26px',
      marginTop: 15,

      '.table-cell-column-label': {
        fontSize: 16,
        fontWeight: 600,
      },
    },
  },
};

const CollaboratorTable: React.FC<Props> = ({ control, error }) => {
  const { t } = useTranslation();
  const isSM = useMediaQuery({ query: `(max-width: ${SM_MAX_SIZE}px)` });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'collaborators',
  });

  const roleOptions = [
    {
      label: '',
      value: undefined,
    },
    {
      label: t(`user.role.${UserRoles.HVAC_DESIGNER}`),
      value: UserRoles.HVAC_DESIGNER,
    },
    {
      label: t(`user.role.${UserRoles.HVAC_CONTRACTOR}`),
      value: UserRoles.HVAC_CONTRACTOR,
    },
    {
      label: t(`user.role.${UserRoles.ARCHITECT}`),
      value: UserRoles.ARCHITECT,
    },
    {
      label: t(`user.role.${UserRoles.HOME_BUILDER}`),
      value: UserRoles.HOME_BUILDER,
    },
    {
      label: t(`user.role.${UserRoles.HOME_OWNER}`),
      value: UserRoles.HOME_OWNER,
    },
  ];

  const collaboratorColumns = [
    {
      id: 'name',
      name: t('project.collaborator.name.label'),
      width: isSM ? '100%' : undefined,
      cell: (row, index) => (
        <DataTableCell columnLabel={t('project.collaborator.name.label')}>
          <Input
            className="full-width-field"
            control={control}
            labelHidden={true}
            label={t('project.collaborator.name.label')}
            id={`collaborators.${index}.name`}
            key={`name-${row.id}`}
            defaultValue={row.name}
            error={error && error[index]?.name}
            validationRules={{
              required: t('errors.common.required').toString(),
            }}
          />
        </DataTableCell>
      ),
    },
    {
      id: 'email',
      name: t('project.collaborator.email.label'),
      grow: !isSM ? 2 : undefined,
      width: isSM ? '100%' : undefined,
      cell: (row, index) => (
        <DataTableCell columnLabel={t('project.collaborator.email.label')}>
          <Input
            className="full-width-field"
            control={control}
            id={`collaborators.${index}.email`}
            labelHidden={true}
            label={t('project.collaborator.email.label')}
            key={`email-${row.id}`}
            defaultValue={row.email}
            error={error && error[index]?.email}
            validationRules={{
              required: t('errors.common.required').toString(),
              validate: isEmail,
            }}
          />
        </DataTableCell>
      ),
    },
    {
      id: 'role',
      name: t('project.collaborator.role.label'),
      width: isSM ? '100%' : undefined,
      cell: (row, index) => (
        <DataTableCell columnLabel={t('project.collaborator.role.label')}>
          <Select
            formGroupClasses="full-width-field"
            control={control}
            id={`collaborators.${index}.role`}
            labelHidden={true}
            label={t('project.collaborator.role.label')}
            key={`role-${row.id}`}
            defaultValue={row.role}
            options={roleOptions}
            error={error && error[index]?.role}
            validationRules={{
              required: t('errors.common.required').toString(),
            }}
          />
        </DataTableCell>
      ),
    },
    {
      id: 'action',
      right: !isSM,
      button: !isSM,
      width: isSM ? '100%' : undefined,
      cell: (row, index) => {
        return (
          <div className="">
            <Button
              bsSize="small"
              className={clsx({
                'table-form-actions': isSM,
                'pull-right': !isSM,
              })}
              onClick={() => remove(index)}
            >
              {t('common.remove')}
            </Button>
          </div>
        );
      },
    },
  ];

  const addCollaboratorRow = () => {
    append({ name: '', email: '', role: undefined });
  };

  return (
    <div>
      <DataTable
        columns={collaboratorColumns}
        data={fields}
        customStyles={isSM ? { ...customStyles, ...mobileStyle } : customStyles}
        noDataComponent={t('project.collaborator.table.empty')}
        striped
        persistTableHead
      />

      <Button bsSize="sm" onClick={() => addCollaboratorRow()}>
        {t('project.collaborator.addButton.label')}
      </Button>
    </div>
  );
};

export default CollaboratorTable;
