/* eslint-disable react-hooks/exhaustive-deps */
import { useQuery } from '@tanstack/react-query';
import { useEffect, useMemo } from 'react';
import {
  Button,
  Col,
  Glyphicon,
  OverlayTrigger,
  Row,
  Tooltip,
} from 'react-bootstrap';
import {
  Control,
  FieldError,
  FieldErrorsImpl,
  FormState,
  Merge,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { getLocations } from '../../api';
import { useInternationalization } from '../../hooks/useInternationalization';
import { Address } from '../../model/Address';
import { HouseType } from '../../model/House';
import { InstallationType, Project, ProjectType } from '../../model/Project';
import { Input, RadioGroup, Select } from '../common/Form';
import { FuelType } from '../../model/Fuel';
import { useClearProjectBaseCase } from '../../hooks/useClearProjectBaseCase';
import { BaseCase } from '../../model/BaseCase';

interface Props {
  project?: Project;
  control: Control<any>;
  getValues: (payload?: string | string[]) => Object;
  setValue: UseFormSetValue<any>;
  watch: UseFormWatch<any>;
  inCreationMode: boolean;
  userIsProjectOwner?: boolean;
  formState: FormState<any>;
  onBlur?: (value: { [key: string]: string | number }) => void;
  displayCompact?: boolean;
  setCompactHouseTypeImage?: Function | undefined;
  trigger: (name?: string | string[]) => Promise<boolean>;
}

const ProjectIdentificationForm: React.FC<Props> = ({
  project,
  control,
  getValues,
  setValue,
  watch,
  inCreationMode,
  userIsProjectOwner,
  formState,
  onBlur,
  displayCompact,
  setCompactHouseTypeImage,
  trigger,
}) => {
  const { t } = useTranslation();
  const { getTranslation } = useInternationalization();

  const locationsQuery = useQuery({
    queryKey: ['locations'],
    queryFn: async () => {
      return (await getLocations()).data;
    },
  });

  const clearProjectBaseCaseMutation = useClearProjectBaseCase(project);

  const locations = locationsQuery.data ?? [];

  const projectTypeWatch = watch('projectType');
  const installationTypeWatch = watch('installationType');
  const addressWatch = watch('address') as Address;

  const houseTypes = [
    {
      value: HouseType.MULTI_LEVEL_DETACHED_HOME,
      label: t(`houseType.${HouseType.MULTI_LEVEL_DETACHED_HOME}`),
      disabled: !userIsProjectOwner,
      image: (
        <img
          alt={t(`houseType.imageAlt.${HouseType.MULTI_LEVEL_DETACHED_HOME}`)}
          src="/assets/images/multiLevelDetachedHome.png"
          className={'house-type-image'}
        />
      ),
      colProps: { className: 'text-center', sm: 4, md: 5 },
    },
    {
      value: HouseType.BUNGALOW,
      label: t(`houseType.${HouseType.BUNGALOW}`),
      disabled: !userIsProjectOwner,
      image: (
        <img
          alt={t(`houseType.imageAlt.${HouseType.BUNGALOW}`)}
          src="/assets/images/bungalow.png"
          className={'house-type-image'}
        />
      ),
      colProps: { className: 'text-center', sm: 4, md: 5 },
    },
    {
      value: HouseType.TOWNHOME,
      label: t(`houseType.${HouseType.TOWNHOME}`),
      disabled: !userIsProjectOwner,
      image: (
        <img
          alt={t(`houseType.imageAlt.${HouseType.TOWNHOME}`)}
          src="/assets/images/townhome.png"
          className={'house-type-image'}
        />
      ),
      colProps: { className: 'text-center', sm: 4, md: 5 },
    },
    {
      value: HouseType.LOW_RISE_STACKED_TOWNHOUSE,
      label: t(`houseType.${HouseType.LOW_RISE_STACKED_TOWNHOUSE}`),
      disabled: !userIsProjectOwner,
      image: (
        <img
          alt={t(`houseType.imageAlt.${HouseType.LOW_RISE_STACKED_TOWNHOUSE}`)}
          src="/assets/images/lowRiseStackedTownhouse.png"
          className={'house-type-image'}
        />
      ),
      colProps: { className: 'text-center', sm: 4, md: 5 },
    },
    {
      value: HouseType.BUNGALOW_TOWNHOUSE,
      label: t(`houseType.${HouseType.BUNGALOW_TOWNHOUSE}`),
      disabled: !userIsProjectOwner,
      image: (
        <img
          alt={t(`houseType.imageAlt.${HouseType.BUNGALOW_TOWNHOUSE}`)}
          src="/assets/images/bungalowTownhouse.png"
          className={'house-type-image'}
        />
      ),
      colProps: { className: 'text-center', sm: 4, md: 5 },
    },
    {
      value: HouseType.BACK_TO_BACK_TOWN,
      label: t(`houseType.${HouseType.BACK_TO_BACK_TOWN}`),
      disabled: !userIsProjectOwner,
      image: (
        <img
          alt={t(`houseType.imageAlt.${HouseType.BACK_TO_BACK_TOWN}`)}
          src="/assets/images/backToBackTown.png"
          className={'house-type-image'}
        />
      ),
      colProps: { className: 'text-center', sm: 4, md: 5 },
    },
    {
      value: HouseType.DUPLEX_OR_TRIPLEX,
      label: t(`houseType.${HouseType.DUPLEX_OR_TRIPLEX}`),
      disabled: !userIsProjectOwner,
      image: (
        <img
          alt={t(`houseType.imageAlt.${HouseType.DUPLEX_OR_TRIPLEX}`)}
          src="/assets/images/duplex.png"
          className={'house-type-image'}
        />
      ),
      colProps: { className: 'text-center', sm: 4, md: 5 },
    },
  ];

  const projectTypes =
    installationTypeWatch === InstallationType.NEW_HOME
      ? [
          {
            value: ProjectType.NEW_SYSTEM_INSTALLATION,
            disabled: !userIsProjectOwner,
            label: t(`projectType.${ProjectType.NEW_SYSTEM_INSTALLATION}`),
          },
        ]
      : [
          {
            value: ProjectType.EQUIPMENT_REPLACEMENT_ONLY,
            disabled: !userIsProjectOwner,
            label: t(`projectType.${ProjectType.EQUIPMENT_REPLACEMENT_ONLY}`),
          },
          {
            value: ProjectType.SYSTEM_EXTENSION,
            disabled: !userIsProjectOwner,
            label: t(`projectType.${ProjectType.SYSTEM_EXTENSION}`),
          },
          {
            value: ProjectType.FULL_SYSTEM_REPLACEMENT,
            disabled: !userIsProjectOwner,
            label: t(`projectType.${ProjectType.FULL_SYSTEM_REPLACEMENT}`),
          },
        ];

  const installationTypes = [
    {
      value: InstallationType.NEW_HOME,
      disabled: !userIsProjectOwner,
      label: t(`installationType.${InstallationType.NEW_HOME}`),
    },
    {
      value: InstallationType.EXISTING_HOME,
      disabled: !userIsProjectOwner,
      label: t(`installationType.${InstallationType.EXISTING_HOME}`),
    },
  ];

  const countries = useMemo(
    () => [
      { value: '', label: '' },
      ...(locations?.map((location) => ({
        value: location.id,
        disabled: !userIsProjectOwner,
        label: getTranslation(location, 'name'),
      })) ?? []),
    ],
    [locations, userIsProjectOwner]
  );

  const provinces = useMemo(() => {
    const country = locations?.find(
      (location) => location.id === addressWatch.country
    );

    return [
      { value: '', label: '' },
      ...(country?.provinces?.map((province) => ({
        value: province.id,
        disabled: !userIsProjectOwner,
        label: getTranslation(province, 'name'),
      })) ?? []),
    ];
  }, [addressWatch, userIsProjectOwner]);

  const cities = useMemo(() => {
    const country = locations?.find(
      (location) => location.id === addressWatch.country
    );
    const province = country?.provinces?.find(
      (province) => province.id === addressWatch.province
    );

    return [
      { value: '', label: '' },
      ...(province?.cities?.map((city) => ({
        value: city.id,
        disabled: !userIsProjectOwner,
        label: getTranslation(city, 'name'),
      })) ?? []),
    ];
  }, [addressWatch, userIsProjectOwner]);

  const fuelTypes = [
    {
      value: '',
      disabled: !userIsProjectOwner,
      label: t('project.identification.baseCase.fuelType.placeholder'),
    },
    {
      value: FuelType.ELECTRIC_RESISTANCE,
      disabled: !userIsProjectOwner,
      label: t(`fuelType.${FuelType.ELECTRIC_RESISTANCE}`),
    },
    {
      value: FuelType.GAS,
      disabled: !userIsProjectOwner,
      label: t(`fuelType.${FuelType.GAS}`),
    },
    {
      value: FuelType.OIL,
      disabled: !userIsProjectOwner,
      label: t(`fuelType.${FuelType.OIL}`),
    },
    {
      value: FuelType.PROPANE,
      disabled: !userIsProjectOwner,
      label: t(`fuelType.${FuelType.PROPANE}`),
    },
  ];

  useEffect(() => {
    if (setCompactHouseTypeImage) {
      const val = houseTypes.find((ht) => ht.value === project?.houseType);
      setCompactHouseTypeImage(val?.image);
    }
    if (setValue) {
      setValue(
        'installationType',
        project?.installationType || InstallationType.NEW_HOME
      );
      setValue('address', new Address(project?.address) || new Address(), {
        shouldValidate: true,
      });
      setValue('projectType', project?.projectType || undefined);
      setValue('houseType', project?.houseType || undefined);
      setValue('numberOfLevels', project?.numberOfLevels || '');
      setValue('baseCase', project?.baseCase || new BaseCase(), {
        shouldValidate: true,
      });
    }
  }, [project]);

  useEffect(() => {
    //default to EQUIPMENT_REMPLACEMENT if ExistingHome is selected and NewSystemInstallation is still the ProjectType
    if (
      installationTypeWatch === InstallationType.EXISTING_HOME &&
      projectTypeWatch === ProjectType.NEW_SYSTEM_INSTALLATION
    ) {
      setValue('projectType', ProjectType.EQUIPMENT_REPLACEMENT_ONLY);
    }
    //default to NEW_SYSTEM if NewHome is selected and NewSystemInstallation is not the ProjectType
    if (
      installationTypeWatch === InstallationType.NEW_HOME &&
      projectTypeWatch !== ProjectType.NEW_SYSTEM_INSTALLATION
    ) {
      setValue('projectType', ProjectType.NEW_SYSTEM_INSTALLATION);
    }
  }, [installationTypeWatch, projectTypeWatch]);

  useEffect(() => {
    if (
      inCreationMode &&
      locations.length &&
      !locations.find(
        (l) =>
          l.id === addressWatch?.country &&
          l.provinces.find((p) => p.id === addressWatch?.province)
      )
    ) {
      setValue('address.province', '', {
        shouldValidate: true,
      });
      setValue('address.city', '', {
        shouldValidate: true,
      });
    }
  }, [addressWatch?.country, project]);

  useEffect(() => {
    if (
      inCreationMode &&
      locations.length &&
      !locations.find(
        (l) =>
          l.id === addressWatch?.country &&
          l.provinces.find(
            (p) =>
              p.id === addressWatch?.province &&
              p.cities.find((c) => c.id === addressWatch?.city)
          )
      )
    ) {
      setValue('address.city', '', {
        shouldValidate: true,
      });
    }
  }, [addressWatch?.province, project]);

  const getFieldError = (
    error: FieldError | Merge<FieldError, FieldErrorsImpl<any>> | undefined
  ): error is FieldError => {
    return (error as FieldError)?.message !== undefined;
  };

  const baseCaseErrors = formState.errors.baseCase;
  const baseCaseNameError = getFieldError(baseCaseErrors)
    ? undefined
    : baseCaseErrors?.name;
  const baseCaseFuelTypeError = getFieldError(baseCaseErrors)
    ? undefined
    : baseCaseErrors?.fuelType;
  const baseCaseEfficiencyError = getFieldError(baseCaseErrors)
    ? undefined
    : baseCaseErrors?.efficiency;

  const numberOfLevelsInput = (
    <Input
      id="numberOfLevels"
      type="number"
      inputProps={{ max: 4, min: 1, disabled: !userIsProjectOwner }}
      control={control}
      error={formState.errors.numberOfLevels}
      label={t('project.identification.numberOfLevels.label')}
      labelHidden={displayCompact ? false : true}
      helperText={
        !displayCompact
          ? t('project.identification.numberOfLevels.suffix')
          : undefined
      }
      onBlur={onBlur}
      validationRules={{
        required: t('errors.common.required').toString(),
        min: {
          value: 1,
          message: t('errors.project.minNumLevels', {
            min: 1,
          }).toString(),
        },
        max: {
          value: 4,
          message: t('errors.project.maxNumLevels', {
            max: 4,
          }).toString(),
        },
        validate: (value) => {
          if (!Number.isInteger(Number(value))) {
            return t('errors.common.notInteger').toString();
          }
          return true;
        },
      }}
    />
  );

  const baseCaseNameInput = (
    <Input
      id="baseCase.name"
      control={control}
      error={baseCaseNameError}
      label={t('project.identification.baseCase.name.label')}
      onBlur={(value) => {
        trigger(['baseCase.fuelType', 'baseCase.efficiency']);
        if (onBlur) {
          onBlur(value);
        }
      }}
      className="base-case-name-input"
      validationRules={{
        validate: (value: string) => {
          if (
            !value &&
            (!!getValues('baseCase.fuelType') ||
              !!getValues('baseCase.efficiency'))
          ) {
            return t('errors.common.required');
          }
          if (value && value.length > 50) {
            return t('errors.common.maxLength', {
              maxLength: 50,
            });
          }
          return true;
        },
      }}
    />
  );

  const baseCaseFuelTypeInput = (
    <Select
      id="baseCase.fuelType"
      formGroupClasses="base-case-fuel-type-input"
      control={control}
      error={baseCaseFuelTypeError}
      options={fuelTypes}
      label={t('project.identification.baseCase.fuelType.label')}
      onChange={(value) => {
        trigger(['baseCase.name', 'baseCase.efficiency']);
        if (onBlur) {
          onBlur(value);
        }
      }}
      disabled={!userIsProjectOwner}
      validationRules={{
        validate: (value) => {
          if (
            !value &&
            (!!getValues('baseCase.name') || !!getValues('baseCase.efficiency'))
          ) {
            return t('errors.common.required');
          }
          return true;
        },
      }}
    />
  );

  const efficiencyTooltip = (
    <Tooltip id="tooltip">
      {t('project.identification.baseCase.efficiency.tooltip')}
    </Tooltip>
  );

  const baseCaseEfficiencyInput = (
    <Input
      id="baseCase.efficiency"
      type="number"
      className="base-case-efficiency-input"
      inputProps={{
        max: 100,
        min: 0,
        disabled: !userIsProjectOwner,
      }}
      control={control}
      error={baseCaseEfficiencyError}
      label={
        <div className="base-case-efficiency-label">
          {t('project.identification.baseCase.efficiency.label')}{' '}
          <OverlayTrigger placement="bottom" overlay={efficiencyTooltip}>
            <Glyphicon glyph="info-sign" />
          </OverlayTrigger>
        </div>
      }
      onBlur={(value) => {
        trigger(['baseCase.name', 'baseCase.fuelType']);
        if (onBlur) {
          onBlur(value);
        }
      }}
      validationRules={{
        min: {
          value: 0,
          message: t('errors.project.minNumber', {
            min: 0,
          }),
        },
        max: {
          value: 100,
          message: t('errors.project.maxNumber', {
            max: 100,
          }).toString(),
        },
        validate: (value) => {
          if (
            !value &&
            (!!getValues('baseCase.name') || !!getValues('baseCase.fuelType'))
          ) {
            return t('errors.common.required');
          }
          if (value && !Number.isInteger(Number(value))) {
            return t('errors.common.notInteger');
          }
          return true;
        },
      }}
    />
  );

  const clearBaseCase = () => {
    setValue('baseCase.name', '', {
      shouldValidate: false,
    });
    setValue('baseCase.fuelType', '', {
      shouldValidate: false,
    });
    setValue('baseCase.efficiency', undefined, {
      shouldValidate: false,
    });
    trigger(['baseCase.name', 'baseCase.fuelType', 'baseCase.efficiency']);
    if (project && onBlur) {
      clearProjectBaseCaseMutation.mutate();
    }
  };

  const { countryName, provinceName, cityName } = useMemo(() => {
    let countryName: string | undefined = '';
    let provinceName: string | undefined = '';
    let cityName: string | undefined = '';
    if (displayCompact && !userIsProjectOwner) {
      locations.forEach((l) => {
        if (l.id === project?.address?.country) {
          const p = l.provinces.find(
            (p) => p.id === project?.address?.province
          );
          const c = p?.cities.find((c) => c.id === project?.address?.city);

          countryName = getTranslation(l, 'name');
          provinceName = p ? getTranslation(p, 'name') : undefined;
          cityName = c ? getTranslation(c, 'name') : undefined;
        }
      });
    }
    return {
      countryName,
      provinceName,
      cityName,
    };
  }, [project?.address, displayCompact, userIsProjectOwner, locations]);

  const addressErrors = formState.errors.address;
  const countryError = getFieldError(addressErrors)
    ? undefined
    : addressErrors?.country;
  const provinceError = getFieldError(addressErrors)
    ? undefined
    : addressErrors?.province;
  const cityError = getFieldError(addressErrors)
    ? undefined
    : addressErrors?.city;

  return (
    <div className="project-identification-form">
      {displayCompact ? (
        <>
          {userIsProjectOwner ? (
            <>
              <Select
                id="installationType"
                control={control}
                error={formState.errors.installationType}
                options={installationTypes}
                label={t('project.identification.installationType.label')}
                onChange={onBlur}
                validationRules={{
                  required: t('errors.common.required').toString(),
                }}
              />
              <Select
                id="projectType"
                control={control}
                error={formState.errors.projectType}
                options={projectTypes}
                onChange={onBlur}
                validationRules={{
                  required: t('errors.common.required').toString(),
                }}
              />
              <div className="address-form">
                <Select
                  id="address.country"
                  control={control}
                  error={countryError}
                  options={countries}
                  label={t('project.identification.address.country.label')}
                  onChange={onBlur}
                  validationRules={{
                    required: t('errors.common.required').toString(),
                  }}
                />
                <Select
                  id="address.province"
                  control={control}
                  error={provinceError}
                  options={provinces}
                  label={t('project.identification.address.province.label')}
                  onChange={onBlur}
                  validationRules={{
                    required: t('errors.common.required').toString(),
                  }}
                />
                <Select
                  id="address.city"
                  control={control}
                  error={cityError}
                  options={cities}
                  label={t('project.identification.address.city.label')}
                  onChange={onBlur}
                  validationRules={{
                    required: t('errors.common.required').toString(),
                  }}
                />
              </div>
              <Select
                id="houseType"
                control={control}
                error={formState.errors.houseType}
                options={houseTypes}
                label={t('project.identification.houseType.label')}
                onChange={onBlur}
                validationRules={{
                  required: t('errors.common.required').toString(),
                }}
              />
              {numberOfLevelsInput}
              <div className="base-case-form-accordion">
                <label className="section-label">
                  {t('project.identification.baseCase.title')}
                </label>
                {baseCaseNameInput}
                {baseCaseFuelTypeInput}
                {baseCaseEfficiencyInput}
                <Button onClick={clearBaseCase}>
                  {t('project.identification.baseCase.clear')}
                </Button>
              </div>
            </>
          ) : (
            <>
              <div className="selected-option-text">
                <label>
                  {t('project.identification.installationType.label')}
                </label>
                <p>{t(`installationType.${project?.installationType}`)}</p>
              </div>
              <div className="selected-option-text">
                <label>{t('project.identification.address.label')}</label>
                <p>{t(`${countryName}, ${provinceName}, ${cityName}`)}</p>
              </div>
              <div className="selected-option-text">
                <label>{t('project.identification.houseType.label')}</label>
                <p>{t(`projectType.${project?.projectType}`)}</p>
              </div>
              <div className="selected-option-text">
                <label>{t('project.identification.houseType.label')}</label>
                <p>{t(`houseType.${project?.houseType}`)}</p>
              </div>
              <div className="read-only-field">
                <label>
                  {t('project.identification.numberOfLevels.label')}
                </label>
                <p>{project?.numberOfLevels || ''}</p>
              </div>
            </>
          )}
        </>
      ) : (
        <>
          <div className="installation-type">
            <label aria-label={'installationType'}>
              {t('project.identification.installationType.label')}
              <RadioGroup
                id="installationType"
                control={control}
                error={formState.errors.installationType}
                items={installationTypes}
                onChange={onBlur}
                // noLegend
                containerClassName="installation-type-items"
                validationRules={{
                  required: t('errors.common.required').toString(),
                }}
              />
            </label>
            <p className="description">
              {t('project.identification.projectType.description')}
            </p>
            <RadioGroup
              id="projectType"
              control={control}
              error={formState.errors.projectType}
              items={projectTypes}
              onChange={onBlur}
              // noLegend
              validationRules={{
                required: t('errors.common.required').toString(),
              }}
            />
          </div>
          <div className="address">
            <label>
              {t('project.identification.address.label')}
              <p>{t('project.identification.address.description')}</p>
              <div className="address-form">
                <Row>
                  <Col xs={12} lg={4}>
                    <Select
                      id="address.country"
                      control={control}
                      error={countryError}
                      options={countries}
                      label={t('project.identification.address.country.label')}
                      onChange={onBlur}
                      disabled={!userIsProjectOwner}
                      validationRules={{
                        required: t('errors.common.required').toString(),
                      }}
                    />
                  </Col>
                  <Col xs={12} lg={4}>
                    <Select
                      id="address.province"
                      control={control}
                      error={provinceError}
                      options={provinces}
                      label={t('project.identification.address.province.label')}
                      onChange={onBlur}
                      disabled={!userIsProjectOwner}
                      validationRules={{
                        required: t('errors.common.required').toString(),
                      }}
                    />
                  </Col>
                  <Col xs={12} lg={4}>
                    <Select
                      id="address.city"
                      control={control}
                      error={cityError}
                      options={cities}
                      label={t('project.identification.address.city.label')}
                      onChange={onBlur}
                      disabled={!userIsProjectOwner}
                      validationRules={{
                        required: t('errors.common.required').toString(),
                      }}
                    />
                  </Col>
                </Row>
              </div>
            </label>
          </div>
          <label className="description">
            <p>{t('project.identification.houseType.label')}</p>
            {t('project.identification.houseType.description')}
          </label>
          <RadioGroup
            id="houseType"
            control={control}
            error={formState.errors.houseType}
            items={houseTypes}
            onChange={onBlur}
            validationRules={{
              required: t('errors.common.required').toString(),
            }}
          />
          <p className="description">
            {t('project.identification.numberOfLevels.description')}
          </p>
          {numberOfLevelsInput}
          <div className="base-case">
            <label>
              {t('project.identification.baseCase.label')}
              <p>{t('project.identification.baseCase.description')}</p>
              <div className="base-case-form">
                <Row>
                  <Col xs={12} lg={4}>
                    {baseCaseNameInput}
                  </Col>
                  <Col xs={12} lg={4}>
                    {baseCaseFuelTypeInput}
                  </Col>
                  <Col xs={12} lg={4}>
                    {baseCaseEfficiencyInput}
                  </Col>
                </Row>
                <Button
                  className="clear-base-case-button"
                  onClick={clearBaseCase}
                >
                  {t('project.identification.baseCase.clear')}
                </Button>
              </div>
            </label>
          </div>
        </>
      )}
    </div>
  );
};

export default ProjectIdentificationForm;
