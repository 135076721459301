import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useMemo, useState } from 'react';
import { Col, Row, Well } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { saveNewProject } from '../../api';
import { AppRoute } from '../../const';
import { Project } from '../../model/Project';
import { Dialog } from '../common/Dialog';
import { Input, ServerFeedback } from '../common/Form';

type FormValues = {
  name: string;
};

interface Props {
  project?: Project;
  setProject: React.Dispatch<React.SetStateAction<Project | undefined>>;
  userProjects: Project[];
}

const CopyProjectDialog: React.FC<Props> = ({
  project,
  setProject,
  userProjects,
}) => {
  const history = useHistory();
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const [serverError, setServerError] = useState<string | undefined>();
  const { control, formState, getValues } = useForm<FormValues>({
    defaultValues: {
      name: '',
    },
    mode: 'onChange',
    shouldUnregister: true,
  });
  const createProjectRoute = `/${t(AppRoute.CreateProject)}`;

  const saveNewMutation = useMutation({
    mutationFn: (project: any) => saveNewProject(project),
  });

  const nameMap = useMemo(() => {
    const names = new Set();
    userProjects.forEach((project) => {
      names.add(project.name?.toLowerCase());
    });
    return names;
  }, [userProjects]);

  const copyProject = () => {
    if (project) {
      saveNewMutation.mutate(
        {
          ...project,
          id: '',
          name: getValues('name'),
          createdAt: new Date(),
          updatedAt: new Date(),
          isStarted: false,
          isCompleted: false,
          comments: {},
        },
        {
          onError: (err) => {
            setServerError(t('errors.common.unknown'));
            console.log(err);
          },
          onSuccess: async ({ data }) => {
            await queryClient.invalidateQueries({ queryKey: ['projects'] });
            if (data && data.id) {
              history.push(
                `${createProjectRoute}/${t(
                  AppRoute.CreateProjectSettings
                )}?id=${data.id}`
              );
            } else {
              setServerError(t('errors.common.requiresRecentLogin'));
            }
          },
        }
      );
    }
  };

  return (
    <Dialog
      actionBtns={[
        {
          type: 'submit',
          disabled: !formState.isValid || saveNewMutation.isPending,
          'aria-disabled': saveNewMutation.isPending,
          label: t('project.copyModal.submit'),
          onClick: () => copyProject(),
        },
      ]}
      closeLabel={t('common.cancel')}
      onHide={() => setProject(undefined)}
      show={!!project}
      title={t('project.copyModal.title')}
      modalProps={{
        onHide: () => {
          setProject(undefined);
        },
        className: 'delete-project-modal',
      }}
    >
      <p>{t('project.copyModal.text')}</p>
      <Input
        id="name"
        className="create-project-name"
        control={control}
        error={formState.errors.name}
        label={t('project.copyModal.fieldLabel')}
        type="text"
        validationRules={{
          required: t('errors.common.required').toString(),
          validate: (value) => {
            if (nameMap.has(String(value).toLowerCase())) {
              return t('errors.project.nameAlreadyUsed').toString();
            }
            return true;
          },
        }}
      />
      {!!serverError && (
        <ServerFeedback type="error">{t(serverError)}</ServerFeedback>
      )}
      <Well>
        <Row>
          <Col xs={1}>
            <FontAwesomeIcon icon={faExclamationCircle} />
          </Col>
          <Col xs={11}>
            <div className="well-note-header">{t('common.important')}</div>
            {t('project.copyModal.note')}
          </Col>
        </Row>
      </Well>
    </Dialog>
  );
};

export default CopyProjectDialog;
