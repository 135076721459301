/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from 'react';
import { Col, Panel, PanelGroup, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  BTUH_MAX_DECIMALS,
  PERCENTAGE_MAX_DECIMALS,
} from '../../../../const/Formatter';
import { useNumberFormatter } from '../../../../hooks/useNumberFormatter';
import { BackupType } from '../../../../model/HeatPumpWithBackup/BackupType';
import { FurnaceCondition } from '../../../../model/HeatPumpWithBackup/FurnaceCondition';
import { FurnaceType } from '../../../../model/HeatPumpWithBackup/FurnaceType';
import {
  DEFAULT_GAS_AFUE,
  HeatPumpWithBackup,
} from '../../../../model/HeatPumpWithBackup/HeatPumpWithBackup';
import { Step12Data, StepOption, Steps } from '../../../../model/Step';
import { Input, RadioGroup, Select } from '../../../common/Form';
import {
  StepOptionRadioItem,
  StepPageAccordianItemHeader,
} from '../../StepPage';
import { getFurnaceAfueDefaultValue } from '../../../../utils/projectUtils';

interface Props {
  stepData?: Step12Data;
  updateProject?: (value) => void;
  displayCompact?: boolean;
  userIsProjectOwner?: boolean;
}

const BackupHeatSourcePage: React.FC<Props> = ({
  stepData,
  updateProject,
  displayCompact,
  userIsProjectOwner,
}) => {
  const { t } = useTranslation();
  const stepId = Steps.STEP_12_1;
  const i18nForm = `step-${stepId}:form`;
  const [activeKey, setActiveKey] = useState(0);
  const btuhFormatter = useNumberFormatter(BTUH_MAX_DECIMALS);
  const percentageFormatter = useNumberFormatter(PERCENTAGE_MAX_DECIMALS);

  const {
    control,
    formState: { errors },
    setValue,
    getValues,
  } = useForm<Step12Data>({
    defaultValues: {
      heatPumpCompareList:
        stepData?.heatPumpCompareList?.map(
          (heatPump) => new HeatPumpWithBackup(heatPump)
        ) ?? [],
    },
    mode: 'onBlur',
  });

  const formValues = getValues();

  const submitField = () => {
    updateProject &&
      updateProject({
        [`steps.${[Steps.STEP_12]}/heatPumpCompareList`]:
          getValues().heatPumpCompareList,
      });
  };

  const onFurnaceTypeChange = (furnaceType: FurnaceType, index: number) => {
    const updatedList = getValues().heatPumpCompareList;
    updatedList[index].furnaceAFUE = getFurnaceAfueDefaultValue(furnaceType);
    updatedList[index].furnaceType = furnaceType;
    setValue(
      `heatPumpCompareList.${index}.furnaceAFUE` as any,
      getFurnaceAfueDefaultValue(furnaceType) as any
    );
    submitField();
  };

  useEffect(() => {
    stepData?.heatPumpCompareList &&
      setValue(
        'heatPumpCompareList',
        stepData.heatPumpCompareList.map((heatPump) => {
          return new HeatPumpWithBackup(heatPump);
        }) ?? []
      );
  }, [stepData]);

  const validationRules = {
    min: {
      value: 1,
      message: t('errors.project.minNumber', {
        min: 1,
      }).toString(),
    },
  };

  const backupOptions: {
    label: string;
    value: string | number;
    disabled?: boolean;
    labelData: StepOption;
  }[] = [
    {
      label: `${t(`${i18nForm}.backupType.a.option.title`)} - ${t(
        `${i18nForm}.backupType.a.option.text`
      )}`,
      value: BackupType.Electric,
      disabled: !userIsProjectOwner,
      labelData: {
        option: {
          text: t(`${i18nForm}.backupType.a.option.text`),
          title: t(`${i18nForm}.backupType.a.option.title`),
        },
      },
    },
    {
      label: `${t(`${i18nForm}.backupType.b.option.title`)} - ${t(
        `${i18nForm}.backupType.b.option.text`
      )}`,
      value: BackupType.Furnace,
      disabled: !userIsProjectOwner,
      labelData: {
        option: {
          text: t(`${i18nForm}.backupType.b.option.text`),
          title: t(`${i18nForm}.backupType.b.option.title`),
        },
      },
    },
  ];
  const furnaceTypeOptions: {
    label: string;
    value: string | number;
    disabled?: boolean;
    labelData: StepOption;
  }[] = [
    {
      label: `${t(`${i18nForm}.furnaceType.b.option.title`)}`,
      value: FurnaceType.Gas,
      disabled: !userIsProjectOwner,
      labelData: {
        option: {
          text: t(`${i18nForm}.furnaceType.b.option.text`),
          title: t(`${i18nForm}.furnaceType.b.option.title`),
        },
      },
    },
    {
      label: `${t(`${i18nForm}.furnaceType.a.option.title`)}`,
      value: FurnaceType.Propane,
      disabled: !userIsProjectOwner,
      labelData: {
        option: {
          text: t(`${i18nForm}.furnaceType.a.option.text`),
          title: t(`${i18nForm}.furnaceType.a.option.title`),
        },
      },
    },
    {
      label: `${t(`${i18nForm}.furnaceType.c.option.title`)}`,
      value: FurnaceType.Oil,
      disabled: !userIsProjectOwner,
      labelData: {
        option: {
          text: t(`${i18nForm}.furnaceType.c.option.text`),
          title: t(`${i18nForm}.furnaceType.c.option.title`),
        },
      },
    },
  ];
  const furnaceConditionOptions: {
    label: string;
    value: string | number;
    disabled?: boolean;
    labelData: StepOption;
  }[] = [
    {
      label: `${t(`${i18nForm}.furnaceCondition.a.option.title`)}`,
      value: FurnaceCondition.NewFurnace,
      disabled: !userIsProjectOwner,
      labelData: {
        option: {
          text: t(`${i18nForm}.furnaceCondition.a.option.text`),
          title: t(`${i18nForm}.furnaceCondition.a.option.title`),
        },
      },
    },
    {
      label: `${t(`${i18nForm}.furnaceCondition.b.option.title`)}`,
      value: FurnaceCondition.ExistingFurnace,
      disabled: !userIsProjectOwner,
      labelData: {
        option: {
          text: t(`${i18nForm}.furnaceCondition.b.option.text`),
          title: t(`${i18nForm}.furnaceCondition.b.option.title`),
        },
      },
    },
  ];

  const accordions = useMemo(
    () => (
      <PanelGroup
        id={`heat-source-accordian-group`}
        accordion
        className="step-page-accordion"
        activeKey={activeKey}
        onSelect={(key) => {
          setActiveKey(key);
        }}
      >
        {formValues.heatPumpCompareList.map((field, index) => {
          return (
            <Panel key={field.ahri_Number} eventKey={index}>
              <StepPageAccordianItemHeader
                title={
                  `#${index + 1} ${field.name ?? ''} ` +
                  (field.outdoorUnitNumber
                    ? `(${field.outdoorUnitNumber ?? ''})`
                    : '')
                }
                activeKey={activeKey}
                keyNumber={index}
              />
              <Panel.Collapse>
                <Panel.Body>
                  <Row>
                    <Col xs={12}>
                      <RadioGroup
                        id={`heatPumpCompareList.${index}.backupType`}
                        control={control}
                        items={backupOptions}
                        radioClassName="step-option-radio"
                        onChange={submitField}
                        renderLabel={(item) => (
                          <StepOptionRadioItem item={item} />
                        )}
                        inline
                      />
                    </Col>
                  </Row>
                  <Row>
                    {formValues.heatPumpCompareList[index].backupType ===
                    BackupType.Electric ? (
                      <Col xs={6} sm={4}>
                        <Input
                          id={`heatPumpCompareList.${index}.electricSize`}
                          control={control}
                          label={t(`${i18nForm}.electricSize`)}
                          inputProps={{ disabled: !userIsProjectOwner }}
                          validationRules={validationRules}
                          onBlur={submitField}
                          formatter={percentageFormatter}
                          error={
                            errors?.heatPumpCompareList?.[index]?.electricSize
                          }
                        />
                      </Col>
                    ) : (
                      <>
                        <Col xs={6} sm={3}>
                          <Select
                            id={`heatPumpCompareList.${index}.furnaceType`}
                            control={control}
                            label={t(`${i18nForm}.furnaceType.label`)}
                            inputProps={{ disabled: !userIsProjectOwner }}
                            onChange={(val) =>
                              onFurnaceTypeChange(
                                val[
                                  `heatPumpCompareList.${index}.furnaceType`
                                ] as FurnaceType,
                                index
                              )
                            }
                            options={furnaceTypeOptions}
                            formGroupClasses="form-group-full-width-select"
                          />
                        </Col>
                        <Col xs={6} sm={3}>
                          <Select
                            id={`heatPumpCompareList.${index}.furnaceCondition`}
                            control={control}
                            label={t(`${i18nForm}.furnaceCondition.label`)}
                            inputProps={{ disabled: !userIsProjectOwner }}
                            onChange={submitField}
                            options={furnaceConditionOptions}
                            formGroupClasses="form-group-full-width-select"
                          />
                        </Col>
                        <Col xs={6} sm={2}>
                          <Input
                            id={`heatPumpCompareList.${index}.furnaceAFUE`}
                            control={control}
                            label={t(`${i18nForm}.furnaceAFUE`)}
                            inputProps={{ disabled: !userIsProjectOwner }}
                            validationRules={{
                              min: {
                                value: 0,
                                message: t('errors.project.minNumber', {
                                  min: 0,
                                }).toString(),
                              },
                              max: {
                                value: 100,
                                message: t('errors.project.maxNumber', {
                                  max: 100,
                                }).toString(),
                              },
                            }}
                            value={
                              field.furnaceType === FurnaceType.None
                                ? DEFAULT_GAS_AFUE
                                : undefined
                            }
                            onBlur={submitField}
                            formatter={percentageFormatter}
                            className="form-group-full-width-input"
                            error={
                              errors?.heatPumpCompareList?.[index]?.furnaceAFUE
                            }
                          />
                        </Col>
                        <Col xs={6} sm={4}>
                          <Input
                            id={`heatPumpCompareList.${index}.furnaceCapacity`}
                            control={control}
                            label={t(`${i18nForm}.furnaceCapacity`)}
                            inputProps={{ disabled: !userIsProjectOwner }}
                            validationRules={validationRules}
                            onBlur={submitField}
                            formatter={btuhFormatter}
                            className="form-group-full-width-input"
                            error={
                              errors?.heatPumpCompareList?.[index]
                                ?.furnaceCapacity
                            }
                          />
                        </Col>
                      </>
                    )}
                  </Row>
                </Panel.Body>
              </Panel.Collapse>
            </Panel>
          );
        })}
      </PanelGroup>
    ),
    [formValues]
  );

  return (
    <>
      {displayCompact ? (
        <Row className="margin-top-large">
          <Col xs={12} md={12}>
            {userIsProjectOwner ? (
              accordions
            ) : (
              <div className="selected-option-text">
                {formValues.heatPumpCompareList.map((field, index) => {
                  const selectionBackupType = backupOptions.find(
                    (option) => option.value === field.backupType
                  );
                  const selectionFurnaceType = furnaceTypeOptions.find(
                    (option) => option.value === field.furnaceType
                  );
                  const selectionFurnaceCondition =
                    furnaceConditionOptions.find(
                      (option) => option.value === field.furnaceCondition
                    );

                  return (
                    <Row key={field.ahri_Number}>
                      <Col xs={12}>
                        <p className="underline-text">
                          {`#${index + 1} ${field.name ?? ''} ` +
                            (field.outdoorUnitNumber
                              ? `(${field.outdoorUnitNumber ?? ''})`
                              : '')}
                        </p>
                      </Col>
                      <Col xs={6} sm={3}>
                        <label>
                          {selectionBackupType?.labelData.option?.title}
                        </label>
                        <p>{selectionBackupType?.labelData.option?.text}</p>
                      </Col>
                      {field.backupType === BackupType.Electric ? (
                        <Col xs={4}>
                          <label>{t(`${i18nForm}.electricSize`)}</label>
                          <p>
                            {
                              percentageFormatter(field.electricSize ?? 0)
                                .formatted
                            }
                          </p>
                        </Col>
                      ) : (
                        <>
                          <Col xs={6} sm={3}>
                            <label>{t(`${i18nForm}.furnaceType.label`)}</label>
                            <p>
                              {selectionFurnaceType?.labelData.option?.title}
                            </p>
                          </Col>
                          <Col xs={6} sm={4}>
                            <label>
                              {t(`${i18nForm}.furnaceCondition.label`)}
                            </label>
                            <p>
                              {
                                selectionFurnaceCondition?.labelData.option
                                  ?.title
                              }
                            </p>
                          </Col>
                          <Col xs={6} sm={3}>
                            <label>{t(`${i18nForm}.furnaceAFUE`)}</label>
                            <p>
                              {
                                percentageFormatter(field.furnaceAFUE ?? 0)
                                  .formatted
                              }
                            </p>
                          </Col>
                          <Col xs={6} sm={4}>
                            <label>{t(`${i18nForm}.furnaceCapacity`)}</label>
                            <p>
                              {
                                btuhFormatter(field.furnaceCapacity ?? 0)
                                  .formatted
                              }
                            </p>
                          </Col>
                        </>
                      )}
                    </Row>
                  );
                })}
              </div>
            )}
          </Col>
        </Row>
      ) : (
        <div className="margin-top-large">
          <form noValidate>{accordions}</form>
        </div>
      )}
    </>
  );
};

export default BackupHeatSourcePage;
