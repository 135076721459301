import clsx from 'clsx';
import { t } from 'i18next';
import React, { useMemo } from 'react';
import { ControlLabel, FormGroup, HelpBlock } from 'react-bootstrap';
import { FieldError, FieldErrorsImpl, Merge } from 'react-hook-form';

interface Props {
  className?: string;
  error?: FieldError | Merge<FieldError, FieldErrorsImpl<any>>;
  id: string;
  label?: string | React.ReactNode;
  labelHidden?: boolean;
  help?: string;
  placeholder?: string;
  children?: React.ReactNode;
  required?: boolean;
}

const FieldGroup: React.FC<Props> = ({
  children,
  className,
  error,
  id,
  label,
  labelHidden,
  help,
  required,
}) => {
  const validationState = useMemo(() => {
    if (error) {
      return 'error';
    }
  }, [error]);

  return (
    <FormGroup
      className={className}
      controlId={id}
      validationState={validationState}
    >
      {label && (
        <ControlLabel className={clsx({ 'sr-only': labelHidden })}>
          {required && (
            <>
              <span className="text-danger">*</span>&nbsp;
            </>
          )}
          {label}
          {required && (
            <span className="text-danger">
              &nbsp;({t('errors.common.required')})
            </span>
          )}
        </ControlLabel>
      )}
      {children}
      {!error && !!help && <HelpBlock>{help}</HelpBlock>}
      {error && <HelpBlock role="alert">{error.message}</HelpBlock>}
    </FormGroup>
  );
};

export default FieldGroup;
