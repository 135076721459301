import { t } from 'i18next';
import { Dispatch, SetStateAction, useMemo } from 'react';
import { Button, ButtonToolbar, Col, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useQuery } from '@tanstack/react-query';
import { getBrands } from '../../../../api/heatPump';
import { TooltipIcon } from '../../../common';
import { Input, Select, ServerFeedback } from '../../../common/Form';
import { HeatPumpsFilter } from '../../../../model/HeatPumpsFilter';
import { useNumberFormatter } from '../../../../hooks/useNumberFormatter';
import { BTUH_MAX_DECIMALS } from '../../../../const/Formatter';
import {
  getFirstError,
  getMinRule,
  isInteger,
  isMinLessThanMax,
} from '../../../../utils/validationUtils';
import { BTU_MIN } from '../../../../const';

interface Props {
  i18nForm: string;
  setFilter: Dispatch<SetStateAction<HeatPumpsFilter>>;
  setPage: Dispatch<SetStateAction<number>>;
  userIsProjectOwner?: boolean;
}

const HeatPumpFilterForm: React.FC<Props> = ({
  i18nForm,
  setFilter,
  setPage,
  userIsProjectOwner,
}) => {
  const numberFormatter = useNumberFormatter(BTUH_MAX_DECIMALS);

  const {
    control: filterControl,
    getValues: filterGetValues,
    handleSubmit,
    reset,
    formState: { errors: filterErrors },
  } = useForm<HeatPumpsFilter>({
    defaultValues: new HeatPumpsFilter(),
    mode: 'onChange',
  });

  const { data: brands, isError: isBrandsError } = useQuery({
    queryKey: ['brands'],
    queryFn: async () => (await getBrands()).data,
  });

  const brandOptions = useMemo(() => {
    const options = [{ label: t(`${i18nForm}.filter.allBrands`), value: '' }];
    brands?.forEach((brand) => options.push({ value: brand, label: brand }));

    return options;
  }, [brands, i18nForm]);

  const getBtuMinRules = (idToCompare: string) => ({
    ...getMinRule(BTU_MIN),
    validate: getFirstError(isInteger, (value) =>
      isMinLessThanMax(value, filterGetValues(idToCompare))
    ),
  });

  const btuMaxRules = {
    ...getMinRule(BTU_MIN),
    validate: isInteger,
  };

  const onReset = () => {
    reset(new HeatPumpsFilter());
    setFilter(new HeatPumpsFilter());
    setPage(1);
  };

  const onSubmit = (values) => {
    setFilter(new HeatPumpsFilter(values));
    setPage(1);
  };

  return (
    <form noValidate className="filter" onSubmit={handleSubmit(onSubmit)}>
      <Row>
        <Col xs={12} lg={6}>
          <Select
            label={t(`${i18nForm}.filter.brand`)}
            id="brand"
            control={filterControl}
            options={brandOptions}
          />

          {isBrandsError && (
            <ServerFeedback type="error">
              {t('errors.common.unknown')}
            </ServerFeedback>
          )}
        </Col>
        <Col xs={12} lg={6}>
          <Input
            label={t(`${i18nForm}.filter.ahriModelUnit`)}
            id="ahriModelUnit"
            control={filterControl}
            placeholder={t(`${i18nForm}.filter.ahriModelUnit`)}
            className="ahriModelUnit"
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="header-tooltip-wrapper" componentClass="span">
          <h3>{t(`${i18nForm}.filter.capacity`)}</h3>
          <TooltipIcon
            alt={t(`${i18nForm}.filter.capacityTooltipAlt`)}
            glyph="info-sign"
            id="filter-capacity-info-tooltip"
            placement="bottom"
            tooltip={t(`${i18nForm}.filter.capacityTooltipText`)}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} lg={4}>
          <Input
            id="capacity5"
            addonRight={t('units.degreesFahrenheit')}
            control={filterControl}
            label={t(`${i18nForm}.filter.atTemp`)}
            inputProps={{ disabled: true }}
            formatter={numberFormatter}
          />
        </Col>
        <Col xs={12} lg={4}>
          <Input
            id="capacity5Min"
            addonRight={t('units.btuPerHour')}
            control={filterControl}
            label={t(`${i18nForm}.filter.tempMin`)}
            inputProps={{ disabled: !userIsProjectOwner }}
            validationRules={getBtuMinRules('capacity5Max')}
            error={filterErrors.capacity5Min}
            formatter={numberFormatter}
          />
        </Col>
        <Col xs={12} lg={4}>
          <Input
            id="capacity5Max"
            addonRight={t('units.btuPerHour')}
            control={filterControl}
            label={t(`${i18nForm}.filter.tempMax`)}
            inputProps={{ disabled: !userIsProjectOwner }}
            validationRules={btuMaxRules}
            error={filterErrors.capacity5Max}
            formatter={numberFormatter}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} lg={4}>
          <Input
            id="capacity47"
            addonRight={t('units.degreesFahrenheit')}
            control={filterControl}
            label={t(`${i18nForm}.filter.atTemp`)}
            inputProps={{ disabled: true }}
            formatter={numberFormatter}
          />
        </Col>
        <Col xs={12} lg={4}>
          <Input
            id="capacity47Min"
            addonRight={t('units.btuPerHour')}
            control={filterControl}
            label={t(`${i18nForm}.filter.tempMin`)}
            inputProps={{ disabled: !userIsProjectOwner }}
            validationRules={getBtuMinRules('capacity47Max')}
            error={filterErrors.capacity47Min}
            formatter={numberFormatter}
          />
        </Col>
        <Col xs={12} lg={4}>
          <Input
            id="capacity47Max"
            addonRight={t('units.btuPerHour')}
            control={filterControl}
            label={t(`${i18nForm}.filter.tempMax`)}
            inputProps={{ disabled: !userIsProjectOwner }}
            validationRules={btuMaxRules}
            error={filterErrors.capacity47Max}
            formatter={numberFormatter}
          />
        </Col>
      </Row>
      <Row className="margin-top-large">
        <Col xs={12}>
          <ButtonToolbar className="filter-btn-group">
            <Button
              onClick={onReset}
              className="btn btn-primary btn-contrast btn-sm"
            >
              {t(`${i18nForm}.filter.resetFilter`)}
            </Button>
            <Button className="btn btn-contrast btn-sm" type="submit">
              {t(`${i18nForm}.filter.applyFilter`)}
            </Button>
          </ButtonToolbar>
        </Col>
      </Row>
    </form>
  );
};

export default HeatPumpFilterForm;
