import i18n from '../i18n';

export const getFirstError = (
  ...validators: ((validator: Function) => string | undefined)[]
) => {
  return (value: any) => {
    for (const validator of validators) {
      const error = validator(value);
      if (error) {
        return error;
      }
    }
  };
};

export const getMaxLengthRule = (maxLength: number) => ({
  maxLength: {
    value: maxLength,
    message: i18n.t('errors.common.maxLength', { maxLength }),
  },
});

export const getMinRule = (min: number) => ({
  min: {
    value: min,
    message: i18n.t('errors.project.minNumber', { min }),
  },
});

export const getRequiredRule = () => ({
  required: i18n.t('errors.common.required'),
});

export const isEmail = (email: any) => {
  const regex = new RegExp(/^[^\s@]+@[^\s@]+$/);
  if (!email.toString().match(regex)) {
    return i18n.t('errors.common.invalidEmail');
  }
};

export const isNotEmptyString = (value: any) => {
  if (typeof value === 'string' && value.trim().length === 0) {
    return i18n.t('errors.common.required');
  }
};

export const isInteger = (value: any) => {
  const num = Number(value);
  if (!Number.isInteger(num)) {
    return i18n.t('errors.common.notInteger');
  }
};

export const isMinLessThanMax = (min: any, max: any) => {
  const minNum = Number(min);
  const maxNum = Number(max);
  if (minNum > maxNum) {
    return i18n.t(`errors.common.minGreaterThanMax`);
  }
};

export const isNumber = (value: any) => {
  const num = Number(value);
  if (Number.isNaN(num)) {
    return i18n.t('errors.common.notNumber');
  }
};
