import { faFire, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useQuery } from '@tanstack/react-query';
import clsx from 'clsx';
import { JSX, useMemo, useState } from 'react';
import { Button, Col, Row, Tabs, Tab } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import { getHeatPumps } from '../../../../api/heatPump';
import { getProjectPropositions } from '../../../../const/Projects';
import { env } from '../../../../env';
import { HeatPump } from '../../../../model/HeatPump';
import { HeatPumpsFilter } from '../../../../model/HeatPumpsFilter';
import { Project, ProjectPropositions } from '../../../../model/Project';
import { Step, Step12Data, Steps } from '../../../../model/Step';
import { Spinner, TooltipWrapper } from '../../../common';
import { DatatablePagination } from '../../../common/Datatable';
import { ServerFeedback } from '../../../common/Form';
import ProjectStepProposition from '../../ProjectStepProposition';
import HeatPumpSelectionCompareList from './HeatPumpSelectionCompareList';
import HeatPumpSelectionIntro from './HeatPumpSelectionIntro';
import CustomHeatPumpForm from './CustomHeatPumpForm';
import HeatPumpFilterForm from './HeatPumpFilterForm';

interface Props {
  project: Project;
  stepData?: Step12Data;
  updateProject?: (value) => Promise<void>;
  displayCompact?: boolean;
  userIsProjectOwner?: boolean;
  additionalInfo?: JSX.Element;
  children?: JSX.Element;
  stepsList?: Step[];
}

const HeatPumpSelectionPage: React.FC<Props> = ({
  project,
  stepData,
  updateProject,
  displayCompact,
  userIsProjectOwner,
  additionalInfo,
  children,
  stepsList,
}) => {
  const { t } = useTranslation();
  const stepId = Steps.STEP_12;
  const i18nForm = `step-${stepId}:form`;
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState(new HeatPumpsFilter());
  const [editingIndex, setEditingIndex] = useState<number | null>(null);

  const propositions: ProjectPropositions = useMemo(() => {
    return getProjectPropositions(project);
  }, [project]);

  const compareList = useMemo(() => {
    return stepData?.heatPumpCompareList ?? [];
  }, [stepData]);

  const {
    data: heatPumps,
    isFetching,
    isError,
    isFetched,
  } = useQuery({
    queryKey: ['heatPumps', page, filter],
    queryFn: async () => (await getHeatPumps(filter, page)).data,
    refetchOnMount: false,
    refetchInterval: false,
    refetchOnWindowFocus: false,
  });

  const updateCompareList = async (values: HeatPump[]) => {
    return (
      updateProject &&
      (await updateProject({
        [`steps.${[stepId]}/heatPumpCompareList`]: values,
      }))
    );
  };

  const addHeatPump = async (heatPump: HeatPump) => {
    const updatedList = [...compareList, heatPump];
    await updateCompareList(updatedList);
  };

  const removeHeatPump = async (index: number) => {
    const updatedList = [...compareList];
    updatedList.splice(index, 1);
    await updateCompareList(updatedList);
  };

  const editHeatPump = async (index: number) => {
    setEditingIndex(index);
  };

  const submitHeatPumpEdit = async (heatPump: HeatPump) => {
    const updatedList = [...compareList];
    updatedList.splice(editingIndex as number, 1, heatPump);
    await updateCompareList(updatedList);
    setEditingIndex(null);
  };

  const compareListFull =
    !!env.REACT_APP_API_MAX_HP_COMPARE_COUNT &&
    Number(env.REACT_APP_API_MAX_HP_COMPARE_COUNT) <=
      (stepData?.heatPumpCompareList.length ?? 0);

  const noResult = !heatPumps?.data?.length;

  const dehumidificationStrategyStepNum = useMemo(
    () => stepsList?.find((step) => step.id === Steps.STEP_14)?.number,
    [stepsList]
  );

  return (
    <div className={clsx({ 'heat-pump-selection-container': !displayCompact })}>
      {!displayCompact && <HeatPumpSelectionIntro project={project} />}
      {Number.isInteger(editingIndex) ? (
        <div className="form-wrapper">
          <h3>
            #{(editingIndex as number) + 1} - {t('common.edit')}
          </h3>
          <CustomHeatPumpForm
            data={
              Number.isInteger(editingIndex)
                ? compareList[editingIndex as number]
                : undefined
            }
            i18nForm={i18nForm}
            onCancel={() => setEditingIndex(null)}
            onSubmit={submitHeatPumpEdit}
            userIsProjectOwner={userIsProjectOwner}
          />
        </div>
      ) : (
        <HeatPumpSelectionCompareList
          compareList={compareList}
          edit={editHeatPump}
          remove={removeHeatPump}
          userIsProjectOwner={userIsProjectOwner}
          isFull={compareListFull}
          isLoading={isFetching}
        />
      )}

      {displayCompact && children}
      {displayCompact && additionalInfo}

      {!displayCompact && (
        <>
          <Row>
            <Col xs={12}>
              <h2>{t(`${i18nForm}.filter.title`)}</h2>
              <Tabs defaultActiveKey={1} animation={false}>
                <Tab
                  eventKey={1}
                  title={t(`${i18nForm}.filter.tabs.existingHeatPump`)}
                >
                  <HeatPumpFilterForm
                    i18nForm={i18nForm}
                    setFilter={setFilter}
                    setPage={setPage}
                    userIsProjectOwner={userIsProjectOwner}
                  />
                </Tab>
                <Tab
                  disabled={compareListFull}
                  eventKey={2}
                  title={t(`${i18nForm}.filter.tabs.customHeatPump`)}
                >
                  <CustomHeatPumpForm
                    addDisabled={compareListFull}
                    i18nForm={i18nForm}
                    onSubmit={addHeatPump}
                    userIsProjectOwner={userIsProjectOwner}
                  />
                </Tab>
              </Tabs>
            </Col>
          </Row>

          <form noValidate className="filter">
            <Row className="margin-top-larger">
              <Col xs={12}>
                <h2>{t(`${i18nForm}.heatPump.title`)}</h2>
                <hr />
                <div className="heat-pumps-container">
                  {isFetching ? (
                    <Spinner />
                  ) : isError ? (
                    <ServerFeedback type="error">
                      {t('errors.common.unknown')}
                    </ServerFeedback>
                  ) : !noResult ? (
                    <div className="heat-pump-container">
                      {heatPumps?.data.map((heatPump, index) => (
                        <div className="heat-pump" key={heatPump.ahri_Number}>
                          <div>
                            <h3 className="heat-pump-name">{heatPump.name}</h3>
                            <div className="model-number">
                              <p>
                                {t(`${i18nForm}.heatPump.ahriModelUnit`)}
                                <strong>{heatPump.ahri_Number}</strong>
                              </p>
                              <p>
                                {t(`${i18nForm}.heatPump.outdoorUnitModel`)}
                                <strong>{heatPump.outdoorUnitNumber}</strong>
                              </p>
                              <p>
                                {t(`${i18nForm}.heatPump.indoorUnitModel`)}
                                <strong>{heatPump.indoorUnitNumber}</strong>
                              </p>
                            </div>
                            <div className="rated-btuh">
                              <p>
                                <FontAwesomeIcon
                                  icon={faFire}
                                  className="fire-icon"
                                />
                                <Trans>
                                  {t(
                                    `${i18nForm}.heatPump.maxHeatingCapacityAt47F`,
                                    {
                                      number: heatPump.maxHeatingCapacityAt47F,
                                    }
                                  )}
                                </Trans>
                              </p>
                              <p>
                                <FontAwesomeIcon
                                  icon={faFire}
                                  className="fire-icon"
                                />
                                <Trans>
                                  {t(
                                    `${i18nForm}.heatPump.maxHeatingCapacityAt17F`,
                                    {
                                      number: heatPump.maxHeatingCapacityAt17F,
                                    }
                                  )}
                                </Trans>
                              </p>
                              {heatPump.maxHeatingCapacityAt5F && (
                                <p>
                                  <FontAwesomeIcon
                                    icon={faFire}
                                    className="fire-icon"
                                  />
                                  <Trans>
                                    {t(
                                      `${i18nForm}.heatPump.maxHeatingCapacityAt5F`,
                                      {
                                        number: heatPump.maxHeatingCapacityAt5F,
                                      }
                                    )}
                                  </Trans>
                                </p>
                              )}
                            </div>
                            {heatPump.copAt5F && (
                              <p>
                                {t(`${i18nForm}.heatPump.cop5`)}{' '}
                                <strong>{heatPump.copAt5F}</strong>
                              </p>
                            )}
                            {heatPump.copAt17F && (
                              <p>
                                {t(`${i18nForm}.heatPump.cop17`)}{' '}
                                <strong>{heatPump.copAt17F}</strong>
                              </p>
                            )}
                            {heatPump.copAt47F && (
                              <p>
                                {t(`${i18nForm}.heatPump.cop47`)}{' '}
                                <strong>{heatPump.copAt47F}</strong>
                              </p>
                            )}
                            <p>
                              {t(`${i18nForm}.heatPump.hspf`)}
                              <strong>
                                {heatPump.hspf ?? t(`common.nonApplicable`)}
                              </strong>
                            </p>
                            <p>
                              {t(`${i18nForm}.heatPump.hspf2`)}
                              <strong>
                                {heatPump.hspf2 ?? t(`common.nonApplicable`)}
                              </strong>
                            </p>
                            <div className="heat-pump-button">
                              <TooltipWrapper
                                disabled={!compareListFull}
                                id={`add-max-tooltip-${index}`}
                                tooltip={t(`${i18nForm}.heatPump.maxSelected`)}
                              >
                                <Button
                                  disabled={
                                    !!stepData?.heatPumpCompareList.find(
                                      (hp) =>
                                        hp.ahri_Number === heatPump.ahri_Number
                                    ) || compareListFull
                                  }
                                  onClick={() => {
                                    addHeatPump(heatPump);
                                  }}
                                  className="btn btn-contrast btn-sm btn-icon-left"
                                >
                                  <FontAwesomeIcon icon={faPlus} />
                                  {t(`${i18nForm}.heatPump.compare`)}
                                </Button>
                              </TooltipWrapper>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <p>{t(`${i18nForm}.heatPump.noResult`)}</p>
                  )}
                </div>
                {isFetched && !noResult && (
                  <DatatablePagination
                    currentPage={page}
                    onChangePage={(page: number) => {
                      setPage(page);
                    }}
                    rowCount={heatPumps?.totalCount ?? 0}
                    rowsPerPage={60}
                    useArrow
                  />
                )}
              </Col>
            </Row>
          </form>

          <ProjectStepProposition
            id={stepId}
            propositions={propositions.secondaries[stepId]}
            translationVariables={{ dehumidificationStrategyStepNum }}
            withListStyle
          />
        </>
      )}
    </div>
  );
};

export default HeatPumpSelectionPage;
