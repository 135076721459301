/* eslint-disable react-hooks/exhaustive-deps */
import { FieldArrayWithId } from 'react-hook-form';
import { DropdownButton, MenuItem } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import DataTable, {
  Media,
  TableColumn,
  TableStyles,
} from 'react-data-table-component';
import { useMediaQuery } from 'react-responsive';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { Step12Data, Steps } from '../../../../model/Step';
import { SM_MAX_SIZE } from '../../../../const/Responsive';
import { DataTableCell } from '../../../Dashboard';
import { Tag } from '../../../common';
import { ServerFeedback } from '../../../common/Form';

const customStyles: TableStyles = {
  headRow: {
    style: {
      borderBottomStyle: 'solid',
      borderBottomWidth: '1px',
      borderBottomColor: '#000',
    },
  },
  headCells: {
    style: {
      display: 'flex',
      color: '#000',
      fontFamily: 'Noto Sans, sans-serif',
      fontSize: 20,
      fontWeight: 600,
      justifyContent: 'space-between',
      span: {
        fontSize: 12,
      },

      '& div': {
        whiteSpace: 'pre-wrap',
        textAlign: 'left',
        fontWeight: 600,
        minHeight: 24,
      },
    },
  },
  rows: {
    style: {
      backgroundColor: '#FBFBFB',
      borderBottom: 'none',
    },
    stripedStyle: {
      backgroundColor: 'transparent',
    },
  },
  responsiveWrapper: {
    style: { overflow: 'visible' },
  },
  cells: {
    style: {
      borderBottom: '#26374A solid 1px',
      fontSize: 20,

      '& a': {
        color: '#000',
      },
    },
  },
};

const mobileStyle = {
  table: {
    style: {
      marginTop: 20,
    },
  },
  headRow: {
    style: {
      display: 'none',
    },
  },
  rows: {
    style: {
      backgroundColor: '#FBFBFB',
      display: 'block',
      '&:first-of-type': {
        borderTop: '#26374A solid 1px',
      },
      '&:not(:last-of-type)': {
        borderBottom: '#26374A solid 1px',
      },

      borderBottom: '#26374A solid 1px',
    },
    stripedStyle: {
      backgroundColor: 'transparent',
    },
  },
  cells: {
    style: {
      border: 'none',
      display: 'inline-block',
      fontSize: 18,
      lineHeight: '26px',
      marginTop: 6,

      a: {
        color: '#333333',
      },

      '.table-cell-column-label': {
        fontSize: 16,
        fontWeight: 600,
      },
    },
  },
};

interface Props {
  compareList: FieldArrayWithId<Step12Data, 'heatPumpCompareList', 'id'>[];
  edit: (index: number) => void;
  remove: (index: number) => void;
  userIsProjectOwner?: boolean;
  isFull?: boolean;
  isLoading: boolean;
}

const HeatPumpSelectionCompareList: React.FC<Props> = ({
  compareList,
  edit,
  remove,
  userIsProjectOwner,
  isFull,
  isLoading,
}) => {
  const { t } = useTranslation();
  const stepId = Steps.STEP_12;
  const i18nForm = `step-${stepId}:form`;
  const isSM = useMediaQuery({ query: `(max-width: ${SM_MAX_SIZE}px)` });

  const columns: TableColumn<
    FieldArrayWithId<Step12Data, 'heatPumpCompareList', 'id'>
  >[] = [
    {
      id: 'index',
      name: '#',
      selector: (_, index = 0) => index + 1,
      width: isSM ? '100%' : '6%',
      cell: (_, index) => {
        return (
          <DataTableCell columnLabel={!isSM ? '#' : ''}>
            {isSM ? '#' : ''}
            {index + 1}
          </DataTableCell>
        );
      },
    },
    {
      id: 'brand',
      name: t(`${i18nForm}.compareList.brand`),
      selector: (row) => row.name ?? '',
      width: isSM ? '100%' : '22%',
      cell: (row) => {
        return (
          <DataTableCell columnLabel={t(`${i18nForm}.compareList.brand`)}>
            <div className="compare-list-brand-container">
              {`${row.name ?? ''}`}
              {row.outdoorUnitNumber ? ` (${row.outdoorUnitNumber})` : ''}
              {row.isCustom && (
                <>
                  <br />
                  <Tag>{t(`${i18nForm}.compareList.custom`)}</Tag>
                </>
              )}
            </div>
          </DataTableCell>
        );
      },
    },
    {
      id: 'ahri',
      name: t(`${i18nForm}.compareList.ahri`),
      selector: (row) => row.ahri_Number ?? '',
      width: isSM ? '100%' : '16%',
      cell: (row) => (
        <DataTableCell columnLabel={t(`${i18nForm}.compareList.ahri`)}>
          {row.ahri_Number}
        </DataTableCell>
      ),
    },
    {
      id: 'heatingRatedBtuh',
      name: t(`${i18nForm}.compareList.heatingRatedBtuh`),
      selector: (row) => row.maxHeatingCapacityAt5F ?? '',
      width: isSM ? '100%' : '18%',
      cell: (row) => (
        <DataTableCell
          columnLabel={t(`${i18nForm}.compareList.heatingRatedBtuh`)}
        >
          <div className="compare-list-rated-btuh-container">
            <span>
              {isSM
                ? t(`${i18nForm}.compareList.maxHeatingCapacityAt47F`, {
                    number: row.maxHeatingCapacityAt47F,
                  })
                : row.maxHeatingCapacityAt47F}
            </span>
            <span>
              {isSM
                ? t(`${i18nForm}.compareList.maxHeatingCapacityAt17F`, {
                    number: row.maxHeatingCapacityAt17F,
                  })
                : row.maxHeatingCapacityAt17F}
            </span>
            <span>
              {isSM
                ? t(`${i18nForm}.compareList.maxHeatingCapacityAt5F`, {
                    number: row.maxHeatingCapacityAt5F,
                  })
                : row.maxHeatingCapacityAt5F}
            </span>
          </div>
        </DataTableCell>
      ),
    },
    {
      id: 'cop',
      name: t(`${i18nForm}.compareList.cop`),
      selector: (row) => row.copAt5F ?? '',
      width: isSM ? '100%' : '10%',
      cell: (row) => (
        <DataTableCell columnLabel={t(`${i18nForm}.compareList.cop`)}>
          <div className="compare-list-cop-container">
            {row.copAt47F ? (
              <span>
                {isSM
                  ? t(`${i18nForm}.compareList.copAt47F`, {
                      number: row.copAt47F,
                    })
                  : row.copAt47F}
              </span>
            ) : null}
            {row.copAt17F ? (
              <span>
                {isSM
                  ? t(`${i18nForm}.compareList.copAt17F`, {
                      number: row.copAt17F,
                    })
                  : row.copAt17F}
              </span>
            ) : null}
            {row.copAt5F ? (
              <span>
                {isSM
                  ? t(`${i18nForm}.compareList.copAt5F`, {
                      number: row.copAt5F,
                    })
                  : row.copAt5F}
              </span>
            ) : null}
          </div>
        </DataTableCell>
      ),
    },
    {
      id: 'atTemperature',
      name: t(`${i18nForm}.compareList.atTemperature`),
      selector: (row) => row.copAt5F ?? '',
      width: '14%',
      hide: Media.SM,
      cell: (_) => (
        <DataTableCell columnLabel={t(`${i18nForm}.compareList.atTemperature`)}>
          <div className="compare-list-at-temperature-container">
            <span>{t(`${i18nForm}.compareList.at47F`)}</span>
            <span>{t(`${i18nForm}.compareList.at17F`)}</span>
            <span>{t(`${i18nForm}.compareList.at5F`)}</span>
          </div>
        </DataTableCell>
      ),
    },
    {
      id: 'actions',
      name: t('common.actions'),
      right: false,
      button: !isSM,
      width: isSM ? '100%' : '14%',
      omit: !userIsProjectOwner,
      cell: (_, index) => (
        <DropdownButton
          className="list-actions"
          disabled={isLoading}
          id={`list-actions-${index}`}
          noCaret
          pullRight
          title={<FontAwesomeIcon icon={faEllipsisV} />}
        >
          <MenuItem onClick={() => edit(index)}>{t('common.edit')}</MenuItem>
          <MenuItem onClick={() => remove(index)}>
            {t('common.delete')}
          </MenuItem>
        </DropdownButton>
      ),
    },
  ];

  return (
    <>
      <form noValidate>
        {!userIsProjectOwner ? (
          <div className="compare-list">
            {!compareList.length ? (
              <>
                <p>{t(`${i18nForm}.compareList.title`)}</p>
                <hr />
                <p> {t(`${i18nForm}.compareList.noResult`)}</p>
              </>
            ) : (
              <DataTable
                keyField="ahri_Number"
                customStyles={
                  isSM ? { ...customStyles, ...mobileStyle } : customStyles
                }
                columns={columns}
                data={compareList ?? []}
                persistTableHead
                striped
                noDataComponent={t(`${i18nForm}.compareList.noResult`)}
              />
            )}
          </div>
        ) : (
          <div className="compare-list">
            {!compareList.length ? (
              <>
                <p>{t(`${i18nForm}.compareList.title`)}</p>
                <hr />
                <p> {t(`${i18nForm}.compareList.noResult`)}</p>
              </>
            ) : (
              <DataTable
                keyField="ahri_Number"
                customStyles={
                  isSM ? { ...customStyles, ...mobileStyle } : customStyles
                }
                columns={columns}
                data={compareList ?? []}
                persistTableHead
                striped
                noDataComponent={t(`${i18nForm}.compareList.noResult`)}
              />
            )}
            {isFull && (
              <ServerFeedback type="error">
                {t(`${i18nForm}.heatPump.maxSelected`)}
              </ServerFeedback>
            )}
          </div>
        )}
      </form>
    </>
  );
};

export default HeatPumpSelectionCompareList;
