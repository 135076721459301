// @ts-nocheck
import React from 'react';

export default class VideoInitialize extends React.Component {
  //This is to initialize the Web Experience Toolkit (WET) video player
  componentDidMount() {
    window.wb.add('.wb-mltmd');
  }

  render() {
    return (
      <div>
        <figure className="wb-mltmd">{this.props.children}</figure>
      </div>
    );
  }
}
