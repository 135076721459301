import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ServerFeedback } from '../../common/Form';

const CopyLinkButton: React.FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const [linkCopied, setLinkCopied] = useState<boolean>(false);
  const copyLink = () => {
    navigator.clipboard.writeText(window.location.href);
    setLinkCopied(true);
  };

  useEffect(() => {
    setLinkCopied(false);
  }, [location]);

  return (
    <div className="copy-link-button">
      <Button
        bsSize="sm"
        onClick={copyLink}
        className="btn-primary btn-contrast"
      >
        <FontAwesomeIcon icon={faLink} />
        {t('stepPage.copyLink')}
      </Button>

      {linkCopied && (
        <ServerFeedback type="success">
          {t('stepPage.copyLinkSuccess')}
        </ServerFeedback>
      )}
    </div>
  );
};

export default CopyLinkButton;
