import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from '@azure/msal-react';
import { PageHeader } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { AppRoute } from '../../const';
import { useOpenSignUpSignRedirect } from '../../hooks/useOpenSignUpSignRedirect';
import { AppHeader } from '../common';
import { LocalizedNavLink } from '../i18n';

const PageNotFound: React.FC = () => {
  const { t } = useTranslation();
  const openSignUpSignRedirect = useOpenSignUpSignRedirect();

  return (
    <div className="page-not-found">
      <Helmet>
        <title>{t('pageTitle.pageNotFound')}</title>
      </Helmet>
      <AppHeader />

      <PageHeader>{t('pageNotFound.title')}</PageHeader>
      <UnauthenticatedTemplate>
        <>
          <p>{t('pageNotFound.guest.text')}</p>

          <div className="button-row">
            <LocalizedNavLink
              className="btn btn-primary"
              to={''}
              onClick={(event) => {
                event?.preventDefault();
                openSignUpSignRedirect('signUp');
              }}
            >
              {t('pageNotFound.guest.goToSignup')}
            </LocalizedNavLink>

            <LocalizedNavLink
              isActive={() => false}
              className="btn btn-default"
              to={AppRoute.Home}
            >
              {t('pageNotFound.guest.goToHome')}
            </LocalizedNavLink>
          </div>
        </>
      </UnauthenticatedTemplate>
      <AuthenticatedTemplate>
        <>
          <p>{t('pageNotFound.auth.text')}</p>

          <LocalizedNavLink className="btn btn-primary" to={AppRoute.Dashboard}>
            {t('pageNotFound.auth.goToDashboard')}
          </LocalizedNavLink>
        </>
      </AuthenticatedTemplate>
    </div>
  );
};

export default PageNotFound;
