import { useMutation, useQueryClient } from '@tanstack/react-query';
import { partialUpdateProject } from '../api/projects';
import { JsonPatchOperation } from '../model/JsonPatchOperation';
import { Project } from '../model/Project';

export const usePartialProjectUpdateMutation = (project) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (changes: JsonPatchOperation[]) => {
      return (await partialUpdateProject(project.id, changes)).data;
    },
    onError: (err) => {
      console.log('err:', err);
    },
    onSuccess: (data: Project) => {
      queryClient.setQueryData(['project', { id: data.id }], data);
    },
  });
};
