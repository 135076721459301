/* eslint-disable react-hooks/exhaustive-deps */
import { JSX, useMemo, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { Row, Col, PanelGroup, Panel } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Step14Data, StepOption, Steps } from '../../../model/Step';
import { RadioGroup, Select } from '../../common/Form';
import { StepOptionRadioItem, StepPageAccordianItemHeader } from '../StepPage';
import { Project, ProjectPropositions } from '../../../model/Project';
import { getProjectPropositions } from '../../../const/Projects';
import { getWorkflow } from '../../../utils/workflowUtils';
import ProjectStepProposition from '../ProjectStepProposition';

interface Props {
  project: Project;
  stepData?: Step14Data;
  updateProject?: (value) => void;
  displayCompact?: boolean;
  userIsProjectOwner?: boolean;
  additionalInfo?: JSX.Element;
}

const DehumidificationStrategyPage: React.FC<Props> = ({
  project,
  stepData,
  updateProject,
  displayCompact,
  userIsProjectOwner,
  additionalInfo,
}) => {
  const { t } = useTranslation();
  const stepId = Steps.STEP_14;
  const fieldValue = `${stepId}-value`;
  const stepList = useMemo(() => getWorkflow(project), [project]);
  const [activeKey, setActiveKey] = useState(0);
  const stepNumber = useMemo(
    () => stepList.find((step) => step.id === stepId)?.number,
    [stepList]
  );
  const heatingCoolingLoadEstimationStepNumber = useMemo(
    () => stepList.find((step) => step.id === Steps.STEP_10)?.number,
    [stepList]
  );

  const propositions: ProjectPropositions = useMemo(() => {
    return getProjectPropositions(project);
  }, [project]);

  const { control, getValues } = useForm<Step14Data>({
    defaultValues: {
      dehumidificationStrategies: stepData?.dehumidificationStrategies || [],
    },
    mode: 'onChange',
  });

  const { fields } = useFieldArray({
    control,
    name: 'dehumidificationStrategies',
  });

  const submitField = () => {
    updateProject &&
      updateProject({
        [`steps.${[stepId]}/dehumidificationStrategies`]:
          getValues().dehumidificationStrategies,
      });
  };

  const i18nForm = `step-${stepId}:form`;

  const options: {
    label: string;
    value: string | number;
    disabled?: boolean;
    labelData: StepOption;
  }[] = [
    {
      label: `${t(`${i18nForm}.a.option.title`, { stepNumber })} - ${t(
        `${i18nForm}.a.option.text`
      )}`,
      value: 'A',
      disabled: !userIsProjectOwner,
      labelData: {
        img: {
          alt: t('figures:14A.alt'),
          fileName: t('figures:14A.fileName'),
          longDescription: t('figures:14A.longDesc'),
        },
        modal: {
          content: (
            <>
              <p>{t(`${i18nForm}.a.modal.text1`, { stepNumber })}</p>
              <p>{t(`${i18nForm}.a.modal.text2`)}</p>
            </>
          ),
          title: t(`${i18nForm}.a.modal.title`),
        },
        option: {
          text: t(`${i18nForm}.a.option.text`),
          title: t(`${i18nForm}.a.option.title`, { stepNumber }),
        },
      },
    },
    {
      label: `${t(`${i18nForm}.b.option.title`, { stepNumber })} - ${t(
        `${i18nForm}.b.option.text`
      )}`,
      value: 'B',
      disabled: !userIsProjectOwner,
      labelData: {
        img: {
          alt: t('figures:14B.alt'),
          fileName: t('figures:14B.fileName'),
          longDescription: t('figures:14B.longDesc'),
        },
        modal: {
          content: (
            <p>
              {t(`${i18nForm}.b.modal.text1`, {
                stepNumber: heatingCoolingLoadEstimationStepNumber,
              })}
            </p>
          ),
          title: t(`${i18nForm}.b.modal.title`),
        },
        option: {
          text: t(`${i18nForm}.b.option.text`),
          title: t(`${i18nForm}.b.option.title`, { stepNumber }),
        },
      },
    },
  ];

  return (
    <>
      <form noValidate>
        {displayCompact ? (
          <Row>
            <Col xs={8} md={8}>
              {userIsProjectOwner ? (
                fields.map((field, index) => {
                  return (
                    <div key={field.id}>
                      <Select
                        label={
                          `#${index + 1} ${field.name ?? ''} ` +
                          (field.outdoorUnitNumber
                            ? `(${field.outdoorUnitNumber ?? ''})`
                            : '')
                        }
                        id={`dehumidificationStrategies.${index}.${fieldValue}`}
                        control={control}
                        options={options}
                        formGroupClasses={'step-option-radio'}
                        onChange={() => submitField()}
                        inputProps={{ disabled: !userIsProjectOwner }}
                      />
                    </div>
                  );
                })
              ) : (
                <div className="selected-option-text">
                  {fields?.map((field, index) => {
                    const item = options.find(
                      (o) => o.value === field[fieldValue]
                    );
                    return (
                      <div key={field.id}>
                        <label>
                          {`#${index + 1} ${field.name ?? ''} ` +
                            (field.outdoorUnitNumber
                              ? `(${field.outdoorUnitNumber ?? ''})`
                              : '')}
                        </label>
                        <p>{`${item?.labelData.option?.title} - ${item?.labelData.option?.text}`}</p>
                      </div>
                    );
                  })}
                </div>
              )}
              {additionalInfo}
            </Col>
          </Row>
        ) : (
          <>
            <p>{t('step-dehumidificationStrategy:description')}</p>
            <PanelGroup
              id={`dehumidificationStrategy-accordian-group`}
              accordion
              className="step-page-accordion"
              activeKey={activeKey}
              onSelect={(key) => setActiveKey(key)}
            >
              {fields.map((field, index) => {
                return (
                  <Panel key={index} eventKey={index}>
                    <StepPageAccordianItemHeader
                      title={
                        `#${index + 1} ${field.name ?? ''} ` +
                        (field.outdoorUnitNumber
                          ? `(${field.outdoorUnitNumber ?? ''})`
                          : '')
                      }
                      activeKey={activeKey}
                      keyNumber={index}
                    />
                    <Panel.Collapse>
                      <Panel.Body>
                        <div id={field.id}>
                          <RadioGroup
                            id={`dehumidificationStrategies.${index}.${fieldValue}`}
                            control={control}
                            items={options}
                            radioClassName="step-option-radio"
                            onChange={submitField}
                            renderLabel={(item) => (
                              <StepOptionRadioItem
                                item={item}
                                extraId={index}
                              />
                            )}
                          />
                        </div>
                      </Panel.Body>
                    </Panel.Collapse>
                  </Panel>
                );
              })}
            </PanelGroup>
            <ProjectStepProposition
              id={stepId}
              propositions={propositions.options[stepId]}
              translationVariables={{ stepNumber }}
            />
          </>
        )}
      </form>
    </>
  );
};

export default DehumidificationStrategyPage;
