import axios from 'axios';
import { env } from './env';
import { protectedResources } from './authConfig';
import { msalInstance } from './index';

axios.defaults.baseURL = `${env.REACT_APP_API_URL}/api/heatpump`;
axios.interceptors.request.use(
  async (config) => {
    const accounts = msalInstance.getAllAccounts();
    let token = '';
    if (accounts && accounts.length > 0) {
      try {
        token = (
          await msalInstance.acquireTokenSilent({
            scopes: protectedResources.projectsApi.scopes,
            account: accounts[0] || {},
          })
        ).accessToken;
      } catch (e) {
        console.log(e);
      }
    }
    if (token) {
      config.headers.set('Authorization', `Bearer ${token}`);
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      msalInstance.logoutRedirect({
        postLogoutRedirectUri: window.location.pathname.substring(0, 4),
      });
    }
    if (error.response.status === 400) {
      throw error;
    }
    return error;
  }
);

export default axios;
