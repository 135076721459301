import { JSX, ReactNode } from 'react';
import { Address } from './Address';
import { DehumidificationStrategy } from './DehumidificationStrategy';
import { BackupControlStrategy } from './BackupControlStrategy';
import { AirflowTesting } from './DuckConstraints/AirflowTesting';
import { CrossSectionalAreaMeasurement } from './DuckConstraints/CrossSectionalAreaMeasurement';
import { HeatPumpWithBackup } from './HeatPumpWithBackup/HeatPumpWithBackup';
import { HouseType } from './House';
import {
  InstallationType,
  ProjectSituationSelections,
  ProjectType,
} from './Project';
import { Video } from './Videos';
import { GHGCostEnergyUse } from './GHGCostEnergyUse';
import { ElectricityCost } from './UtilityCosts/ElectricityCost';
import { FurnaceCost } from './UtilityCosts/FurnaceCost';
import { BaseCase } from './BaseCase';

export enum Steps {
  STEP_1 = 'projectDetails',
  STEP_2 = 'ashpUnitConfiguration',
  STEP_2_1 = 'ductlessIndoorOutdoorUnitsConfiguration',
  STEP_3 = 'sizingCapacityRequirements',
  STEP_4 = 'supplyOutletApproach',
  HPNewHome_Step5Data = 'ductSizing',
  STEP_5 = 'existingConstraints',
  STEP_5_1 = 'airflowCapacityMeasurement',
  STEP_5_1_1 = 'airflowTesting',
  STEP_5_1_2 = 'crossSectionalAreaMeasurement',
  STEP_6 = 'zoningSupplyDucts',
  STEP_7 = 'zoningEquipment',
  STEP_8 = 'returnAirDuctDesign',
  STEP_9 = 'ductSealing',
  STEP_10 = 'heatingCoolingLoadEstimates',
  STEP_11 = 'ductShape',
  STEP_12 = 'heatPumpSelection',
  STEP_12_1 = 'backupHeatSource',
  STEP_13 = 'utilityCosts',
  STEP_13_1 = 'backupControlStrategy',
  STEP_14 = 'dehumidificationStrategy',
  STEP_15 = 'ghgCostEnergyUseComparison',
}

export interface Step {
  id: string;
  number: number;
  path: string;
}

export interface StepOption {
  modal?: {
    content: ReactNode;
    title?: ReactNode;
  };
  img?: {
    alt: string;
    fileName: string;
    longDescription: string;
  };
  modalImg?: {
    alt: string;
    fileName: string;
    longDescription: string;
  };
  option?: {
    disclaimer?: string;
    text: string;
    title?: string;
  };
  appendElements?: (JSX.Element | false)[];
  video?: Video;
}

export interface Step1Data {
  address: Address;
  baseCase: BaseCase;
  installationType: InstallationType;
  projectType: ProjectType;
  houseType: HouseType;
  numberOfLevels: number | '';
  situations: ProjectSituationSelections;
}

export interface Step2Data {
  'ashpUnitConfiguration-value': 'A' | 'B' | 'C' | 'D';
  numberOfIndoorUnits?: number;
  numberOfOutdoorUnits?: number;
}

export interface Step2_1Data {
  indoorUnits: {
    'ductlessIndoorOutdoorUnitsConfiguration-value': 'A' | 'B' | 'C' | 'D';
  }[];
  outdoorUnits: {
    'ductlessIndoorOutdoorUnitsConfiguration-value': 'A' | 'B' | 'C' | 'D';
  }[];
}

export interface Step3Data {
  'sizingCapacityRequirements-value': 'A' | 'B' | 'C' | 'D';
}
export interface Step4Data {
  'supplyOutletApproach-value': 'A' | 'B' | 'C';
}
export interface Step5Data {
  'airflowCapacityMeasurementMethod-value': 'A' | 'B';
  serviceSize: number;
  availableCapacity: number;
}
export interface Step5_1Data {
  ductVelocity?: number;
  airflowTesting?: AirflowTesting;
  crossSectionalAreaMeasurement?: CrossSectionalAreaMeasurement;
}
export interface Step6Data {
  'zoningSupplyDucts-value': 'A' | 'B' | 'C';
}
export interface Step7Data {
  'zoningEquipment-value': 'A' | 'B' | 'C';
}
export interface Step8Data {
  'returnAirDuctDesign-value': 'A' | 'B';
}
export interface Step9Data {
  'ductSealing-value': 'A' | 'B' | 'C';
}
export interface Step10Data {
  'heatingCoolingLoadEstimates-value': 'A' | 'B' | 'C' | 'D';
  designHeatLoss?: number;
  designHeatGain?: number;
}
export interface Step11Data {
  'ductShape-value': 'A' | 'B' | 'C';
}
export interface Step12Data {
  heatPumpCompareList: HeatPumpWithBackup[];
}
export interface Step13Data {
  electricityCosts?: ElectricityCost;
  gasCosts?: FurnaceCost;
  oilCosts?: FurnaceCost;
  propaneCosts?: FurnaceCost;
}
export interface Step13_1Data {
  backupControlStrategies: BackupControlStrategy[];
}
export interface Step14Data {
  dehumidificationStrategies: DehumidificationStrategy[];
}
export interface Step15Data {
  ghgCostEnergyUseComparisons: GHGCostEnergyUse[];
}

export interface HPNewHome_Step5Data {
  'ductSizing-value': 'A' | 'B' | 'C';
}

export interface StepData {
  [Steps.STEP_1]: Step1Data;
  [Steps.STEP_2]: Step2Data;
  [Steps.STEP_2_1]: Step2_1Data;
  [Steps.STEP_3]: Step3Data;
  [Steps.STEP_4]: Step4Data;
  [Steps.HPNewHome_Step5Data]: HPNewHome_Step5Data;
  [Steps.STEP_5]: Step5Data;
  [Steps.STEP_5_1]: Step5_1Data;
  [Steps.STEP_6]: Step6Data;
  [Steps.STEP_7]: Step7Data;
  [Steps.STEP_8]: Step8Data;
  [Steps.STEP_9]: Step9Data;
  [Steps.STEP_10]: Step10Data;
  [Steps.STEP_11]: Step11Data;
  [Steps.STEP_12]: Step12Data;
  [Steps.STEP_13]: Step13Data;
  [Steps.STEP_13_1]: Step13_1Data;
  [Steps.STEP_14]: Step14Data;
  [Steps.STEP_15]: Step15Data;
}
