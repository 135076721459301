import { useMutation, useQueryClient } from '@tanstack/react-query';
import { JsonPatchOperation } from '../model/JsonPatchOperation';
import { partialUpdateProject } from '../api/projects';
import { Project } from '../model/Project';

export const useClearProjectBaseCase = (project?: Project) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async () => {
      if (!project || !project.id) {
        throw new Error('Project is required');
      }
      const operations: JsonPatchOperation[] = [
        {
          op: 'add',
          path: '/baseCase/name',
          value: '',
        },
        {
          op: 'add',
          path: '/baseCase/fuelType',
          value: undefined,
        },
        {
          op: 'add',
          path: '/baseCase/efficiency',
          value: undefined,
        },
      ];
      return (await partialUpdateProject(project.id, operations)).data;
    },
    onError: (err) => {
      console.log('err:', err);
    },
    onSuccess: (data) => {
      queryClient.setQueryData(['project', { id: data.id }], data);
    },
  });
};
