/* eslint-disable react-hooks/exhaustive-deps */
import { JSX, useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { UseControllerProps, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AMPS_MAX_DECIMALS } from '../../../const/Formatter';
import { getProjectPropositions } from '../../../const/Projects';
import { useNumberFormatter } from '../../../hooks/useNumberFormatter';
import {
  Project,
  ProjectPropositions,
  ProjectType,
} from '../../../model/Project';
import { Step2Data, Step5Data, StepOption, Steps } from '../../../model/Step';
import { getWorkflow } from '../../../utils/workflowUtils';
import { Input, RadioGroup, Select } from '../../common/Form';
import ProjectStepProposition from '../ProjectStepProposition';
import { StepOptionRadioItem } from '../StepPage';

interface Props {
  step2Data?: Step2Data;
  stepData?: Step5Data;
  project: Project;
  updateProject?: (value) => void;
  displayCompact?: boolean;
  userIsProjectOwner?: boolean;
  hideOptionsSelection?: boolean;
  additionalInfo?: JSX.Element;
  children?: JSX.Element;
}

const ExistingConstraintsPage: React.FC<Props> = ({
  step2Data,
  stepData,
  project,
  updateProject,
  displayCompact,
  userIsProjectOwner,
  hideOptionsSelection,
  additionalInfo,
  children,
}) => {
  const { t } = useTranslation();
  const numberFormatter = useNumberFormatter(AMPS_MAX_DECIMALS);

  const stepId = Steps.STEP_5;
  const isSystemExtension =
    project.projectType === ProjectType.SYSTEM_EXTENSION;
  const stepList = useMemo(() => getWorkflow(project), [project]);
  const stepNumber = useMemo(
    () => stepList.find((step) => step.id === stepId)?.number,
    [stepList]
  );

  const submitField = (value: { [key: string]: string | number }) => {
    updateProject &&
      updateProject({ [`steps.${[stepId]}`]: { ...stepData, ...value } });
  };

  const propositions: ProjectPropositions = useMemo(() => {
    return getProjectPropositions(project);
  }, [project]);

  const {
    control,
    getValues,
    setValue,
    formState: { errors },
  } = useForm<Step5Data>({
    defaultValues: {
      serviceSize: stepData?.serviceSize || 100,
      availableCapacity: stepData?.availableCapacity || 1,
      'airflowCapacityMeasurementMethod-value':
        stepData?.['airflowCapacityMeasurementMethod-value'] || 'A',
    },
    mode: 'onChange',
  });

  const i18nForm = `step-${[stepId]}:form`;

  const options: {
    disabled?: boolean;
    label: string;
    value: string | number;
    labelData: StepOption;
  }[] = [
    {
      label: `${t(`${i18nForm}.a.option.title`, {
        stepNumber: stepNumber,
      })} - ${t(`${i18nForm}.a.option.text`)}`,
      value: 'A',
      disabled: !userIsProjectOwner,
      labelData: {
        img: {
          alt: t('figures:5A.alt'),
          fileName: t('figures:5A.fileName'),
          longDescription: t('figures:5A.longDesc'),
        },
        modal: {
          content: <p>{t(`${i18nForm}.a.modal.text`)}</p>,
          title: t(`${i18nForm}.a.modal.title`),
        },
        option: {
          text: t(`${i18nForm}.a.option.text`),
          title: t(`${i18nForm}.a.option.title`, {
            stepNumber: stepNumber,
          }),
        },
        video: {
          href: t(`${i18nForm}.a.video.href`),
          title: t(`${i18nForm}.a.video.title`),
        },
      },
    },
    {
      label: `${t(`${i18nForm}.b.option.title`, {
        stepNumber: stepNumber,
      })} - ${t(`${i18nForm}.b.option.text`)}`,
      value: 'B',
      disabled: !userIsProjectOwner,
      labelData: {
        img: {
          alt: t('figures:5B.alt'),
          fileName: t('figures:5B.fileName'),
          longDescription: t('figures:5B.longDesc'),
        },
        modal: {
          content: <p>{t(`${i18nForm}.b.modal.text`)}</p>,
          title: t(`${i18nForm}.b.modal.title`),
        },
        option: {
          text: t(`${i18nForm}.b.option.text`),
          title: t(`${i18nForm}.b.option.title`, {
            stepNumber: stepNumber,
          }),
        },
      },
    },
  ];

  const formValues = getValues();
  const [selectedOption, setSelectedOption] = useState(
    options.find(
      (o) => o.value === formValues['airflowCapacityMeasurementMethod-value']
    )
  );

  useEffect(() => {
    if (!Object.values(stepData ?? {}).length) {
      submitField(formValues as any);
    }
  }, []);

  useEffect(() => {
    setSelectedOption(
      options.find(
        (o) =>
          o.value === formValues?.['airflowCapacityMeasurementMethod-value']
      )
    );

    if (stepData && !formValues) {
      setValue('serviceSize', stepData?.serviceSize || 1);
      setValue('availableCapacity', stepData?.availableCapacity || 1);
      setValue(
        'airflowCapacityMeasurementMethod-value',
        stepData?.['airflowCapacityMeasurementMethod-value'] ?? 'A'
      );
    }
  }, [stepData, setValue]);

  const isCentrallyDucted = step2Data?.['ashpUnitConfiguration-value'] === 'A';

  const validationRules = {
    min: {
      value: 1,
      message: t('errors.project.minNumber', {
        min: 1,
      }).toString(),
    },
  };

  const capacityValidationRules: UseControllerProps['rules'] = useMemo(
    () => ({
      min: {
        value: 1,
        message: t('errors.project.minNumber', {
          min: 1,
        }).toString(),
      },
      validate: {
        availableCapacityLessThanServiceSize: (value) => {
          return Number(numberFormatter(value).parsed) > getValues().serviceSize
            ? t(`${i18nForm}.availableCapacityLessThanServiceSize`).toString()
            : true;
        },
      },
    }),
    [getValues().serviceSize]
  );

  return (
    <>
      <form noValidate>
        {displayCompact ? (
          <Row>
            <Col xs={8} md={12}>
              {userIsProjectOwner ? (
                <>
                  <Input
                    id="serviceSize"
                    control={control}
                    label={t(`${i18nForm}.serviceSize`)}
                    inputProps={{ disabled: !userIsProjectOwner }}
                    onBlur={submitField}
                    suffix={t(`${i18nForm}.amps`)}
                    validationRules={validationRules}
                    error={errors?.serviceSize}
                    formatter={numberFormatter}
                  />
                  <Input
                    id="availableCapacity"
                    control={control}
                    label={t(`${i18nForm}.availableCapacity`)}
                    inputProps={{ disabled: !userIsProjectOwner }}
                    onBlur={submitField}
                    suffix={t(`${i18nForm}.amps`)}
                    validationRules={capacityValidationRules}
                    error={errors?.availableCapacity}
                    formatter={numberFormatter}
                  />
                  {isCentrallyDucted && (
                    <Select
                      label={t('checklist.selectedOption')}
                      id={'airflowCapacityMeasurementMethod-value'}
                      control={control}
                      options={options}
                      formGroupClasses={'step-option-radio'}
                      onChange={submitField}
                    />
                  )}
                </>
              ) : (
                <div className="selected-option-text">
                  <label>{t(`${i18nForm}.serviceSize`)}</label>
                  <p>{`${numberFormatter(formValues.serviceSize).formatted} ${t(
                    `${i18nForm}.amps`
                  )}`}</p>
                  <label>{t(`${i18nForm}.availableCapacity`)}</label>
                  <p>{`${
                    numberFormatter(formValues.availableCapacity).formatted
                  } ${t(`${i18nForm}.amps`)}`}</p>
                  {isCentrallyDucted && !hideOptionsSelection && (
                    <>
                      <label>{selectedOption?.labelData.option?.title}</label>
                      <p>{selectedOption?.labelData.option?.text}</p>
                    </>
                  )}
                </div>
              )}
              {children}
              {additionalInfo}
            </Col>
          </Row>
        ) : (
          <>
            <p>
              <strong>{t(`${i18nForm}.electricalConstraints`)}</strong>
            </p>
            <p>{t(`${i18nForm}.description`)}</p>
            <Row>
              <Col xs={6} sm={5}>
                <Input
                  id="serviceSize"
                  control={control}
                  label={t(`${i18nForm}.serviceSize`)}
                  inputProps={{ disabled: !userIsProjectOwner }}
                  onBlur={submitField}
                  type="text"
                  suffix={t(`${i18nForm}.amps`)}
                  validationRules={validationRules}
                  error={errors?.serviceSize}
                  formatter={numberFormatter}
                />
              </Col>
              <Col xs={6} sm={5}>
                <Input
                  id="availableCapacity"
                  control={control}
                  label={t(`${i18nForm}.availableCapacity`)}
                  inputProps={{ disabled: !userIsProjectOwner }}
                  onBlur={submitField}
                  type="text"
                  suffix={t(`${i18nForm}.amps`)}
                  validationRules={capacityValidationRules}
                  error={errors?.availableCapacity}
                  formatter={numberFormatter}
                />
              </Col>
            </Row>
            {isCentrallyDucted && (
              <>
                <p>
                  <strong>{t(`${i18nForm}.ductContraints`)}</strong>
                </p>
                <p>
                  {isSystemExtension
                    ? t(`${i18nForm}.ductContraintsSystemExtension`)
                    : t(`${i18nForm}.ductContraintsDesc`)}
                </p>
                <RadioGroup
                  id={'airflowCapacityMeasurementMethod-value'}
                  control={control}
                  items={options}
                  radioClassName="step-option-radio"
                  onChange={submitField}
                  renderLabel={(item) => <StepOptionRadioItem item={item} />}
                />
                <ProjectStepProposition
                  id={stepId}
                  propositions={propositions.options[stepId]}
                />
              </>
            )}
          </>
        )}
      </form>
    </>
  );
};

export default ExistingConstraintsPage;
