import React, { ComponentType, useMemo } from 'react';
import { Route, BrowserRouterProps } from 'react-router-dom';
import { CompatRouter } from 'react-router-dom-v5-compat';
import { SplashPage } from '../Splash';
import { getLang } from '../../utils/splashUtils';

interface Props {
  RouterComponent: ComponentType<BrowserRouterProps>;
  children: React.ReactNode;
}

const LocalizedRouter: React.FC<Props> = ({ children, RouterComponent }) => {
  const isInit = useMemo(() => Boolean(getLang()), []);

  return (
    <RouterComponent>
      <CompatRouter>
        <Route path="/">
          {({ location }) => {
            const { pathname } = location;

            if (pathname === '/' && !isInit) {
              return <SplashPage />;
            }

            return <>{children}</>;
          }}
        </Route>
      </CompatRouter>
    </RouterComponent>
  );
};

export default LocalizedRouter;
