import { PageHeader } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { Trans, useTranslation } from 'react-i18next';
import { AppRoute } from '../../const';
import { LocalizedNavLink } from '../i18n';
import { AppHeader } from '.';

const LicensePage: React.FC = () => {
  const { t } = useTranslation('license');
  return (
    <div className="license-page">
      <Helmet>
        <title>
          {t('common:license.title')} - {t('common:pageTitle.appName')}
        </title>
      </Helmet>
      <AppHeader />
      <PageHeader>{t('common:license.title')}</PageHeader>
      <p>
        <Trans i18nKey="license:text1" />
      </p>
      <p>
        <Trans i18nKey="license:text2" />
      </p>
      <p>
        <Trans i18nKey="license:text3" />
      </p>
      <ol>
        <li>
          <strong>
            <Trans i18nKey="license:term1.title" />
          </strong>
          <ol>
            <li>
              <Trans i18nKey="license:term1.1" />
            </li>
            <li>
              <Trans i18nKey="license:term1.2" />
            </li>
          </ol>
        </li>
        <li>
          <strong>
            <Trans i18nKey="license:term2.title" />
          </strong>
          <ol>
            <li>
              <Trans i18nKey="license:term2.1" />
            </li>
            <li>
              <Trans i18nKey="license:term2.2" />
            </li>
          </ol>
        </li>
        <li>
          <strong>
            <Trans i18nKey="license:term3.title" />
          </strong>
          <ol>
            <li>
              <Trans i18nKey="license:term3.1" />
            </li>
            <li>
              <Trans i18nKey="license:term3.2" />
            </li>
          </ol>
        </li>
        <li>
          <strong>
            <Trans i18nKey="license:term4.title" />
          </strong>
          <ol>
            <li>
              <Trans i18nKey="license:term4.1" />
            </li>
          </ol>
        </li>
        <li>
          <strong>
            <Trans i18nKey="license:term5.title" />
          </strong>
          <ol>
            <li>
              <Trans i18nKey="license:term5.1" />
            </li>
            <li>
              <Trans i18nKey="license:term5.2" />
            </li>
          </ol>
        </li>
        <li>
          <strong>
            <Trans i18nKey="license:term6.title" />
          </strong>
          <ol>
            <li>
              <Trans i18nKey="license:term6.1" />
            </li>
            <li>
              <Trans i18nKey="license:term6.2" />
            </li>
          </ol>
        </li>
        <li>
          <strong>
            <Trans i18nKey="license:term7.title" />
          </strong>
          <ol>
            <li>
              <Trans i18nKey="license:term7.1" />
            </li>
            <li>
              <Trans i18nKey="license:term7.2" />
            </li>
            <li>
              <Trans i18nKey="license:term7.3" />
            </li>
          </ol>
        </li>
        <li>
          <strong>
            <Trans i18nKey="license:term8.title" />
          </strong>
          <ol>
            <li>
              <Trans i18nKey="license:term8.1" />
            </li>
            <li>
              <Trans i18nKey="license:term8.2" />
            </li>
          </ol>
        </li>
        <li>
          <strong>
            <Trans i18nKey="license:term9.title" />
          </strong>
          <ol>
            <li>
              <Trans i18nKey="license:term9.1" />
            </li>
            <li>
              <Trans i18nKey="license:term9.2" />
            </li>
          </ol>
        </li>
        <li>
          <strong>
            <Trans i18nKey="license:term10.title" />
          </strong>
          <ol>
            <li>
              <Trans i18nKey="license:term10.1" />
            </li>
          </ol>
        </li>
        <li>
          <strong>
            <Trans i18nKey="license:term11.title" />
          </strong>
          <ol>
            <li>
              <Trans i18nKey="license:term11.1" />
            </li>
          </ol>
        </li>
        <li>
          <strong>
            <Trans i18nKey="license:term12.title" />
          </strong>
          <ol>
            <li>
              <Trans i18nKey="license:term12.1" />
              <p>
                <Trans i18nKey="license:term12.nrcan" />
                <br />
                <Trans i18nKey="license:term12.IPDivision" />
                <br />
                <a href={`mailto:${t('emailAddress')}`}>
                  <Trans i18nKey="license:term12.emailAddress" />
                </a>
              </p>
            </li>
          </ol>
        </li>
      </ol>
      <LocalizedNavLink
        to={AppRoute.Dashboard}
        className="btn btn-default btn-sm btn-back-home"
      >
        {t('common:common.backHomeButton')}
      </LocalizedNavLink>
    </div>
  );
};

export default LicensePage;
