import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

interface Props {
  children: React.ReactNode;
  className?: string;
  disabled?: boolean;
  id: string; // required for accessibility
  placement?: 'top' | 'right' | 'bottom' | 'left';
  tooltip: React.ReactNode;
}

const TooltipWrapper: React.FC<Props> = ({
  children,
  disabled,
  id,
  placement = 'bottom',
  tooltip,
}): React.ReactNode => {
  if (disabled) {
    return children;
  }

  return (
    <OverlayTrigger
      overlay={<Tooltip id={id}>{tooltip}</Tooltip>}
      placement={placement}
    >
      {children}
    </OverlayTrigger>
  );
};

export default TooltipWrapper;
