/* eslint-disable react-hooks/exhaustive-deps */
import { useForm } from 'react-hook-form';
import { Row, Col } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import { JSX } from 'react';
import { Step12Data, Step13Data, Steps } from '../../../model/Step';
import { Select, Input } from '../../common/Form';
import { FurnaceType } from '../../../model/HeatPumpWithBackup/FurnaceType';
import { BackupType } from '../../../model/HeatPumpWithBackup/BackupType';
import { BillingType } from '../../../model/UtilityCosts/BillingType';
import { ElectricityCost } from '../../../model/UtilityCosts/ElectricityCost';
import { FurnaceCost } from '../../../model/UtilityCosts/FurnaceCost';
import { NUMBER_MAX_DECIMALS } from '../../../const/Formatter';
import { useNumberFormatter } from '../../../hooks/useNumberFormatter';

interface Props {
  step12Data?: Step12Data;
  stepData?: Step13Data;
  updateProject?: (value) => void;
  displayCompact?: boolean;
  userIsProjectOwner?: boolean;
  additionalInfo?: JSX.Element;
  children?: JSX.Element;
}

const UtilityCostsPage: React.FC<Props> = ({
  step12Data,
  stepData,
  updateProject,
  displayCompact,
  userIsProjectOwner,
  additionalInfo,
  children,
}) => {
  const { t } = useTranslation();
  const numberFormatter = useNumberFormatter(NUMBER_MAX_DECIMALS);

  const stepId = Steps.STEP_13;

  const submitField = () => {
    updateProject &&
      updateProject({
        [`steps.${[stepId]}`]: getValues(),
      });
  };

  const {
    control,
    getValues,
    formState: { errors },
  } = useForm<Step13Data>({
    defaultValues: {
      electricityCosts: new ElectricityCost(stepData?.electricityCosts),
      gasCosts: new FurnaceCost(stepData?.gasCosts),
      oilCosts: new FurnaceCost(stepData?.oilCosts),
      propaneCosts: new FurnaceCost(stepData?.propaneCosts),
    },
    mode: 'onChange',
  });

  const formValues = getValues();

  const i18nForm = `step-${stepId}:form`;
  const i18nDesc = `step-${stepId}:desciption`;

  const billingTypeOptions = [
    {
      label: t(`${i18nForm}.billingType.fixed`),
      value: BillingType.Fixed,
    },
    {
      label: t(`${i18nForm}.billingType.tiered`),
      value: BillingType.Tiered,
    },
    {
      label: t(`${i18nForm}.billingType.timeOfUse`),
      value: BillingType.TimeOfUse,
    },
  ];
  const validationRules = {
    min: {
      value: 1,
      message: t('errors.project.minNumber', {
        min: 1,
      }).toString(),
    },
  };

  const hasFurnace = step12Data?.heatPumpCompareList.some(
    (hp) => hp.backupType === BackupType.Furnace
  );
  const hasPropane = step12Data?.heatPumpCompareList.some(
    (hp) => hp.furnaceType === FurnaceType.Propane
  );
  const hasGas = step12Data?.heatPumpCompareList.some(
    (hp) => hp.furnaceType === FurnaceType.Gas
  );
  const hasOil = step12Data?.heatPumpCompareList.some(
    (hp) => hp.furnaceType === FurnaceType.Oil
  );

  const selectedBillingType = billingTypeOptions.find(
    (option) => option.value === formValues.electricityCosts?.billingType
  );

  const content = (
    <div className="define-backup-control-strategy-container">
      <p className="underline-text">{t(`${i18nForm}.electric.label`)}</p>
      <Row>
        <Col xs={6} sm={5}>
          <Select
            label={t(`${i18nForm}.billingType.label`)}
            id={`electricityCosts.billingType`}
            control={control}
            options={billingTypeOptions}
            onChange={submitField}
            formGroupClasses="form-group-full-width-select"
          />
        </Col>
        {formValues.electricityCosts?.billingType === BillingType.Fixed ||
        formValues.electricityCosts?.billingType === BillingType.Tiered ? (
          <Col xs={12} sm={5}>
            <Input
              id={`electricityCosts.cost`}
              control={control}
              formatter={numberFormatter}
              label={t(`${i18nForm}.electric.cost`)}
              suffix={t(`${i18nForm}.electric.units`)}
              onBlur={submitField}
              validationRules={validationRules}
              error={errors.electricityCosts?.cost}
            />
          </Col>
        ) : (
          <>
            <Col xs={6} sm={5}>
              <Input
                id={`electricityCosts.onPeakCost`}
                control={control}
                formatter={numberFormatter}
                label={t(`${i18nForm}.electric.onPeakCost`)}
                onBlur={submitField}
                validationRules={validationRules}
                error={errors.electricityCosts?.onPeakCost}
              />
            </Col>
            <Col xs={6} sm={5}>
              <Input
                id={`electricityCosts.midPeakCost`}
                control={control}
                formatter={numberFormatter}
                label={t(`${i18nForm}.electric.midPeakCost`)}
                onBlur={submitField}
                validationRules={validationRules}
                error={errors.electricityCosts?.midPeakCost}
              />
            </Col>
            <Col xs={6} sm={5}>
              <Input
                id={`electricityCosts.offPeakCost`}
                control={control}
                formatter={numberFormatter}
                label={t(`${i18nForm}.electric.offPeakCost`)}
                onBlur={submitField}
                validationRules={validationRules}
                error={errors.electricityCosts?.offPeakCost}
              />
            </Col>
          </>
        )}
      </Row>
      {hasFurnace && (
        <>
          {hasPropane && (
            <>
              <p className="underline-text">{t(`${i18nForm}.propane.label`)}</p>
              <Row>
                <Col xs={12} sm={5}>
                  <Input
                    id={`propaneCosts.cost`}
                    control={control}
                    formatter={numberFormatter}
                    label={t(`${i18nForm}.propane.cost`)}
                    onBlur={submitField}
                    suffix={t(`${i18nForm}.propane.unit`)}
                    validationRules={validationRules}
                    error={errors.propaneCosts?.cost}
                  />
                </Col>
              </Row>
            </>
          )}
          {hasGas && (
            <>
              <p className="underline-text">{t(`${i18nForm}.gas.label`)}</p>
              <Row>
                <Col xs={12} sm={5}>
                  <Input
                    id={`gasCosts.cost`}
                    control={control}
                    formatter={numberFormatter}
                    label={t(`${i18nForm}.gas.cost`)}
                    onBlur={submitField}
                    suffix={t(`${i18nForm}.gas.unit`)}
                    validationRules={validationRules}
                    error={errors.gasCosts?.cost}
                  />
                </Col>
              </Row>
            </>
          )}
          {hasOil && (
            <>
              <p className="underline-text">{t(`${i18nForm}.oil.label`)}</p>
              <Row>
                <Col xs={12} sm={5}>
                  <Input
                    id={`oilCosts.cost`}
                    control={control}
                    formatter={numberFormatter}
                    label={t(`${i18nForm}.oil.cost`)}
                    onBlur={submitField}
                    suffix={t(`${i18nForm}.oil.unit`)}
                    validationRules={validationRules}
                    error={errors.oilCosts?.cost}
                  />
                </Col>
              </Row>
            </>
          )}
        </>
      )}
    </div>
  );

  return (
    <>
      {displayCompact ? (
        <Row>
          <Col xs={12}>
            {userIsProjectOwner ? (
              content
            ) : (
              <>
                <div className="selected-option-text">
                  <Row>
                    <Col xs={12}>
                      <p className="underline-text">
                        {t(`${i18nForm}.electric.label`)}
                      </p>
                    </Col>
                    <Col xs={6}>
                      <label>{t(`${i18nForm}.billingType.label`)}</label>
                      <p>{selectedBillingType?.label}</p>
                    </Col>
                    {formValues.electricityCosts?.billingType ===
                      BillingType.Fixed ||
                    formValues.electricityCosts?.billingType ===
                      BillingType.Tiered ? (
                      <Col xs={6}>
                        <label>{t(`${i18nForm}.electric.cost`)}</label>
                        <p>{`${
                          numberFormatter(
                            formValues.electricityCosts?.cost ?? 0
                          ).formatted
                        } ${t(`${i18nForm}.electric.units`)}`}</p>
                      </Col>
                    ) : (
                      <>
                        <Col xs={6} sm={5}>
                          <label>{t(`${i18nForm}.electric.onPeakCost`)}</label>
                          <p>
                            {
                              numberFormatter(
                                formValues.electricityCosts?.onPeakCost ?? 0
                              ).formatted
                            }
                          </p>
                        </Col>
                        <Col xs={6} sm={5}>
                          <label>{t(`${i18nForm}.electric.midPeakCost`)}</label>
                          <p>
                            {
                              numberFormatter(
                                formValues.electricityCosts?.midPeakCost ?? 0
                              ).formatted
                            }
                          </p>
                        </Col>
                        <Col xs={6} sm={5}>
                          <label>{t(`${i18nForm}.electric.offPeakCost`)}</label>
                          <p>
                            {
                              numberFormatter(
                                formValues.electricityCosts?.offPeakCost ?? 0
                              ).formatted
                            }
                          </p>
                        </Col>
                      </>
                    )}
                    {hasFurnace && (
                      <>
                        {hasPropane && (
                          <>
                            <Col xs={12}>
                              <p className="underline-text">
                                {t(`${i18nForm}.propane.label`)}
                              </p>
                            </Col>
                            <Col xs={6} sm={6}>
                              <label>{t(`${i18nForm}.propane.cost`)}</label>
                              <p>{`${
                                numberFormatter(
                                  formValues.propaneCosts?.cost ?? 0
                                ).formatted
                              } ${i18nForm}.propane.unit`}</p>
                            </Col>
                          </>
                        )}
                        {hasGas && (
                          <>
                            <Col xs={12}>
                              <p className="underline-text">
                                {t(`${i18nForm}.gas.label`)}
                              </p>
                            </Col>
                            <Col xs={6} sm={6}>
                              <label>{t(`${i18nForm}.gas.cost`)}</label>
                              <p>{`${
                                numberFormatter(formValues.gasCosts?.cost ?? 0)
                                  .formatted
                              } ${t(`${i18nForm}.gas.unit`)}`}</p>
                            </Col>
                          </>
                        )}
                        {hasOil && (
                          <>
                            <Col xs={12}>
                              <p className="underline-text">
                                {t(`${i18nForm}.oil.label`)}
                              </p>
                            </Col>
                            <Col xs={6} sm={6}>
                              <label>{t(`${i18nForm}.oil.cost`)}</label>
                              <p>{`${
                                numberFormatter(formValues.oilCosts?.cost ?? 0)
                                  .formatted
                              } ${t(`${i18nForm}.oil.unit`)}`}</p>
                            </Col>
                          </>
                        )}
                      </>
                    )}
                  </Row>
                </div>
              </>
            )}
            {children}
            {additionalInfo}
          </Col>
        </Row>
      ) : (
        <form noValidate>
          {content}
          <div className="utility-costs-desciption">
            <p>{t(`${i18nDesc}.list1.title`)}</p>
            <ul>
              <li>{t(`${i18nDesc}.list1.item1`)}</li>
              <li>{t(`${i18nDesc}.list1.item2`)}</li>
              <li>{t(`${i18nDesc}.list1.item3`)}</li>
            </ul>
            <p>
              <Trans>{t(`${i18nDesc}.list1.example`)}</Trans>
            </p>
            <Row>
              <Col xs={7} sm={5}>
                <img
                  src={`/assets/images/${t(`${i18nDesc}.list1.image.src`)}`}
                  alt={t(`${i18nDesc}.list1.image.alt`)}
                />
              </Col>
            </Row>

            <p>{t(`${i18nDesc}.list2.title`)}</p>
            <ul>
              <li>
                <Trans>{t(`${i18nDesc}.list2.item1`)}</Trans>
              </li>
              <li>
                <Trans>{t(`${i18nDesc}.list2.item2`)}</Trans>
              </li>
            </ul>

            <p>{t(`${i18nDesc}.list3.title`)}</p>
            <ul>
              <li>{t(`${i18nDesc}.list3.item1`)}</li>
            </ul>

            <p>{t(`${i18nDesc}.text1`)}</p>
            <p>{t(`${i18nDesc}.text2`)}</p>

            <p>{t(`${i18nDesc}.list4.title`)}</p>
            <ul>
              <li>
                <Trans>{t(`${i18nDesc}.list4.item1`)}</Trans>
              </li>
              <li>
                <Trans>{t(`${i18nDesc}.list4.item2`)}</Trans>
              </li>
              <li>
                <Trans>{t(`${i18nDesc}.list4.item3`)}</Trans>
              </li>
            </ul>
            <p>
              <Trans>{t(`${i18nDesc}.list4.example`)}</Trans>
            </p>
            <Row>
              <Col xs={7} sm={5}>
                <img
                  src={`/assets/images/${t(`${i18nDesc}.list4.image.src`)}`}
                  alt={t(`${i18nDesc}.list4.image.alt`)}
                />
              </Col>
            </Row>

            <p>{t(`${i18nDesc}.list5.title`)}</p>
            <ul>
              <li>
                <Trans>{t(`${i18nDesc}.list5.item1`)}</Trans>
              </li>
              <li>
                <Trans>{t(`${i18nDesc}.list5.item2`)}</Trans>
              </li>
            </ul>

            <p>{t(`${i18nDesc}.list6.title`)}</p>
            <ul>
              <li>{t(`${i18nDesc}.list6.item1`)}</li>
            </ul>

            <p>{t(`${i18nDesc}.text3`)}</p>

            <p>{t(`${i18nDesc}.list7.title`)}</p>
            <ul>
              <li>{t(`${i18nDesc}.list7.item1`)}</li>
            </ul>
            <p>{t(`${i18nDesc}.list7.example`)}</p>
            <img
              src={`/assets/images/${t(`${i18nDesc}.list7.image.src`)}`}
              alt={t(`${i18nDesc}.list7.image.alt`)}
            />

            <p>{t(`${i18nDesc}.list8.title`)}</p>
            <p>
              <Trans>{t(`${i18nDesc}.list8.example`)}</Trans>
            </p>
            <Row>
              <Col xs={7} sm={5}>
                <img
                  src={`/assets/images/${t(`${i18nDesc}.list8.image.src`)}`}
                  alt={t(`${i18nDesc}.list8.image.alt`)}
                />
              </Col>
            </Row>

            <p>{t(`${i18nDesc}.list9.title`)}</p>
            <p>{t(`${i18nDesc}.list9.item1`)}</p>

            <p>{t(`${i18nDesc}.list10.title`)}</p>
            <p>
              <Trans>{t(`${i18nDesc}.list10.table.title`)}</Trans>
            </p>
            <div className="tableContainer">
              <table>
                <thead>
                  <tr>
                    <th>{t(`${i18nDesc}.list10.table.label1`)}</th>
                    <th>{t(`${i18nDesc}.list10.table.label2`)}</th>
                    <th>{t(`${i18nDesc}.list10.table.label3`)}</th>
                    <th>{t(`${i18nDesc}.list10.table.label4`)}</th>
                    <th>{t(`${i18nDesc}.list10.table.label5`)}</th>
                    <th>{t(`${i18nDesc}.list10.table.label6`)}</th>
                    <th>{t(`${i18nDesc}.list10.table.label7`)}</th>
                    <th>{t(`${i18nDesc}.list10.table.label8`)}</th>
                    <th>{t(`${i18nDesc}.list10.table.label9`)}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{t(`${i18nDesc}.list10.table.value1`)}</td>
                    <td>{t(`${i18nDesc}.list10.table.value2`)}</td>
                    <td>{t(`${i18nDesc}.list10.table.value3`)}</td>
                    <td>{t(`${i18nDesc}.list10.table.value4`)}</td>
                    <td>{t(`${i18nDesc}.list10.table.value5`)}</td>
                    <td>{t(`${i18nDesc}.list10.table.value6`)}</td>
                    <td>{t(`${i18nDesc}.list10.table.value7`)}</td>
                    <td>{t(`${i18nDesc}.list10.table.value8`)}</td>
                    <td>{t(`${i18nDesc}.list10.table.value9`)}</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <p>{t(`${i18nDesc}.list10.text1`)}</p>
          </div>
        </form>
      )}
    </>
  );
};

export default UtilityCostsPage;
