import { FuelType } from './Fuel';

export class BaseCase {
  name = '';
  fuelType?: FuelType;
  efficiency?: number;

  constructor(entity?: BaseCase) {
    if (entity) {
      Object.assign(this, { ...entity });
    } else {
      this.name = '';
      this.fuelType = undefined;
      this.efficiency = undefined;
    }
  }
}
