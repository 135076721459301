import { useMemo, useState } from 'react';
import { Col, PageHeader, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import MediaQuery from 'react-responsive';
import { useUserIsProjectOwner } from '../../../hooks/useUserIsProjectOwner';
import { ProjectMenu, ProjectStatus } from '..';
import { AppRoute } from '../../../const';
import { InstallationType, Project, ProjectType } from '../../../model/Project';
import ProjectDetailsPage from '../Steps/ProjectDetailsPage';
import {
  ZoningSupplyDuctsPage,
  SizingCapacityRequirementsPage,
  AshpUnitConfigurationPage,
  DuctlessIndoorOutdoorUnitsConfigurationPage,
  ExistingConstraintsPage,
  ReturnAirDuctDesignPage,
  DuctSealingPage,
  HeatingCoolingLoadEstimatesPage,
  SupplyOutletApproachPage,
  DehumidificationStrategyPage,
  DuctShapePage,
  HeatPumpSelectionPage,
  UtilityCostsPage,
  ZoningEquipmentPage,
  GHGCostEnergyUseComparisonPage,
  AirflowTestingPage,
  CrossSectionalAreaMeasurementPage,
  DuctSizingPage,
  BackupHeatSourcePage,
  BackupControlStrategyPage,
} from '../Steps';
import { Steps } from '../../../model/Step';
import { ProjectComments } from '../Comments';
import { MD_MIN_SIZE, SM_MAX_SIZE } from '../../../const/Responsive';
import { usePartialProjectUpdateMutation } from '../../../hooks/usePartialProjectUpdateMutation';
import {
  getWorkflow,
  getWorkflowWithoutSubSteps,
} from '../../../utils/workflowUtils';
import { JsonPatchOperation } from '../../../model/JsonPatchOperation';
import { ProjectStepFooter, ProjectStepHeader } from '.';

interface Props {
  project: Project;
}

const ProjectStepsPage: React.FC<Props> = ({ project }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { stepId } = useParams<{ stepId: string }>();
  const [serverError, setServerError] = useState<string | undefined>();
  const steps = project.steps || {};

  const userIsProjectOwner = useUserIsProjectOwner(project);
  const partialUpdateProjectMutation = usePartialProjectUpdateMutation(project);

  const stepsList = useMemo(() => getWorkflow(project), [project]);
  const stepsListWithoutSubStep = useMemo(
    () => getWorkflowWithoutSubSteps(project),
    [project]
  );

  const updateProjectFn = async (value: {
    [key: string]: string | number | boolean | undefined;
  }) => {
    if (userIsProjectOwner) {
      //used to default value that depend on other value
      let operations: JsonPatchOperation[] = [];
      if (value.installationType) {
        operations = [
          {
            op: 'add',
            path: '/projectType',
            value:
              value.installationType === InstallationType.NEW_HOME
                ? ProjectType.NEW_SYSTEM_INSTALLATION
                : project.projectType === ProjectType.NEW_SYSTEM_INSTALLATION
                ? ProjectType.EQUIPMENT_REPLACEMENT_ONLY
                : project.projectType,
          },
        ];
      }
      if (value['address.country']) {
        operations = [
          {
            op: 'add',
            path: '/address/province',
            value: '',
          },
          {
            op: 'add',
            path: '/address/city',
            value: '',
          },
        ];
      }
      if (value['address.province']) {
        operations = [
          {
            op: 'add',
            path: '/address/city',
            value: '',
          },
        ];
      }

      await partialUpdateProjectMutation.mutateAsync(
        [
          { op: 'add', path: '/updatedAt', value: new Date() },
          {
            op: 'add',
            path: `/${Object.keys(value)[0].replace('.', '/')}`,
            value: value[Object.keys(value)[0]],
          },
          ...operations,
        ],
        {
          onSuccess: () => setServerError(undefined),
          onError: (err) => {
            console.log(err);
            setServerError(t('errors.common.unknown'));
          },
        }
      );
    }
  };

  const step = useMemo(() => {
    const stepItem = stepsList.find((stepItem) => t(stepItem.path) === stepId);

    if (!stepItem) {
      history.replace(`/${t(AppRoute.NotFound)}`);
      return;
    }

    return stepItem;
  }, [stepsList, t, stepId, history]);

  const isCentrallyDucted =
    steps[Steps.STEP_2]['ashpUnitConfiguration-value'] === 'A';

  return (
    <div className="step-page">
      <Helmet>
        <title>
          {t('pageTitle.step', {
            projectName: project.name,
            stepNumber: step?.number,
          })}{' '}
          - {t('pageTitle.appName')}
        </title>
      </Helmet>
      <PageHeader>
        {t(`stepPage.pageTitle`, {
          projectName: project.name,
          stepNumber: step?.number,
        })}
      </PageHeader>

      <ProjectMenu project={project} />

      <Row>
        <MediaQuery maxWidth={SM_MAX_SIZE}>
          <Col xs={12}>
            <ProjectStatus
              project={project}
              stepsList={stepsListWithoutSubStep}
            />
          </Col>
        </MediaQuery>

        <Col xs={12} md={8}>
          {step && (
            <>
              <ProjectStepHeader
                completedSteps={project.completedSteps}
                project={project}
                step={step}
                userIsProjectOwner={userIsProjectOwner}
                stepsList={stepsList}
                serverError={serverError}
              />

              {step?.path === AppRoute.Step1 && (
                <ProjectDetailsPage
                  project={project}
                  updateProject={updateProjectFn}
                  userIsProjectOwner={userIsProjectOwner}
                />
              )}

              {step?.path === AppRoute.Step2 && (
                <AshpUnitConfigurationPage
                  step2_1Data={steps[Steps.STEP_2_1]}
                  stepData={steps[Steps.STEP_2]}
                  project={project}
                  updateProject={updateProjectFn}
                  userIsProjectOwner={userIsProjectOwner}
                />
              )}

              {step?.path === AppRoute.Step2_1 && (
                <DuctlessIndoorOutdoorUnitsConfigurationPage
                  step2Data={steps[Steps.STEP_2]}
                  stepData={steps[Steps.STEP_2_1]}
                  updateProject={updateProjectFn}
                  userIsProjectOwner={userIsProjectOwner}
                />
              )}

              {step?.path === AppRoute.Step3 && (
                <SizingCapacityRequirementsPage
                  stepData={steps[Steps.STEP_3]}
                  project={project}
                  updateProject={updateProjectFn}
                  userIsProjectOwner={userIsProjectOwner}
                />
              )}

              {step?.path === AppRoute.Step4 && (
                <SupplyOutletApproachPage
                  project={project}
                  stepData={steps[Steps.STEP_4]}
                  updateProject={updateProjectFn}
                  userIsProjectOwner={userIsProjectOwner}
                />
              )}

              {step?.path === AppRoute.HPNewHome_Step5 && (
                <DuctSizingPage
                  stepData={steps[Steps.HPNewHome_Step5Data]}
                  project={project}
                  updateProject={updateProjectFn}
                  userIsProjectOwner={userIsProjectOwner}
                />
              )}

              {step?.path === AppRoute.Step5 && (
                <ExistingConstraintsPage
                  step2Data={steps[Steps.STEP_2]}
                  stepData={steps[Steps.STEP_5]}
                  project={project}
                  updateProject={updateProjectFn}
                  userIsProjectOwner={userIsProjectOwner}
                />
              )}

              {step?.path === AppRoute.Step5_1_1 && isCentrallyDucted && (
                <AirflowTestingPage
                  stepData={steps[Steps.STEP_5_1]}
                  updateProject={updateProjectFn}
                  userIsProjectOwner={userIsProjectOwner}
                />
              )}

              {step?.path === AppRoute.Step5_1_2 && isCentrallyDucted && (
                <CrossSectionalAreaMeasurementPage
                  stepData={steps[Steps.STEP_5_1]}
                  updateProject={updateProjectFn}
                  userIsProjectOwner={userIsProjectOwner}
                />
              )}

              {step?.path === AppRoute.Step6 && (
                <ZoningSupplyDuctsPage
                  project={project}
                  stepData={steps[Steps.STEP_6]}
                  updateProject={updateProjectFn}
                  userIsProjectOwner={userIsProjectOwner}
                />
              )}

              {step?.path === AppRoute.Step7 && (
                <ZoningEquipmentPage
                  project={project}
                  stepData={steps[Steps.STEP_7]}
                  updateProject={updateProjectFn}
                  userIsProjectOwner={userIsProjectOwner}
                />
              )}

              {step?.path === AppRoute.Step8 && (
                <ReturnAirDuctDesignPage
                  project={project}
                  stepData={steps[Steps.STEP_8]}
                  updateProject={updateProjectFn}
                  userIsProjectOwner={userIsProjectOwner}
                />
              )}

              {step?.path === AppRoute.Step9 && (
                <DuctSealingPage
                  project={project}
                  stepData={steps[Steps.STEP_9]}
                  updateProject={updateProjectFn}
                  userIsProjectOwner={userIsProjectOwner}
                />
              )}

              {step?.path === AppRoute.Step10 && (
                <HeatingCoolingLoadEstimatesPage
                  project={project}
                  stepData={steps[Steps.STEP_10]}
                  updateProject={updateProjectFn}
                  userIsProjectOwner={userIsProjectOwner}
                />
              )}

              {step?.path === AppRoute.Step11 && (
                <DuctShapePage
                  stepData={steps[Steps.STEP_11]}
                  updateProject={updateProjectFn}
                  userIsProjectOwner={userIsProjectOwner}
                />
              )}

              {step?.path === AppRoute.Step12 && (
                <HeatPumpSelectionPage
                  project={project}
                  stepData={steps[Steps.STEP_12]}
                  stepsList={stepsList}
                  updateProject={updateProjectFn}
                  userIsProjectOwner={userIsProjectOwner}
                />
              )}

              {step?.path === AppRoute.Step12_1 && (
                <BackupHeatSourcePage
                  stepData={steps[Steps.STEP_12]}
                  updateProject={updateProjectFn}
                  userIsProjectOwner={userIsProjectOwner}
                />
              )}

              {step?.path === AppRoute.Step13 && (
                <UtilityCostsPage
                  step12Data={steps[Steps.STEP_12]}
                  stepData={steps[Steps.STEP_13]}
                  updateProject={updateProjectFn}
                  userIsProjectOwner={userIsProjectOwner}
                />
              )}

              {step?.path === AppRoute.Step13_1 && (
                <BackupControlStrategyPage
                  project={project}
                  step12Data={steps[Steps.STEP_12]}
                  stepData={steps[Steps.STEP_13_1]}
                  updateProject={updateProjectFn}
                  userIsProjectOwner={userIsProjectOwner}
                />
              )}

              {step?.path === AppRoute.Step14 && (
                <DehumidificationStrategyPage
                  project={project}
                  stepData={steps[Steps.STEP_14]}
                  updateProject={updateProjectFn}
                  userIsProjectOwner={userIsProjectOwner}
                />
              )}

              {step?.path === AppRoute.Step15 && (
                <GHGCostEnergyUseComparisonPage
                  project={project}
                  stepData={steps[Steps.STEP_15]}
                  userIsProjectOwner={userIsProjectOwner}
                />
              )}

              <ProjectStepFooter
                completedSteps={project.completedSteps}
                additionalInformation={project.additionalInformation}
                project={project}
                step={step}
                stepsList={stepsList}
                userIsProjectOwner={userIsProjectOwner}
                serverError={serverError}
              />
            </>
          )}
        </Col>
        <Col xs={12} md={4}>
          <MediaQuery minWidth={MD_MIN_SIZE}>
            <ProjectStatus
              project={project}
              stepsList={stepsListWithoutSubStep}
            />
          </MediaQuery>
          <ProjectComments project={project} pageId={step?.id ?? ''} />
        </Col>
      </Row>
    </div>
  );
};

export default ProjectStepsPage;
