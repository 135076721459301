import { Helmet } from 'react-helmet-async';
import { Trans, useTranslation } from 'react-i18next';
import { Col, PageHeader, Row } from 'react-bootstrap';
import { AppHeader } from '../common';
import { VideoContent } from '../Project/Videos';

const VideosPage: React.FC = () => {
  const { t } = useTranslation();
  const videos = [
    {
      description: t('videos.video1.description'),
      href: t('videos.video1.href'),
      title: t('videos.video1.title'),
    },
    {
      description: t('videos.video2.description'),
      href: t('videos.video2.href'),
      title: t('videos.video2.title'),
    },
    {
      description: t('videos.video3.description'),
      href: t('videos.video3.href'),
      title: t('videos.video3.title'),
    },
    {
      description: t('videos.video4.description'),
      href: t('videos.video4.href'),
      title: t('videos.video4.title'),
    },
    {
      description: t('videos.video5.description'),
      href: t('videos.video5.href'),
      title: t('videos.video5.title'),
    },
    {
      description: t('videos.video6.description'),
      href: t('videos.video6.href'),
      title: t('videos.video6.title'),
    },
    {
      description: t('videos.video7.description'),
      href: t('videos.video7.href'),
      title: t('videos.video7.title'),
    },
    {
      description: t('videos.video8.description'),
      href: t('videos.video8.href'),
      title: t('videos.video8.title'),
    },
  ];

  return (
    <>
      <Helmet>
        <title>
          {t('pageTitle.videos')} - {t('pageTitle.appName')}
        </title>
      </Helmet>
      <AppHeader />
      <PageHeader>{t('videos.title')}</PageHeader>
      <p className="video-page-description">{t('videos.description')}</p>
      {videos.map((video) => {
        return (
          <div key={video.title}>
            <hr />
            <Row>
              <Col xs={12} md={6}>
                <h2>{video.title}</h2>
                <p>
                  <Trans>{video.description}</Trans>
                </p>
              </Col>
              <Col xs={12} md={6}>
                <VideoContent video={video} />
              </Col>
            </Row>
          </div>
        );
      })}
    </>
  );
};

export default VideosPage;
