import clsx from 'clsx';
import { PropsWithChildren } from 'react';

interface Props {
  labelType?: 'success' | 'warning' | 'danger' | 'info' | 'default' | 'primary';
}

const Tag: React.FC<PropsWithChildren<Props>> = ({
  children,
  labelType = 'default',
}: PropsWithChildren<Props>) => {
  const className = clsx({ width: 'fit-content' }, `label label-${labelType}`);

  return <span className={className}>{children}</span>;
};

export default Tag;
