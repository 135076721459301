/* eslint-disable react-hooks/exhaustive-deps */
import { JSX, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Step4Data, StepOption, Steps } from '../../../model/Step';
import { RadioGroup, Select } from '../../common/Form';
import { StepOptionRadioItem } from '../StepPage';
import { getProjectPropositions } from '../../../const/Projects';
import {
  Project,
  ProjectPropositions,
  ProjectType,
} from '../../../model/Project';
import ProjectStepProposition from '../ProjectStepProposition';
import { getWorkflow } from '../../../utils/workflowUtils';

interface Props {
  project: Project;
  stepData?: Step4Data;
  updateProject: (value) => void;
  displayCompact?: boolean;
  userIsProjectOwner?: boolean;
  additionalInfo?: JSX.Element;
}

const SupplyOutletApproachPage: React.FC<Props> = ({
  project,
  stepData,
  updateProject,
  displayCompact,
  userIsProjectOwner,
  additionalInfo,
}) => {
  const { t } = useTranslation();

  const stepId = Steps.STEP_4;
  const fieldValue = `${stepId}-value`;
  const isSystemExtension =
    project.projectType === ProjectType.SYSTEM_EXTENSION;
  const stepList = useMemo(() => getWorkflow(project), [project]);
  const stepNumber = useMemo(
    () => stepList.find((step) => step.id === stepId)?.number,
    [stepList]
  );

  const propositions: ProjectPropositions = useMemo(() => {
    return getProjectPropositions(project);
  }, [project]);

  const submitField = (value: { [key: string]: string | number }) => {
    updateProject({
      [`steps.${[stepId]}`]: {
        ...stepData,
        ...value,
      },
    });
  };

  const { control, getValues } = useForm<Step4Data>({
    defaultValues: {
      [fieldValue]: stepData?.[fieldValue] || 'A',
    },
    mode: 'onChange',
  });

  const i18nForm = `step-${stepId}:form`;

  const options: {
    label: string;
    value: string | number;
    disabled?: boolean;
    labelData: StepOption;
  }[] = [
    {
      label: `${t(`${i18nForm}.a.option.title`, { stepNumber })} - ${t(
        `${i18nForm}.a.option.text`
      )}`,
      value: 'A',
      disabled: !userIsProjectOwner,
      labelData: {
        img: {
          alt: t('figures:4A.alt'),
          fileName: t('figures:4A.fileName'),
          longDescription: t('figures:4A.longDesc'),
        },
        modal: {
          content: (
            <>
              <p>{t(`${i18nForm}.a.modal.list1.title`)}</p>
              <ul>
                <li>{t(`${i18nForm}.a.modal.list1.item1`)}</li>
                <li>{t(`${i18nForm}.a.modal.list1.item2`)}</li>
              </ul>
              <p>{t(`${i18nForm}.a.modal.list2.title`)}</p>
              <ul>
                <li>{t(`${i18nForm}.a.modal.list2.item1`)}</li>
                <li>{t(`${i18nForm}.a.modal.list2.item2`)}</li>
              </ul>
            </>
          ),
          title: t(`${i18nForm}.a.modal.title`),
        },
        option: {
          text: t(`${i18nForm}.a.option.text`),
          title: t(`${i18nForm}.a.option.title`, { stepNumber }),
        },
      },
    },
    {
      label: `${t(`${i18nForm}.b.option.title`, { stepNumber })} - ${t(
        `${i18nForm}.b.option.text`
      )}`,
      value: 'B',
      disabled: !userIsProjectOwner,
      labelData: {
        img: {
          alt: t('figures:4B.alt'),
          fileName: t('figures:4B.fileName'),
          longDescription: t('figures:4B.longDesc'),
        },
        modal: {
          content: (
            <>
              <p>{t(`${i18nForm}.b.modal.list1.title`)}</p>
              <ul>
                <li>{t(`${i18nForm}.b.modal.list1.item1`)}</li>
                <li>{t(`${i18nForm}.b.modal.list1.item2`)}</li>
              </ul>
              <p>{t(`${i18nForm}.b.modal.list2.title`)}</p>
              <ul>
                <li>{t(`${i18nForm}.b.modal.list2.item1`)}</li>
                <li>{t(`${i18nForm}.b.modal.list2.item2`)}</li>
                <li>{t(`${i18nForm}.b.modal.list2.item3`)}</li>
              </ul>
              <p>{t(`${i18nForm}.b.modal.list3.title`)}</p>
              <ul>
                <li>{t(`${i18nForm}.b.modal.list3.item1`)}</li>
                <li>{t(`${i18nForm}.b.modal.list3.item2`)}</li>
                <li>{t(`${i18nForm}.b.modal.list3.item3`)}</li>
              </ul>
            </>
          ),
          title: t(`${i18nForm}.b.modal.title`),
        },
        option: {
          text: t(`${i18nForm}.b.option.text`),
          title: t(`${i18nForm}.b.option.title`, { stepNumber }),
        },
      },
    },
    {
      label: `${t(`${i18nForm}.c.option.title`, { stepNumber })} - ${t(
        `${i18nForm}.c.option.text`
      )}`,
      value: 'C',
      disabled: !userIsProjectOwner,
      labelData: {
        img: {
          alt: t('figures:4C.alt'),
          fileName: t('figures:4C.fileName'),
          longDescription: t('figures:4C.longDesc'),
        },
        modal: {
          content: (
            <>
              <p>{t(`${i18nForm}.c.modal.list1.title`)}</p>
              <ul>
                <li>{t(`${i18nForm}.c.modal.list1.item1`)}</li>
                <li>{t(`${i18nForm}.c.modal.list1.item2`)}</li>
                <li>{t(`${i18nForm}.c.modal.list1.item3`)}</li>
              </ul>
              <p>{t(`${i18nForm}.c.modal.list2.title`)}</p>
              <ul>
                <li>{t(`${i18nForm}.c.modal.list2.item1`)}</li>
                <li>{t(`${i18nForm}.c.modal.list2.item2`)}</li>
                <li>{t(`${i18nForm}.c.modal.list2.item3`)}</li>
              </ul>
              <p>{t(`${i18nForm}.c.modal.list3.title`)}</p>
              <ul>
                <li>{t(`${i18nForm}.c.modal.list3.item1`)}</li>
                <li>{t(`${i18nForm}.c.modal.list3.item2`)}</li>
                <li>{t(`${i18nForm}.c.modal.list3.item3`)}</li>
              </ul>
            </>
          ),
          title: t(`${i18nForm}.c.modal.title`),
        },
        video: {
          href: t(`${i18nForm}.c.video.href`),
          title: t(`${i18nForm}.c.video.title`),
        },
        option: {
          text: t(`${i18nForm}.c.option.text`),
          title: t(`${i18nForm}.c.option.title`, { stepNumber }),
        },
      },
    },
  ];

  const formValues = getValues();
  const [selectedOption, setSelectedOption] = useState(
    options.find((o) => o.value === formValues[fieldValue])
  );

  useEffect(() => {
    if (!(stepData && stepData[fieldValue])) {
      submitField(formValues as any);
    }
  }, [formValues[fieldValue]]);

  useEffect(() => {
    setSelectedOption(options.find((o) => o.value === stepData?.[fieldValue]));
  }, [stepData]);

  return (
    <>
      <form noValidate>
        {displayCompact ? (
          <Row>
            <Col xs={8} md={8}>
              {userIsProjectOwner ? (
                <Select
                  label={t('checklist.selectedOption')}
                  id={fieldValue}
                  control={control}
                  options={options}
                  formGroupClasses={'step-option-radio'}
                  onChange={submitField}
                />
              ) : (
                <div className="selected-option-text">
                  <label>{selectedOption?.labelData.option?.title}</label>
                  <p>{selectedOption?.labelData.option?.text}</p>
                </div>
              )}
              {additionalInfo}
            </Col>
            <Col xs={4} md={4}>
              {selectedOption && (
                <img
                  src={`/assets/images/${selectedOption.labelData.img?.fileName}`}
                  alt={selectedOption.labelData.img?.alt}
                  className="compact-image"
                />
              )}
            </Col>
          </Row>
        ) : (
          <>
            <ProjectStepProposition
              id={stepId}
              propositions={propositions.always.options[stepId]}
              always
            />
            <p>
              {isSystemExtension
                ? t(`step-${stepId}:descriptionSystemExtension`)
                : t(`step-${stepId}:description`)}
            </p>
            <RadioGroup
              id={fieldValue}
              control={control}
              items={options}
              radioClassName="step-option-radio"
              onChange={submitField}
              renderLabel={(item) => <StepOptionRadioItem item={item} />}
            />
            <ProjectStepProposition
              id={stepId}
              propositions={propositions.options[stepId]}
            />
          </>
        )}
      </form>
    </>
  );
};

export default SupplyOutletApproachPage;
