import moment from 'moment';
import React, { useState } from 'react';
import { Button, Col, PageHeader, Row, Well } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import DataTable, {
  TableColumn,
  TableStyles,
} from 'react-data-table-component';
import { NavLink } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import MediaQuery, { useMediaQuery } from 'react-responsive';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Project, ProjectStatus } from '../../model/Project';
import { Dialog } from '../common/Dialog';
import { AppRoute, QUERY_REFETCH_INTERVAL } from '../../const';
import { LocalizedNavLink } from '../i18n';
import { sortString } from '../../utils/sortUtils';
import { DatatablePagination } from '../common/Datatable';
import { getProjectStatus } from '../../utils/projectUtils';
import { SM_MAX_SIZE } from '../../const/Responsive';
import { getProjectsOfUser } from '../../api';
import { useUserProjects } from '../../hooks/useUserProjects';
import { deleteProject } from '../../api/projects';
import DataTableCell from '../common/Datatable/DataTableCell';
import CopyProjectDialog from './CopyProjectDialog';
import { ProjectFilters, ProjectMobileSort } from '.';

const sortStatus = (a, b) => {
  const isCompletedA = a.isCompleted;
  const isStartedA = a.isStarted;
  const isCompletedB = b.isCompleted;
  const isStartedB = b.isStarted;

  if (!isCompletedA && !isCompletedB && isStartedA && isStartedB) {
    return a.completedSteps?.length > b.completedSteps?.length;
  }

  return (isCompletedA && !isCompletedB) || (isStartedA && !isStartedB)
    ? 1
    : -1;
};

const customStyles: TableStyles = {
  headRow: {
    style: {
      borderBottomStyle: 'solid',
      borderBottomWidth: '1px',
      borderBottomColor: '#000',
    },
  },
  headCells: {
    style: {
      display: 'flex',
      color: '#000',
      fontFamily: 'Noto Sans, sans-serif',
      fontSize: 20,
      fontWeight: 600,
      justifyContent: 'space-between',
      span: {
        fontSize: 12,
      },
      '& div': {
        fontWeight: 600,
      },
    },
  },
  rows: {
    style: {
      backgroundColor: '#FBFBFB',
      borderBottom: 'none',
    },
    stripedStyle: {
      backgroundColor: 'transparent',
    },
  },
  cells: {
    style: {
      borderBottom: '#26374A solid 1px',
      fontSize: 20,

      '& a': {
        color: '#000',
      },
    },
  },
};

const mobileStyle = {
  table: {
    style: {
      marginTop: 20,
    },
  },
  headRow: {
    style: {
      display: 'none',
    },
  },
  rows: {
    style: {
      backgroundColor: '#FBFBFB',
      display: 'block',
      '&:first-of-type': {
        borderTop: '#26374A solid 1px',
      },
      '&:not(:last-of-type)': {
        borderBottom: '#26374A solid 1px',
      },

      borderBottom: '#26374A solid 1px',
    },
    stripedStyle: {
      backgroundColor: 'transparent',
    },
  },
  cells: {
    style: {
      border: 'none',
      display: 'inline-block',
      fontSize: 18,
      lineHeight: '26px',
      marginTop: 15,

      a: {
        color: '#333333',
      },

      '.table-cell-column-label': {
        fontSize: 16,
        fontWeight: 600,
      },
    },
  },
};

const Dashboard: React.FC = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const isSM = useMediaQuery({ query: `(max-width: ${SM_MAX_SIZE}px)` });
  const [filter, setFilter] = useState<string>('');
  const [sortField, setSortFieldId] = useState<string>('updatedAt');
  const [sortDirection, setSortDirection] = useState<string>('desc');

  const userProjectsQuery = useUserProjects();

  const projectListFilteredQuery = useQuery({
    queryKey: ['projects', filter],
    queryFn: async () => {
      return (await getProjectsOfUser(filter as ProjectStatus)).data;
    },
    refetchInterval: QUERY_REFETCH_INTERVAL,
  });

  const deleteProjectMutation = useMutation({
    mutationFn: (id: string) => deleteProject(id),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['projects'] });
      setDeletingProject(undefined);
    },
  });

  const [deletingProject, setDeletingProject] = useState<Project | undefined>();
  const [copyingProject, setCopyingProject] = useState<Project | undefined>();

  const dashboardColumns: TableColumn<Project>[] = [
    {
      id: 'name',
      name: t('project.table.name.label'),
      selector: (row) => row.name,
      width: isSM ? '100%' : undefined,
      cell: (row) => {
        const link =
          row.isStarted === false
            ? `/${t(AppRoute.CreateProject)}/${t(
                AppRoute.CreateProjectSettings
              )}?id=${row.id}`
            : `/${t(AppRoute.Project)}/${row.id}`;
        return <NavLink to={link}>{row.name}</NavLink>;
      },
      sortable: true,
      sortFunction: (a, b) => sortString('name', a, b),
    },
    {
      id: 'homeBuilder',
      name: t('project.table.builder.label'),
      selector: (row) => row.checklist?.homeBuilder,
      width: isSM ? '100%' : undefined,
      cell: (project) => (
        <DataTableCell columnLabel={t('project.table.builder.label')}>
          {project.checklist?.homeBuilder}
        </DataTableCell>
      ),
      sortable: true,
      sortFunction: (a, b) =>
        a.checklist?.['homeBuilder'].localeCompare(
          b.checklist?.['homeBuilder'],
          undefined,
          { numeric: true }
        ),
    },
    {
      id: 'createdAt',
      name: t('project.table.createdAt.label'),
      selector: (row) =>
        row.createdAt ? moment(row.createdAt).format('YYYY/MM/DD') : '-',
      width: isSM ? '50%' : undefined,
      cell: (project) => (
        <DataTableCell columnLabel={t('project.table.createdAt.label')}>
          {project.createdAt
            ? moment(project.createdAt).format('YYYY/MM/DD')
            : '-'}
        </DataTableCell>
      ),
      sortable: true,
    },
    {
      id: 'updatedAt',
      name: t('project.table.updatedAt.label'),
      selector: (row) =>
        row.updatedAt ? moment(row.updatedAt).format('YYYY/MM/DD') : '-',
      width: isSM ? '50%' : undefined,
      cell: (project) => (
        <DataTableCell columnLabel={t('project.table.updatedAt.label')}>
          {project.updatedAt
            ? moment(project.updatedAt).format('YYYY/MM/DD')
            : '-'}
        </DataTableCell>
      ),
      sortable: true,
    },
    {
      id: 'status',
      name: 'Status',
      selector: (row) => getProjectStatus(row, t),
      cell: (project) => (
        <DataTableCell columnLabel={t('project.table.status.label')}>
          {getProjectStatus(project, t)}
        </DataTableCell>
      ),
      width: isSM ? '50%' : undefined,
      sortable: true,
      sortFunction: sortStatus as any,
    },
    {
      id: 'action',
      name: '',
      right: true,
      button: !isSM,
      width: isSM ? '100%' : '18%',
      cell: (project) => (
        <>
          <Button
            bsSize="small"
            className="btn btn-primary btn-sm copy-btn pull-right"
            onClick={() => setCopyingProject(project)}
          >
            {t('common.copy')}
          </Button>
          <Button
            bsSize="small"
            className="btn btn-secondary btn-sm pull-right"
            onClick={() => setDeletingProject(project)}
          >
            {t('common.delete')}
          </Button>
        </>
      ),
    },
  ];

  return (
    <>
      <Helmet>
        <title>
          {t('pageTitle.dashboard')} - {t('pageTitle.appName')}
        </title>
      </Helmet>
      <PageHeader>{t('dashboard.title')}</PageHeader>
      <Row className="dashboard-header">
        <Col xs={12} md={4} mdPush={8} className="vcenter text-right">
          <LocalizedNavLink
            to={AppRoute.CreateProject}
            className="btn btn-primary btn-sm add-project-btn"
          >
            {t('dashboard.addButton.label')}
          </LocalizedNavLink>
        </Col>
        <Col xs={12} md={8} mdPull={4} className="vcenter project-filtering">
          <ProjectFilters filter={filter} setFilter={setFilter} />
          <MediaQuery maxWidth={SM_MAX_SIZE}>
            <ProjectMobileSort
              sortDirection={sortDirection}
              sortField={sortField}
            />
          </MediaQuery>
        </Col>
      </Row>

      <DataTable
        keyField="id"
        onSort={(column, sortDirection) => {
          setSortFieldId(column.id as string);
          setSortDirection(sortDirection);
        }}
        defaultSortFieldId={sortField}
        defaultSortAsc={sortDirection === 'asc'}
        customStyles={isSM ? { ...customStyles, ...mobileStyle } : customStyles}
        columns={dashboardColumns}
        data={projectListFilteredQuery.data ?? []}
        persistTableHead
        striped
        noDataComponent={t('dashboard.table.empty')}
        pagination
        paginationComponent={(props) => <DatatablePagination {...props} />}
      />

      <CopyProjectDialog
        project={copyingProject}
        setProject={setCopyingProject}
        userProjects={userProjectsQuery.data ?? []}
      />

      <Dialog
        actionBtns={[
          {
            label: t('project.deleteModal.submit'),
            onClick: () => {
              if (deletingProject) {
                deleteProjectMutation.mutate(deletingProject.id);
              }
            },
          },
        ]}
        closeLabel={t('common.cancel')}
        onHide={() => setDeletingProject(undefined)}
        show={!!deletingProject}
        title={t('project.deleteModal.title')}
        modalProps={{
          onHide: () => setDeletingProject(undefined),
          className: 'delete-project-modal',
        }}
      >
        <p>{t('project.deleteModal.text')}</p>
        <strong>{deletingProject?.name}</strong>
        <Well>
          <Row>
            <Col xs={1}>
              <FontAwesomeIcon icon={faExclamationCircle} />
            </Col>
            <Col xs={11}>
              <div className="well-note-header">{t('common.important')}</div>
              {t('project.deleteModal.note')}
            </Col>
          </Row>
        </Well>
      </Dialog>
    </>
  );
};

export default Dashboard;
