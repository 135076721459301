import { useAccount } from '@azure/msal-react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { Button, Form, Well } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { saveComment } from '../../../api/projects';
import { PageComment } from '../../../model/Comments';
import { Project } from '../../../model/Project';
import { Input, ServerFeedback, TextArea } from '../../common/Form';

interface Props {
  pageId: string;
  project: Project;
}

const NewCommentForm: React.FC<Props> = ({ pageId, project }) => {
  const { t } = useTranslation();
  const account = useAccount();
  const queryClient = useQueryClient();
  const [serverError, setServerError] = useState('');
  const addCommentMutation = useMutation({
    mutationFn: (comment: PageComment) =>
      saveComment(project.id, pageId, comment),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    defaultValues: {
      comment: '',
      userName: account?.name || '',
    },
  });

  const onSubmit = (values) => {
    addCommentMutation.mutate(
      {
        createdAt: new Date(),
        text: values.comment,
        userName: values.userName || '',
      },
      {
        onError: (err) => {
          console.log(err);
          setServerError(t('errors.common.unknown'));
        },
        onSuccess: ({ data }) => {
          queryClient.setQueryData(['project', { id: data.id }], data);
          resetForm();
        },
      }
    );
  };

  const resetForm = () => {
    reset(
      { comment: '', userName: account?.name || '' },
      { keepDirty: false, keepTouched: false }
    );
  };

  useEffect(() => {
    setValue('userName', account?.name || '');
  }, [account, setValue]);

  return (
    <Well>
      <div className="well-title">{t('comment.add.title')}</div>

      <Form noValidate onSubmit={handleSubmit(onSubmit)}>
        <TextArea
          className="full-width-field"
          id="comment"
          label={t('comment.add.comment.label')}
          labelHidden={true}
          control={control}
          error={errors.comment}
          validationRules={{
            maxLength: {
              value: 400,
              message: t('errors.common.maxLength', { maxLength: 400 }),
            },
            required: t('errors.common.required').toString(),
          }}
        ></TextArea>

        <Input
          className="full-width-field"
          id="userName"
          control={control}
          type={account ? 'hidden' : 'text'}
          label={t('comment.add.name.label')}
          labelHidden={!!account}
          error={errors.userName}
          validationRules={{
            required: t('errors.common.required').toString(),
          }}
        />

        {!!serverError && (
          <ServerFeedback type="error">{t(serverError)}</ServerFeedback>
        )}

        <div className="well-actions">
          <Button type="submit" bsSize="sm" bsStyle="primary">
            {t('common.save')}
          </Button>
          <Button
            onClick={resetForm}
            bsSize="sm"
            className="btn-primary btn-contrast"
          >
            {t('common.cancel')}
          </Button>
        </div>
      </Form>
    </Well>
  );
};

export default NewCommentForm;
